import { colors } from "../assets/colors";
import { booleanState } from "../components/Status";

export const stateColor = (state) => {
  const { isError, isOnProgress, isCompleted, isInitial, isCreated } =
    booleanState(state);
  if (isInitial || isCreated) return colors.teal;
  if (isError) return colors.redE9;
  if (isOnProgress) return colors.blue10;
  if (isCompleted) return colors.green28;
  return colors.grey72;
};
