import { sentenceCase, titleCase } from "change-case";
import { pickBy } from "lodash";
import { useRouter } from "next/router";
import {
  customDateFormatter,
  formatCurrency,
} from "../../../../../../components/tools";
import { queryArray } from "../modal/content/parent";

export const eWalletFiltersMarkers = (array, additionalMarkerArrRaw) => {
  const { query } = useRouter();
  const {
    from_date,
    to_date,
    amount_type,
    specific_amount,
    minimum_amount,
    maximum_amount,
    attachment,
  } = query;
  const markersGeneratedRaw = array.map((item) => {
    const { name, label: labelRaw } = item;

    const value = queryArray(query, name);

    const isKeywords = name == "keywords";
    const isDate = name == "date";
    const isAmount = name == "amount";
    const isAttachment = name == "attachment";

    if (isAttachment && attachment)
      return {
        label: sentenceCase(attachment),
        name: "attachment",
        show: true,
      };

    if (isAmount) {
      const obj = pickBy(
        { amount_type, specific_amount, minimum_amount, maximum_amount },
        (value) => value
      );

      const keys = Object.keys(obj);

      const result = keys.map((key) => {
        const value = obj[key];

        const isDirection = key == "amount_type";

        if (isDirection)
          return { name: key, label: sentenceCase(value), show: true };

        return {
          label: `${titleCase(key)} ${formatCurrency(value)}`,
          name: key,
          show: true,
        };
      });

      return result;
    }

    if (isDate) {
      const label = `${customDateFormatter(from_date, true, true)} - ${
        to_date ? customDateFormatter(to_date, true, true) : "Now"
      }`;

      return { label, name: "date", show: !!(to_date || from_date) };
    }

    if (isKeywords) {
      const newValue = value.join(" ");
      const label = `"${newValue}"`;
      return { label, value: newValue, show: !!value.length, name: "keywords" };
    }

    const label = `${labelRaw} (${value.length})`;

    return { label, show: !!value.length, name };
  });

  const markersGenerated = markersGeneratedRaw.flat(1);

  const additionalMarkerArr = additionalMarkerArrRaw.map((item) => {
    const { label, value: name, isPink } = item || {};

    const show = query[name];
    return { label, show, name, isPink };
  });

  const markers = [...additionalMarkerArr, ...markersGenerated];

  return { markers, markersGenerated };
};
