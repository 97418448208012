import { AxiosRequestConfig } from "axios";
import { decryptToken } from "../hash";
import { urlDeciderChild } from "./url";

const defaultHeader = async (config: AxiosRequestConfig, isV2?: boolean) => {
  const getToken = localStorage.getItem("token");
  const decryptedToken = getToken ? decryptToken(getToken) : "";

  const baseUrl = urlDeciderChild(config.baseURL || "");
  config.baseURL = isV2 ? baseUrl.replace("v1", "v2") : baseUrl;
  config.headers = { Authorization: decryptedToken };
  return config;
};

export default defaultHeader;
