const localStorageBooleans = () => {
  const storage = localStorage.getItem("env_type");
  const original = !storage;
  const prod = storage == "production";
  const internal = storage == "internal_production";
  const internal2 = storage == "internal_production_2";
  const internalIo = storage == "internal_production_io";
  const sandbox = storage == "sandbox";
  const staging = storage == "staging";
  const prod3 = storage == "prod3";
  const api2 = storage == "api2";
  const prod_unbanned = storage == "prod_unbanned";

  return {
    prod_unbanned,
    original,
    prod,
    internal,
    internal2,
    internalIo,
    prod3,
    api2,
    sandbox,
    staging,
  };
};

export const mainURL = process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL || "";

export const mainDownloadURL =
  process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL || "";

export const getUrlEnv = (env: string) => {
  if (env == "production") return "https://api.enterprise.transfez.com/api/v1";
  if (env == "prod_unbanned")
    return "https://api.enterprise.itsjack.com/api/v1";
  if (env == "internal_production")
    return "https://inprod.api.business.transfez-jack-engineering.xyz/api/v1";
  if (env == "internal_production_2")
    return "https://api.enterprise.transfez.app/api/v1/";
  if (env == "internal_production_io")
    return "https://inprod.api.business.transfez.io/api/v1";
  if (env == "prod3") return "https://prod3.business.transfez.app/api/v1";
  if (env == "api2") return "https://api2.enterprise.transfez.app/api/v1";
  if (env == "sandbox")
    return "https://sandbox.api.business.transfez.tech/api/v1";
  if (env == "staging")
    return "https://staging.api.business.transfez.tech/api/v1";
  return mainURL;
};

export const urlDeciderChild = (defaultUrl: string) => {
  const isDefaultUrl = defaultUrl == mainURL;

  const isDownload = defaultUrl == mainDownloadURL;

  const {
    internal,
    sandbox,
    internal2,
    internalIo,
    prod,
    staging,
    prod3,
    api2,
    prod_unbanned,
  } = localStorageBooleans();

  const defaultUrlFunc = () => {
    if (prod) return getUrlEnv("production");
    if (internal) return getUrlEnv("internal_production");
    if (internal2) return getUrlEnv("internal_production_2");
    if (internalIo) return getUrlEnv("internal_production_io");

    if (prod3) return getUrlEnv("prod3");
    if (api2) return getUrlEnv("api2");

    if (sandbox) return getUrlEnv("sandbox");
    if (staging) return getUrlEnv("staging");
    if (prod_unbanned) return getUrlEnv("prod_unbanned");

    return mainURL;
  };

  if (isDefaultUrl) return defaultUrlFunc();

  if (isDownload) return defaultUrlFunc().replace("/api/v1", "");

  return defaultUrl;
};
