import { AES, enc } from "crypto-js";

const AESPassword = process.env.NEXT_PUBLIC_FINGERPRINT || "";

export const encryptToken = (token: string) => {
  const safeToken = token || "";
  const encryptedToken = AES.encrypt(safeToken, AESPassword).toString();
  return encryptedToken;
};

export const decryptToken = (encryptedToken: string) => {
  if (!encryptedToken) return encryptedToken;
  const bytes = AES.decrypt(encryptedToken, AESPassword);
  const result = bytes.toString(enc.Utf8);
  return result;
};
