import * as Sentry from "@sentry/nextjs";
import React from "react";
import { jackColors } from "./assets/colors";
import { JackIcons } from "./assets/jackIcons/parent";
import { ButtonJack } from "./components/ButtonsJack/parent";
import { useTranslation } from "react-i18next";
import { isProduction } from "@tools";

const ErrorPage = ({ onClick }) => {
  const { t } = useTranslation("common");

  return (
    <div
      className="d-flex justify-content-center align-items-center my-4"
      style={{
        flexDirection: "column",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <JackIcons
        name="alert-triangle"
        fill={jackColors.black34}
        style={{ height: 64, width: 64 }}
        className="mb-4"
      />
      <h1 style={{ fontFamily: "GothamBook" }} className="font20">
        {t("Please try again")}
      </h1>
      <h1 style={{ fontFamily: "GothamBook" }} className="font16">
        {t("Oops, there is an error occured while loading the page.")}
      </h1>
      <h1 style={{ fontFamily: "GothamBook" }} className="font14">
        {t("If this problem still persists please try open")}{" "}
        <a href="https://business.itsjack.com">business.itsjack.com</a>{" "}
        {t("in another browser")}
      </h1>

      <ButtonJack onClick={onClick} style={{ height: 36 }} className="mt-4">
        {t("Reload page")}
      </ButtonJack>
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  // client error catcher
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  // client error catcher

  componentDidCatch(error, errorInfo) {
    if (!isProduction) return;
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    return <ErrorPage onClick={() => window.location.reload()} />;
  }
}

export default ErrorBoundary;
