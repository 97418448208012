import { TextField } from "@material-ui/core";
import { sentenceCase, titleCase } from "change-case";
import React, { useEffect, useRef, useState } from "react";
import { colors, jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular, TextInlineRegular } from "../Text";
import CustomTooltip from "../Tooltip";
import { formatCurrency, unformatCurrency } from "../tools";
import { useTranslation } from "react-i18next";

export const ErrorMsg = ({ children, isError = true, style }) => (
  <GothamRegular
    className="font10"
    style={{
      marginTop: 4,
      color: isError ? jackColors.redE7 : jackColors.grey90,
      ...style,
    }}
  >
    {children}
  </GothamRegular>
);
export const FixedHelperText = ({ children, style }) => (
  <GothamRegular
    className="font10"
    style={{
      marginTop: 4,
      color: jackColors.grey90,
      ...style,
    }}
  >
    {children}
  </GothamRegular>
);

export const TextFieldJackHelp = ({ msg, isError, helperTextStyle }) => {
  if (!msg) return null;
  return (
    <ErrorMsg isError={isError} style={helperTextStyle}>
      {msg}
    </ErrorMsg>
  );
};

const helperTextDecider = (helperText, error, errorMsg, woFormat) => {
  if (typeof error == "boolean") return "";
  if (typeof error == "string" && !!error) return error;
  if (errorMsg) return woFormat ? errorMsg : sentenceCase(errorMsg);
  return helperText;
};

const textFieldPropsWoUseFormObj = {
  name: "",
  label: "",
  icon: null,
  iconRight: null,
  placeholder: "",
  required: true,
  error: "",
  helperText: "",
  woLabel: false,
  style: {},
  textFieldStyle: {},
  onChange: () => {},
  noBorder: false,
  labelStyle: {},
  disabled: false,
  isAutoFill: false,
  textFieldInputStyle: {},
  autoFocus: false,
  placeholderStyle: {},
  onBlur: () => {},
  onFocus: () => {},
  preventTab: false,
  isTransparent: false,
  helperTextStyle: {},
  withLetterCounter: false,
  maxLength: false,
  letterCounterStyle: {},
};

export const textFieldProps = {
  ...textFieldPropsWoUseFormObj,
  useFormObj: {},
};

export const jackIconsTo20 = (iconsArr = []) => {
  const result = iconsArr.map((icon) => {
    if (!icon) return;

    const isJackIcons = icon?.type?.name === "JackIcons";

    if (!isJackIcons) return icon;

    const { props } = icon || {};
    const style = { width: 20, height: 20 };
    const newProps = { ...props, style };

    return { ...icon, props: newProps };
  });

  return result;
};

export const AtomicTextFieldJack = (
  defaultProps = {
    ...textFieldProps,
    isError: false,
    hasValue: false,
  }
) => {
  const {
    icon: iconProps,
    iconRight: iconRightProps,
    placeholder = "",
    isError,
    helperText,
    hasValue,
    label,
    name,
    style,
    textFieldStyle,
    woLabel,
    noBorder,
    labelStyle,
    disabled,
    required, // di culik biar ga ke kirim ke <TextField
    isAutoFill,
    textFieldInputStyle,
    placeholderStyle,
    onBlur: onBlurProps = () => {},
    onFocus: onFocusProps = () => {},
    preventTab,
    isTransparent,
    helperTextStyle,
    tooltip,
    tooltipStyle,
    isOptional,
    withLetterCounter,
    maxLength,
    letterCounterStyle,
    disableUnderline = true,
    idContainer,
    isLabelBold,
    readOnly = false,
    ...props
  } = defaultProps;

  const { value } = defaultProps;

  const [icon, iconRight] = jackIconsTo20([iconProps, iconRightProps]);

  const [isFocused, setIsFocused] = useState(false);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isAutoFill) return setIsReady(true);
    const func = () => setIsReady(true);
    document.addEventListener("click", func);
    return () => {
      document.removeEventListener("click", func);
    };
  }, []);

  const isPlaceHolderPushed = isFocused && !hasValue;

  const [clientWidth, setClientWidth] = useState(0);

  useEffect(() => {
    if (!!icon) return;
    setClientWidth(0);
  }, [!!icon]);

  const left = clientWidth + 4 + 12 + (isPlaceHolderPushed ? 4 : 0);

  const textFieldClassNameDecider = () => {
    if (isFocused || isError) return "";
    if (noBorder) return "";
    return "textfield";
  };

  const textFieldClassName = textFieldClassNameDecider();

  const handleOnFocus = (value) => {
    setIsFocused(true);
    onFocusProps(value);
  };

  const handleOnBlur = (value) => {
    setIsFocused(false);
    onBlurProps(value);
  };
  return (
    <div
      style={{
        flexDirection: "column",
        position: "relative",
        marginBottom: 32,
        ...style,
      }}
      className="d-flex"
      id={idContainer}
    >
      {!hasValue && isReady && (
        <div
          style={{
            position: "absolute",
            top: label ? 29 + 6 : 19,
            left: -2,
            translate: `${left}px 0px`,
            transition: "all 0.2s linear",
            width: "calc(100% - 36px)",
            ...placeholderStyle,
          }}
        >
          <GothamRegular
            style={{
              color: jackColors.greyBB,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {placeholder}
          </GothamRegular>
        </div>
      )}
      {tooltip && (
        <div
          style={{
            position: "absolute",
            top: 1.5,
            right: 0,
          }}
        >
          <CustomTooltip
            text={tooltip}
            style={{
              backgroundColor: colors.neutral100,
              boxShadow:
                "0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16)",
              opacity: 1,
              maxWidth: "151px",
              color: colors.neutral900,
              fontSize: "12px",
              lineHeight: "16px",
              textAlign: "left",
              ...tooltipStyle,
            }}
            popperClassName="white-arrow"
          >
            <JackIcons
              name="info-outline"
              fill={colors.neutral700}
              style={{ width: "12px", height: "12px" }}
            />
          </CustomTooltip>
        </div>
      )}
      {!woLabel &&
        (isLabelBold ? (
          <GothamMedium
            className="font12"
            style={{
              color: isError ? jackColors.redE7 : jackColors.black34,
              marginBottom: 8,
              ...labelStyle,
            }}
          >
            {label}
            {required && <span style={{ color: jackColors.redE7 }}>*</span>}
            {isOptional && (
              <TextInlineRegular
                style={{
                  color: colors.neutral700,
                  fontSize: "10px",
                  marginLeft: "4px",
                }}
              >
                (optional)
              </TextInlineRegular>
            )}
          </GothamMedium>
        ) : (
          <GothamRegular
            className="font12"
            style={{
              color: isError ? jackColors.redE7 : jackColors.black34,
              marginBottom: 8,
              ...labelStyle,
            }}
          >
            {label}
            {required && <span style={{ color: jackColors.redE7 }}>*</span>}
            {isOptional && (
              <TextInlineRegular
                style={{
                  color: colors.neutral700,
                  fontSize: "10px",
                  marginLeft: "4px",
                }}
              >
                (optional)
              </TextInlineRegular>
            )}
          </GothamRegular>
        ))}
      {withLetterCounter && maxLength && (
        <GothamRegular
          woFontColor
          className="font10"
          style={{
            color: jackColors.neutral900,
            position: "absolute",
            bottom: "8px",
            right: "8px",
            ...letterCounterStyle,
          }}
        >
          {value ? value.length : 0}/{maxLength}
        </GothamRegular>
      )}
      <div
        style={{
          backgroundColor: isTransparent ? "transparent" : "white",
          borderRadius: 4,
        }}
      >
        <TextField
          {...props}
          name={name}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          disabled={!!disabled}
          inputProps={{ maxLength: maxLength ?? "none" }}
          InputProps={{
            disableUnderline: disableUnderline,
            startAdornment: !!icon && (
              <div
                className="d-flex align-items-center mr-1"
                ref={(e) => {
                  if (!e) return;
                  setClientWidth((prev) => {
                    if (prev == e.clientWidth) return prev;
                    return e.clientWidth;
                  });
                }}
              >
                {icon}
              </div>
            ),
            endAdornment: !!iconRight && (
              <div className="d-flex align-items-center ml-1">{iconRight}</div>
            ),
            style: {
              fontFamily: "GothamBook",
              fontSize: 14,
              marginTop: -4,
              ...textFieldInputStyle,
            },
            readOnly,
          }}
          variant="standard"
          style={{
            height: 40,
            borderRadius: 4,
            width: "100%",
            padding: 12,
            paddingTop: 10,
            paddingBottom: 10,
            border: `1px solid ${
              isError ? jackColors.redE7 : jackColors.black34
            }`,
            transition: "all 0.3s ease",
            backgroundColor: disabled ? jackColors.greyF1 : "",
            ...textFieldStyle,
          }}
          className={textFieldClassName}
        />
      </div>
      {/* {Boolean(fixedHelperText) && (
        <FixedHelperText>{fixedHelperText}</FixedHelperText>
      )} */}
      <TextFieldJackHelp
        isError={isError}
        msg={helperText}
        helperTextStyle={helperTextStyle}
      />
    </div>
  );
};

export const useAutofocus = ({ name, required, autoFocus, register }) => {
  const inputRef = useRef();

  const { onChange, ref } = register(name, { required });

  useEffect(() => {
    const current = inputRef?.current;
    if (!current) return;
    if (!autoFocus) return;
    current.focus();
  }, [!!inputRef.current, autoFocus]);

  return {
    onChange,
    inputRef: (e) => {
      ref(e);
      inputRef.current = e;
    },
  };
};

export const TextFieldJack = (props = textFieldProps) => {
  const {
    name,
    label: labelProps,
    useFormObj,
    required,
    error,
    helperText,
    autoFocus,
    onBlur = () => {},
    onFocus = () => {},
    woFormatError = false,
    type,
  } = props;

  const { watch, register, errors, clearErrors, setValue } = useFormObj;
  const value = watch(name);
  const hasValue = !!value;
  const errorMsg = error || errors?.[name]?.message;

  const isError = !!errorMsg;

  const label = labelProps || titleCase(name);

  const autoFocusProps = useAutofocus({ name, autoFocus, register, required });

  useEffect(() => {
    if (!isError) return;
    if (hasValue) return clearErrors(name);
  }, [value]);

  const isNumber = type == "number";

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!isNumber) return;
    if (isFirstRender) return setIsFirstRender(false);
    setValue(name || "", value?.replace(/\D/g, ""));
  }, [value]);

  return (
    <AtomicTextFieldJack
      {...autoFocusProps}
      {...props}
      value={watch(name) || ""}
      helperText={helperTextDecider(helperText, error, errorMsg, woFormatError)}
      hasValue={hasValue}
      isError={isError}
      label={label}
      onBlur={onBlur}
      onFocus={onFocus}
      type={isNumber ? "text" : type}
    />
  );
};
const textFieldJackStateProps = { ...textFieldPropsWoUseFormObj, value: "" };

export const TextFieldJackState = (props = textFieldJackStateProps) => {
  const {
    error,
    helperText = "",
    value,
    onChange = () => {},
    autoFocus,
    onBlur = () => {},
    onFocus = () => {},
    ...defaultProps
  } = props;
  const hasValue = value;
  const errorMsg = error;
  const isError = !!errorMsg;

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [inputRef, autoFocus]);

  return (
    <AtomicTextFieldJack
      helperText={helperTextDecider(helperText, error, errorMsg)}
      isError={isError}
      inputRef={inputRef}
      hasValue={hasValue}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      {...defaultProps}
    />
  );
};

const toNumber = (string) => {
  const pattern = /\d+/g;
  const matches = (string || "").match(pattern);
  let longestDigits = "";

  if (matches) {
    matches.forEach((match) => {
      if (match.length > longestDigits.length) {
        longestDigits = match;
      }
    });
  }

  return longestDigits;
};

const formatToCurr = (numberRaw, isCurrency) => {
  const number = Math.abs(Number(unformatCurrency(numberRaw)));
  const is0 = number == "0" || !number;

  const curr = formatCurrency(is0 ? "" : number);

  const valueDecider = () => {
    if (!isCurrency) return toNumber(numberRaw);
    if (!curr) return "";
    return curr;
  };

  return valueDecider();
};

export const useNumberOnChangeProps = (props) => {
  const { name, useFormObj, isCurrency, maxLength } = props;

  const { setValue, watch } = useFormObj;
  const value = watch(name);

  const onChange = (textRaw) => {
    const text = maxLength ? String(textRaw).slice(0, maxLength) : textRaw;

    const value = formatToCurr(text, isCurrency);
    setValue(name, value);
  };

  useEffect(() => {
    onChange(value);
  }, []);

  return { onChange: (e) => onChange(e?.target?.value) };
};

export const NumberFieldJack = (
  props = {
    ...textFieldProps,
    isCurrency: true,
  }
) => <TextFieldJack {...props} {...useNumberOnChangeProps(props)} />;

export const NumberFieldJackState = (
  props = {
    ...textFieldJackStateProps,
    isCurrency: true,
  }
) => {
  const { isCurrency, onChange, value, max } = props;

  const handleChange = (text) => {
    if (max && Number(unformatCurrency(text)) > max)
      return onChange(formatCurrency(max));
    onChange(formatToCurr(text, isCurrency));
  };

  useEffect(() => {
    handleChange(value);
  }, []);

  return (
    <TextFieldJackState
      {...props}
      value={formatToCurr(value, isCurrency)}
      onChange={(value) => handleChange(value)}
    />
  );
};

export const CurrencyFieldJack = (
  props = {
    ...textFieldProps,
    isCurrency: true,
  }
) => {
  const { name, useFormObj } = props;

  const { setValue, watch } = useFormObj;

  const onChange = (text) => setValue(name, formatCurrency(text));

  useEffect(() => {
    onChange(watch(name));
  }, []);

  return (
    <TextFieldJack {...props} onChange={(e) => onChange(e?.target.value)} />
  );
};

export const TextFieldJackPassword = (props = textFieldProps) => {
  const [isIconHovered, setIsIconHovered] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const iconRight = (
    <div
      onClick={togglePasswordVisibility}
      style={{ cursor: "pointer" }}
      onMouseEnter={() => setIsIconHovered(true)}
      onMouseLeave={() => setIsIconHovered(false)}
    >
      <JackIcons
        name={isPasswordVisible ? "visibility-off" : "visibility"}
        fill={isIconHovered ? colors.neutral800 : colors.neutral600}
        styleDiv={{ height: 20, width: 20 }}
      />
    </div>
  );

  return (
    <TextFieldJack
      type={isPasswordVisible ? "text" : "password"}
      iconRight={iconRight}
      {...props}
    />
  );
};

export const TextFieldLimitJack = (props = textFieldProps) => {
  const { name, useFormObj, maxLength } = props;
  const { setValue, watch } = useFormObj;

  const handleChange = (text) => {
    if (text.length <= maxLength) return setValue(name, text);
    setValue(name, text.slice(0, maxLength));
  };

  return (
    <TextFieldJack
      {...props}
      onChange={(e) => handleChange(e?.target.value)}
      maxLength={maxLength}
    />
  );
};

export const TextOnlyFieldJack = (
  props = {
    ...textFieldProps,
  }
) => {
  const [prevValue, setPrevValue] = useState("");
  const { name, useFormObj } = props;

  const { setValue } = useFormObj;
  const onChange = (value) => {
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(value) || !value) {
      setPrevValue(value);
      setValue(name, value);
    } else {
      setValue(name, prevValue);
    }
  };

  return (
    <TextFieldJack {...props} onChange={(e) => onChange(e?.target.value)} />
  );
};
