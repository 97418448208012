import axios, { AxiosInstance } from "axios";
import { ToasterHook } from "contexts/ToasterContext";
import { useState } from "react";

type MimeType =
  | "application/pdf"
  | "application/json"
  | "application/xml"
  | "application/zip"
  | "application/msword"
  | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  | "application/vnd.ms-excel"
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  | "application/vnd.ms-powerpoint"
  | "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  | "text/plain"
  | "text/html"
  | "text/csv"
  | "image/jpeg"
  | "image/png"
  | "image/gif"
  | "image/svg+xml"
  | "image/webp"
  | "audio/mpeg"
  | "audio/wav"
  | "audio/ogg"
  | "video/mp4"
  | "video/webm"
  | "video/ogg";

interface DownloadNormal {
  type?: MimeType;
  url: string;
  fileName?: string;
  jsonHandler?: (url: string, fileName: string) => void;
  afterSuccess?: (url: string, fileName: string) => void;
  isPut?: boolean;
  payloadPut?: Record<string, any>;
  longTimeout?: boolean;
}

interface APIs {
  apiBusinessWoHeaderDownload: AxiosInstance;
  apiBusinessDownload: AxiosInstance;
  apiBusinessLong: AxiosInstance;
  apiBusiness: AxiosInstance;
}

interface DownloadAPIDefault {
  woV1?: boolean;
  errorToasterApi?: (error: unknown) => void;
  isAxios?: boolean;
  woHeader?: boolean;
}

export interface DownloadApiParams extends DownloadNormal, DownloadAPIDefault {}

interface DownloadApiParamsLocal extends DownloadApiParams, APIs {}

export const handleDownloadRawChild = ({
  data,
  type = "application/pdf",
  fileName = "",
}: {
  data: BlobPart;
  type?: MimeType;
  fileName?: string;
}) => {
  const file = new Blob([data], {
    type,
  });
  const targetUrl = window.URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = targetUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode && link.parentNode.removeChild(link);
};

export const downloadApiChild = async ({
  type = "application/pdf",
  url,
  fileName = "Jack-Service-Agreement-and-Policy.pdf",
  jsonHandler,
  woV1 = false,
  errorToasterApi,
  afterSuccess,
  isAxios,
  woHeader = false,
  isPut = false,
  payloadPut,
  longTimeout = false,
  apiBusinessWoHeaderDownload,
  apiBusinessDownload,
  apiBusinessLong,
  apiBusiness,
}: DownloadApiParamsLocal) => {
  try {
    const apiDecider = () => {
      if (woHeader) return apiBusinessWoHeaderDownload;
      if (isAxios) return axios;
      if (woV1) return apiBusinessDownload;
      if (longTimeout) return apiBusinessLong;
      return apiBusiness;
    };
    const apiDownload = apiDecider();

    const Method = () => {
      if (isPut)
        return apiDownload.put(url, payloadPut, {
          responseType: "blob",
        });
      return apiDownload.get(url, {
        responseType: "blob",
      });
    };

    const { data } = await Method();
    const { type: responseType } = data;
    const isJson = responseType == "application/json";

    if (isJson && jsonHandler) return jsonHandler(url, fileName);
    handleDownloadRawChild({ data, fileName, type });

    afterSuccess && afterSuccess(url, fileName);
  } catch (err) {
    errorToasterApi && errorToasterApi(err);
  }
};

export const useDownloadChild = (apis: APIs) => {
  const [loading, setLoading] = useState(false);
  const { errorToasterApi } = ToasterHook();

  const handleDownload = async ({
    type = "application/pdf",
    url,
    fileName = "Service-Agreement-and-Policy.pdf",
    jsonHandler,
    afterSuccess,
    isPut,
    payloadPut,
    longTimeout,
  }: DownloadNormal) => {
    setLoading(true);
    await downloadApiChild({
      type,
      fileName,
      url,
      jsonHandler,
      errorToasterApi,
      afterSuccess,
      isPut,
      payloadPut,
      longTimeout,
      ...apis,
    });
    setLoading(false);
  };

  return { handleDownload, loading };
};
