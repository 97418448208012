import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { colors } from "../../../../../assets/colors";
import filterGrayIcon from "../../../../../assets/images/filter-gray-icon.svg";
import filterPinkIcon from "../../../../../assets/images/filter-pink-icon.svg";
import { useModalHook } from "../../../../../components/Modals";
import { DefaultQueryInput } from "../../../../../components/SearchAndFilterCard";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { useClickOutside } from "../../../../../universalFunctions/useClickOutside";
import { eWalletFilterTypeBooleans, queryArray } from "./modal/content/parent";
import { EWalletFilterModal } from "./modal/parent";
import xIcon from "../../../../../assets/images/x-icon.svg";
import { pickBy } from "lodash";
import { eWalletFiltersMarkers } from "./markers/logics";

export const EWalletFilterNumber = ({ children, style }) => (
  <GothamMedium
    style={{
      padding: 8,
      paddingTop: 2,
      paddingBottom: 2,
      backgroundColor: colors.lightPink,
      color: colors.pink,
      borderRadius: 12,
      minWidth: 36,
      textAlign: "center",
      ...style,
    }}
    className="ml-1"
  >
    {children}
  </GothamMedium>
);

export const EWalletFilterButton = ({
  isOpen,
  icon,
  activeIcon,
  onClick,
  text,
  number, // not req
  style, // not req
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="d-flex align-items-center pinkhover"
      style={{
        height: "100%",
        padding: 8,
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: 200,
        backgroundColor: isOpen ? colors.lightPink : "",
        ...style,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <img src={isOpen || isHovered ? activeIcon : icon} className="mr-1" />
      <GothamMedium
        style={{ color: isOpen || isHovered ? colors.pink : colors.grey6c }}
      >
        {text}
      </GothamMedium>
      {Boolean(number) && <EWalletFilterNumber>{number}</EWalletFilterNumber>}
    </div>
  );
};

export const EWalletFilters = ({
  endButtons = [], // conditional
  buttons = [], // conditional
  placeholder = "Search keywords",
  array = [], // is a must
  additionalMarkerArr: additionalMarkerArrRaw = [], // conditional
  resetQueries = [], // very conditional (currently only used in e-wallet)
  longSearchbar = false,
}) => {
  const { query, push } = useRouter();

  const isLongPlaceholder = placeholder?.length > 33;

  const { isOpen, close, toggle } = useModalHook();

  const [tempStates, setTempStates] = useState("");

  useEffect(() => {
    const { runQuery, ...restQuery } = query;
    if (isOpen) {
      setTempStates(JSON.stringify(restQuery));
      return push({ query: { ...query, runQuery: false } });
    }

    const currQuery = JSON.stringify(restQuery);

    if (tempStates == currQuery) return;

    push({ query: { ...query, runQuery: true } });
  }, [isOpen]);

  const ref = useRef();

  useClickOutside({ ref, clickOutside: close });

  const queryInputWidth = isLongPlaceholder || longSearchbar ? 375 : 264;

  const queries = array.map(({ name }) => query[name]).filter((item) => item);

  useEffect(() => {
    if (!queries.length) return;
    if (!resetQueries.length) return;

    setTimeout(() => {
      const result = pickBy(query, (_, key) => !resetQueries.includes(key));
      push({ query: result });
    }, 200);
  }, [String(queries)]);

  const { markers, markersGenerated } = eWalletFiltersMarkers(
    array,
    additionalMarkerArrRaw
  );

  const runQuery = (queries) => {
    push({ query: { ...queries, runQuery: false } });
    push({ query: { ...queries, runQuery: true } });
  };

  const handleDelete = (value) => {
    if (value === "date") {
      const result = pickBy(query, (_, key) => {
        if (key === "to_date" || "from_date") return false;
        return true;
      });
      return runQuery(result);
    }

    const result = pickBy(query, (_, key) => value != key);
    runQuery(result);
  };

  const handleDeleteAll = () => {
    let result = query;
    array.forEach(({ name }) => {
      const isDate = name == "date";
      const isAmount = name == "amount";

      if (isAmount) {
        delete result["amount_type"];
        delete result["specific_amount"];
        delete result["minimum_amount"];
        delete result["maximum_amount"];
        return;
      }

      if (isDate) {
        delete result["from_date"];
        return delete result["to_date"];
      }
      return delete result[name];
    });

    push({ query: result });
    runQuery();
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div
            style={{
              width: queryInputWidth,
              position: "relative",
              marginRight: 8,
            }}
          >
            <DefaultQueryInput
              placeholder={placeholder}
              containerStyle={{ marginBottom: 0 }}
              onChange={(search_box) => runQuery({ ...query, search_box })}
              woQuery
              inputStyle={{ width: "100%" }}
            />
          </div>
          <div style={{ position: "relative" }} ref={ref}>
            <EWalletFilterButton
              activeIcon={filterPinkIcon}
              icon={filterGrayIcon}
              isOpen={isOpen}
              onClick={toggle}
              text="Filter"
              style={{ marginRight: 8 }}
            />
            {/* what you actually care about */}
            <EWalletFilterModal
              isOpen={isOpen}
              style={{ left: -queryInputWidth - 8 }}
              array={array}
              toggle={toggle}
            />
            {/* what you actually care about */}
          </div>
          {buttons.map((button, index) => (
            <div style={{ marginRight: 8 }} key={index}>
              {button}
            </div>
          ))}
        </div>
        {endButtons.map((button, index) => (
          <div style={{ marginLeft: 8 }} key={index}>
            {button}
          </div>
        ))}
      </div>
      <div className="d-flex">
        {markers.map((item, index) => {
          const { show, label, name, isPink } = item || {};
          if (!show) return null;
          return (
            <MarkerContainer
              key={index}
              style={{
                backgroundColor: isPink
                  ? "rgba(253, 215, 245, .4)"
                  : colors.greyea,
              }}
            >
              <GothamRegular className="font12">{label}</GothamRegular>
              <img
                src={xIcon}
                style={{ height: 16, marginLeft: 4 }}
                className="darkhover"
                onClick={() => handleDelete(name)}
              />
            </MarkerContainer>
          );
        })}
        {!!markersGenerated.filter(({ show }) => show).length && (
          <div>
            <MarkerContainer
              style={{ backgroundColor: "transparent" }}
              className="d-flex align-items-center hover"
              onClick={handleDeleteAll}
            >
              <GothamMedium style={{ color: colors.pink }}>
                Reset Filter
              </GothamMedium>
            </MarkerContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export const MarkerContainer = ({ children, style, ...props }) => (
  <div
    className="d-flex align-items-center"
    style={{
      height: 32,
      backgroundColor: colors.greyea,
      padding: "4px 8px 4px 8px",
      marginTop: 12,
      borderRadius: 8,
      marginRight: 8,
      ...style,
    }}
    {...props}
  >
    {children}
  </div>
);
