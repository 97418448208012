import React, { Fragment, useState } from "react";
import { colors } from "../assets/colors";
import { GothamMedium } from "../components/Text";

export const TopTabs = ({ data = [], style }) => {
  const [activeScreen, setActiveScreen] = useState(0);
  return (
    <>
      <div
        className="d-flex justify-content-between"
        style={{
          marginTop: 20,
          border: "1px solid rgba(0,0,0,.05)",
          borderWidth: 0,
          borderBottomWidth: 1,
          ...style,
        }}
      >
        {data.map((item, index) => {
          const { screenName, icon } = item;
          const isActive = activeScreen == index;
          const activeStyle = {
            position: "relative",
            top: -4,
            right: 8,
          };
          return (
            <div
              className="d-flex justify-content-center align-items-center hover"
              onClick={() => setActiveScreen(index)}
              style={{
                width: `${100 / data.length}%`,
                border: "1px solid black",
                borderWidth: 0,
                borderBottomWidth: 4,
                borderColor: isActive ? colors.lightPink : "transparent",
              }}
              key={index}
            >
              {icon && (
                <img
                  src={icon}
                  style={
                    isActive
                      ? { ...activeStyle }
                      : { ...activeStyle, filter: "grayscale(100%)" }
                  }
                />
              )}
              <GothamMedium
                style={{
                  color: isActive ? colors.pink : colors.greyC2,
                  marginBottom: 8,
                  fontSize: 16,
                }}
              >
                {screenName}
              </GothamMedium>
            </div>
          );
        })}
      </div>
      {data.map(({ screen }, index) => {
        const isActive = activeScreen == index;
        if (!isActive) return null;
        if (!screen) return null;
        return <Fragment key={index}>{screen}</Fragment>;
      })}
    </>
  );
};
