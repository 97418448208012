import { AxiosResponse } from "axios";

const pinLSKey = "pin-remaining-attempt";
const otpLSKey = "OTL";

const func = (res: AxiosResponse<any>) => {
  const { config, status } = res || {};
  const { data: dataRaw } = config || {};
  const isFormData = dataRaw instanceof FormData;
  const isPinGet = String(config.url).includes("pin=");

  if (isPinGet) return localStorage.removeItem(pinLSKey);
  if (!dataRaw) return;
  const isSuccess = status === 200;
  if (!isSuccess) return;
  if (isFormData) return;
  const data = JSON.parse(dataRaw) || {};
  const { pin, otp_code } = data;

  if (pin) return localStorage.removeItem(pinLSKey);
  if (otp_code) return localStorage.removeItem(otpLSKey);
};

export const responseInterceptor = (res: AxiosResponse<any>) => {
  func(res);

  return res;
};
