import { toNumber } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { NakedSearchInput } from "../../../../../../../components/inputs";
import { GothamMedium } from "../../../../../../../components/Text";
import {
  formatCurrencyNoDecimal,
  unformatCurrency,
  useDebounce,
} from "../../../../../../../components/tools";
import { EWalletFilterModalAttachment } from "./attachment";

const Input = ({ label, placeholder, name, disabled }) => {
  const { query, push } = useRouter();
  const [amount, setAmount] = useState(query[name]);
  const debouncedAmount = useDebounce(amount, 400);

  useEffect(() => {
    if (!debouncedAmount) {
      delete query[name];
      push({ query });
      return;
    }
    let obj = query;
    obj[name] = unformatCurrency(amount);
    push({ query: obj });
  }, [debouncedAmount]);

  return (
    <>
      <GothamMedium style={{ marginBottom: 4 }}>{label}</GothamMedium>
      <NakedSearchInput
        onChange={(text) => setAmount(text)}
        placeholder={placeholder}
        disabled={disabled}
        woSearch
        value={amount ? formatCurrencyNoDecimal(amount) : ""}
        styleInput={{ paddingLeft: 8 }}
      />
    </>
  );
};

export const EWalletFilterModalAmount = ({ activeObj }) => {
  const { noDirection } = activeObj || {};

  const array = noDirection
    ? []
    : [
        { label: "Any", value: "" },
        { label: "In (e.g. top up, refund)", value: "money_in" },
        { label: "Out (e.g. send money, payment)", value: "money_out" },
      ];

  const { query } = useRouter();
  const { specific_amount, minimum_amount, maximum_amount } = query;

  const disabledSpecific = toNumber(minimum_amount) || toNumber(maximum_amount);
  const disabledAny = toNumber(specific_amount);

  const inputArray = [
    {
      label: "Specific Amount",
      placeholder: "= IDR",
      name: "specific_amount",
      disabled: disabledSpecific,
    },
    {
      label: "Minimum Amount",
      placeholder: "≥ IDR",
      name: "minimum_amount",
      disabled: disabledAny,
    },
    {
      label: "Maximum Amount",
      placeholder: "≤ IDR",
      name: "maximum_amount",
      disabled: disabledAny,
    },
  ];

  return (
    <div>
      {!noDirection && (
        <GothamMedium style={{ marginBottom: 12 }}>Direction</GothamMedium>
      )}
      <EWalletFilterModalAttachment
        activeObj={{ array, name: "amount_type" }}
      />
      {inputArray.map((item, index) => (
        <Input {...item} key={index} />
      ))}
    </div>
  );
};
