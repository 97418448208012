import { useRouter } from "next/router";
import React, { useState } from "react";
import { NakedSearchInput } from "../../../../../../../components/inputs";
import { queryArray } from "./parent";
import { selectResult } from "./status";
import checkedIcon from "../../../../../../../assets/images/checked-icon.svg";
import uncheckedIcon from "../../../../../../../assets/images/unchecked-icon.svg";
import { GothamRegular } from "../../../../../../../components/Text";
import { noCase } from "change-case";

export const CreatedByModalContent = ({ activeObj }) => {
  const { query, push } = useRouter();
  const { array, name } = activeObj || {};
  const queryValue = queryArray(query, name);

  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(queryValue);

  //================================
  const selectedStatusNumber = selectedStatus?.map((item) => Number(item));
  const filteredArray = array?.filter(({ name }) =>
    noCase(name).includes(noCase(search))
  );
  //================================

  return (
    <div
      style={{
        height: "100%",
        position: "relative",
        overflow: "scroll",
      }}
    >
      <NakedSearchInput
        onChange={(e) => setSearch(e)}
        placeholder="Search user"
      />
      {filteredArray?.map((item, index) => {
        const { name, id } = item || {};
        const isActive = selectedStatusNumber.includes(id);
        return (
          <div
            key={index}
            style={{ cursor: "pointer", marginBottom: 12 }}
            onClick={() =>
              setSelectedStatus((prev) => {
                const result = selectResult(prev, id);

                let obj = query;
                obj["created_by"] = result;

                // disimpen juga ke query
                push({ query: obj });
                // disimpen juga ke query

                return result;
              })
            }
          >
            <div className="d-flex">
              <img
                src={isActive ? checkedIcon : uncheckedIcon}
                style={{ marginRight: 8 }}
              />
              <GothamRegular style={{ color: "#333334" }}>{name}</GothamRegular>
            </div>
          </div>
        );
      })}
    </div>
  );
};
