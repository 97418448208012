import React from "react";
import { FaSpinner } from "react-icons/fa";
const LoadingSpinner = ({ size = 32, style, className = "", ...props }) => (
  <div
    className={`d-flex justify-content-center align-items-center ${className}`}
    style={{ height: 200, ...style }}
  >
    <FaSpinner className="icon-spin " size={size} {...props} />
  </div>
);
export default LoadingSpinner;
