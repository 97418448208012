import { isEmpty, times } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { colors } from "../../../../../../../assets/colors";
import checkedIcon from "../../../../../../../assets/images/checked-icon.svg";
import uncheckedIcon from "../../../../../../../assets/images/unchecked-icon.svg";
import { GothamRegular } from "../../../../../../../components/Text";
import { arraySplitter } from "../../../../../../../components/tools";
import { queryArray } from "./parent";

export const selectResult = (prev, value) => {
  const isDelete = prev.includes(value);
  const filteredValues = prev.filter((prevValue) => prevValue !== value);
  const defaultValues = [...prev, value];

  const result = isDelete ? filteredValues : defaultValues;
  return result;
};

const customArraySplitter = (array) => {
  let firstArr = [];
  let secondArr = [];

  array.forEach((item, index) => {
    const middleIndex = Math.ceil((array.length - 1) / 2);
    if (index >= middleIndex) return firstArr.push(item);
    secondArr.push(item);
  });
  return [firstArr, secondArr];
};

export const EWalletFilterModalContentStatus = ({
  activeObj: { array, name, noSplit, loading },
}) => {
  const { query, push } = useRouter();

  const queryValue = queryArray(query, name);

  // disimpen di local component
  // disimpen juga ke query
  const [selectedStatus, setSelectedStatus] = useState(queryValue);

  useEffect(() => setSelectedStatus(queryValue), [queryValue.length]);

  const filtered = array.filter((item) => !isEmpty(item));

  const splittedArray =
    filtered.length <= 16
      ? arraySplitter(filtered, 8)
      : customArraySplitter(filtered);

  const getArray = (array) => (
    <>
      {(array || []).map((item, index) => {
        if (!item) return null;
        const { label, value, desc } = item || {};
        const isActive = selectedStatus.includes(value);
        return (
          <div
            className="hover"
            key={index}
            style={{ marginBottom: 16 }}
            onClick={() =>
              setSelectedStatus((prev) => {
                const result = selectResult(prev, value);

                let obj = query;
                obj[name] = result;

                // disimpen juga ke query
                push({ query: obj });
                // disimpen juga ke query

                return result;
              })
            }
          >
            <div className="d-flex">
              <img
                src={isActive ? checkedIcon : uncheckedIcon}
                style={{ marginRight: 8 }}
              />
              <GothamRegular
                style={{
                  color: "#333334",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {label}
              </GothamRegular>
            </div>
            {desc && (
              <GothamRegular
                style={{
                  color: colors.grey6c,
                  marginLeft: 28,
                }}
                className="font12"
              >
                {desc}
              </GothamRegular>
            )}
          </div>
        );
      })}
      {loading &&
        times(10, (index) => (
          <div className="d-flex" key={index} style={{ marginBottom: 16 }}>
            <Skeleton height={20} width={20} style={{ marginRight: 12 }} />
            <Skeleton height={20} width={100} />
          </div>
        ))}
    </>
  );

  if (noSplit) return getArray(array);

  const width = `${100 / splittedArray.length}%`;

  return (
    <div className="d-flex w-100">
      {splittedArray.map((array, index) => (
        <div key={index} style={{ width }}>
          {getArray(array)}
        </div>
      ))}
    </div>
  );
};
