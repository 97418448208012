import { useConstants } from "../../../../../../../contexts/ConstantsContext/parent";
import { EWalletFilterModalContentStatus } from "./status";

export const EWalletFilterModalTeam = ({ activeObj: { name } }) => {
  const { teamsData, teamsLoading } = useConstants();
  return (
    <div style={{ overflowY: "scroll" }} className="h-100">
      <EWalletFilterModalContentStatus
        activeObj={{
          array: teamsData.map((item) => ({
            ...item,
            value: String(item.value),
          })),
          name,
          loading: teamsLoading,
        }}
      />
    </div>
  );
};
