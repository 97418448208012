import { Table } from "reactstrap";
import { colors } from "../assets/colors";
import eyeGrey from "../assets/images/eye-grey.svg";
import eye from "../assets/images/eye-show.svg";
import trashBlack from "../assets/images/trash-black.svg";
import trashGreyV from "../assets/images/trash-grey-3.svg";
import trashGrey from "../assets/images/trash-grey2.svg";
import trash from "../assets/images/trash-pink.svg";
import { fileSize } from "../modals/smartActivityModal/invoicePayment/supportingDocs";
import { Tbody, TdHeader } from "../pageComponents/tableComponents";
import { DialogButton } from "./Buttons";
import { FileInput } from "./inputs";
import { LeftRightList } from "./Lists";
import { GothamLight, GothamRegular } from "./Text";
import { windowDimension } from "./tools";

// tdHeaderStyleFunc must be a function to know
// which header need to be styled
const Image = ({ src, onClick, item, ...rest }) => (
  <img
    src={src}
    className="ml-2 darkhover"
    style={{ zIndex: 1 }}
    onClick={(e) => {
      if (e) {
        e.preventDefault();
        onClick(item);
      }
    }}
    {...rest}
  />
);

const buttonProps = ({
  item,
  isTabOrPhone,
  trashAndShow,
  handleShow,
  handleDelete,
  hideField,
  canDelete,
  showIcon = eye,
  trashIcon,
  simpleTable,
}) => {
  const lastString = String(item).split(".").pop();
  const canShow = ["jpeg", "pdf", "jpg", "png"].includes(lastString);

  const DefaultTrash = ({ style, icon }) => {
    if (simpleTable)
      return (
        <DialogButton
          className="fake-button darkhover"
          woClassName
          style={{
            height: 24,
          }}
          onClick={() => handleDelete(item)}
          text={`Are you sure you want to delete ${item} ?`}
        >
          <img src={trashIcon || trash} />
        </DialogButton>
      );
    return (
      <Image
        src={trashIcon || icon || trash}
        onClick={handleDelete}
        item={item}
        style={style}
      />
    );
  };

  const DefaultShow = () => (
    <Image
      src={showIcon}
      onClick={handleShow}
      item={item}
      className="darkhover"
    />
  );

  const Show = () => {
    if (isTabOrPhone) return null;
    if (!canShow) return null;
    return <DefaultShow />;
  };

  const Trash = () => {
    if (canDelete) return <DefaultTrash />;
    if (hideField) return null;
    return <DefaultTrash />;
  };

  if (trashAndShow)
    return (
      <>
        <Show />
        <Trash />
      </>
    );

  return (
    <DefaultTrash icon={trashBlack} style={{ marginRight: 5, zIndex: 1 }} />
  );
};

export const MultipleInputTable = ({
  data,
  handleDelete,
  dataPinkButton,
  handleShow,
  trashAndShow,
  hideField,
  canDelete,
  simpleTable,
  isPinkButton,
  isReimbursementDraft,
  invoiceUploadLoading = false,
}) => {
  const defaultProps = {
    handleDelete,
    handleShow,
    isTabOrPhone,
    trashAndShow,
    hideField,
    canDelete,
    simpleTable,
  };
  const { figmaHeight, isTabOrPhone } = windowDimension();
  const dataThead = [
    {
      title: "File Name",
      handle: "file_name",
    },
    {
      title: "Action",
      handle: "action",
    },
  ];
  if (isReimbursementDraft) return null;
  if (isPinkButton)
    return (
      <div
        style={{
          padding: "16px 0px 24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <GothamLight style={{ color: "#909098", marginBottom: 16 }}>
          {`${data?.length} ${data?.length > 1 ? "files" : "file"} uploaded`}
        </GothamLight>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            maxHeight: 175,
            overflowY: "scroll",
            width: "100%",
          }}
        >
          {dataPinkButton?.map(({ type, size, name }, index) => {
            const icon =
              type == "application/pdf" ? "/images/pdf.svg" : "/images/jpg.svg";
            return (
              <div
                className="d-flex justify-content-between"
                key={index + 1}
                style={{ width: "96%" }}
              >
                <div
                  style={{
                    width: "93%",
                    minHeight: 60,
                    height: "auto",
                    border: "1px solid #E6E6E8",
                    borderRadius: 8,
                    padding: 12,
                    display: "flex",
                    gap: 8,
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      width: 36,
                      height: 36,
                      backgroundColor: "#E6E6E8",
                      borderRadius: 4,
                      display: "flex",
                      alignSelf: "center",
                      marginRight: 8,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={icon} style={{ height: 30, width: "auto" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "85%",
                      wordWrap: "break-word",
                    }}
                  >
                    <GothamRegular>{name}</GothamRegular>
                    <GothamLight style={{ fontSize: 12 }}>
                      {fileSize(size)}
                    </GothamLight>
                  </div>
                </div>
                <img
                  src={trashGreyV}
                  style={{ cursor: "pointer", marginLeft: 16 }}
                  onClick={() => {
                    if (!invoiceUploadLoading) handleDelete(name);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );

  if (simpleTable)
    return (
      <LeftRightList
        array={(data || []).map((item) => ({
          left: <GothamRegular>{item}</GothamRegular>,
          right: buttonProps({
            ...defaultProps,
            item,
            trashIcon: trashGrey,
            showIcon: eyeGrey,
          }),
        }))}
      />
    );

  return (
    <>
      <div
        className="table-responsive mt-4"
        style={{
          border: "1px solid rgba(194, 194, 194,.5)",
          borderBottomWidth: 0,
          maxHeight: figmaHeight(200),
        }}
      >
        <Table className="table-transfez w-100 mb-0">
          <thead>
            <tr>
              {dataThead.map(({ title, handle }, index) => {
                const isFirst = index === 0;
                if (isFirst)
                  return (
                    <td key={index} style={{ width: "80%" }}>
                      {title}
                    </td>
                  );

                return (
                  <TdHeader
                    isCustomStyle
                    noSortButton
                    key={index}
                    style={{ paddingTop: 10 }}
                    className="d-flex justify-content-center "
                  >
                    {title}
                  </TdHeader>
                );
              })}
            </tr>
          </thead>

          <Tbody totalHeader={dataThead.length}>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item}</td>
                <td className="d-flex justify-content-center">
                  {buttonProps({ ...defaultProps, item })}
                </td>
              </tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <div
        className="w-100"
        style={{ height: 1, backgroundColor: "rgba(194, 194, 194,.2)" }}
      ></div>
      <div
        className="d-flex"
        style={{
          border: "1px solid rgba(194, 194, 194,.5)",
          borderTopWidth: 0,
          width: "100%",
          position: "relative",
          height: 56,
        }}
      >
        <GothamRegular
          style={{ color: colors.grey72, marginTop: 11, marginLeft: 12 }}
        >
          Total Files: {data.length} File
        </GothamRegular>
      </div>
    </>
  );
};

export const FileInputTable = ({
  defaultValue,
  useFormObj,
  name = "file_input_table_files",
  fileTypes = ["image/jpeg", "application/pdf"],
  ...props
}) => (
  <FileInput
    defaultValue={defaultValue}
    fileTypes={fileTypes}
    maxSize={25000000}
    name={name}
    type="general"
    useFormObj={useFormObj}
    hideField
    canDelete
    trashAndShow
    isDottedBorder
    maxSizeInfo
    formData
    longText
    simpleTable
    {...props}
  />
);
