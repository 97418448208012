import { uniqueId } from "lodash";
import React, { useMemo, useState } from "react";
import { Tooltip } from "reactstrap";
import { GothamRegular } from "./Text";

export const AdvancedCustomTooltip = ({
  children,
  component = null,
  disable,
}) => {
  const [dimensions, setDimensions] = useState(false);
  const { clientHeight, clientWidth } = dimensions || {};

  if (disable) return children;

  return (
    <CustomTooltip
      style={{
        backgroundColor: "white",
        boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.1)",
        opacity: 1,
        maxWidth: "none",
        position: "fixed",
        top: -clientHeight,
        left: -clientWidth / 2,
      }}
      popperClassName="white-arrow"
      text={
        <div
          ref={(e) => {
            if (!e) return;
            const { clientHeight, clientWidth } = e;
            setDimensions((prev) => {
              const { clientHeight: pclientHeight, clientWidth: pclientWidth } =
                prev || {};

              if (pclientHeight == clientHeight && pclientWidth == clientWidth)
                return prev;

              return { clientHeight, clientWidth };
            });
          }}
        >
          {component}
        </div>
      }
    >
      {children}
    </CustomTooltip>
  );
};

const CustomTooltip = ({
  children,
  placement = "top",
  text = "",
  customId = null,
  alwaysOpen = false,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipId = useMemo(() => "tooltip" + uniqueId(), []);

  return (
    <span id={"Tooltip-" + (customId || tooltipId)}>
      {children}
      <Tooltip
        placement={placement}
        isOpen={tooltipOpen || alwaysOpen}
        target={"Tooltip-" + (customId || tooltipId)}
        toggle={toggle}
        {...props}
      >
        {text}
      </Tooltip>
    </span>
  );
};
export default CustomTooltip;

export const TooltipCustom = ({ textInside, children }) => {
  const [show, setShow] = useState(true);
  return (
    <>
      <div
        style={{
          position: "relative",
          display: "inline-block",
        }}
        // onMouseEnter={() => setShow(true)}
        // onMouseLeave={() => setShow(false)}
      >
        {children}
        {show && (
          <div
            style={{
              position: "absolute",
              background: "#FDD7F5",
              boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.06)",
              borderRadius: 4,
              width: 200,
              top: -10,
              right: -215,
              zIndex: 20,
              padding: 8,
            }}
          >
            <div
              style={{
                position: "absolute",
                left: -10,
                top: 10,
                width: 0,
                height: 0,
                borderTop: "9px solid transparent",
                borderBottom: "9px solid transparent",
                borderRight: "13px solid #FDD7F5",
              }}
            ></div>
            <GothamRegular style={{ fontSize: 12, lineHeight: "16px" }}>
              {textInside}
            </GothamRegular>
          </div>
        )}
      </div>
    </>
  );
};
