// import { camelCase } from "change-case";
import { camelCase } from "lodash";
import dynamic from "next/dynamic";
import { jackImages } from "./constants/parent";
import { useMemo } from "react";
// if your image is not the one you want, try this steps:
// 1. adds "2" in your name. Eg: "book", change it to "book2", if its still not your img. Try "book3" and so on
// 2. If it doesnt exist, feel free to add it in images/jack. And import it into `jackImages` constant

// importing rule (if icon doesn't exist):
// Please follow figma's name. So every developer can guess it intuitively

// u can pass naming "camelCase", or "dash-case" or "snake_case", or even "normal text", JackIcons doesnt care.
// Whatever you type on `name` props will always be converted to camelCase

const images = Object.fromEntries(
  jackImages.map((url) => [
    url,
    dynamic(() => import(`../jackIcons/constants/images/${url}`), {
      loading: ({ isLoading }) => <div style={{ width: 24, height: 24 }}></div>,
    }),
  ])
);

export const JackIcons = ({
  name = "",
  style = {},
  className = "",
  fill = "white",
  onClick = () => {},
  styleDiv = {},
  isStopBubbling = false,
  ref = null,
  alignItems = "align-items-center",
  ...props
}) => {
  const url = jackImages.find((key) => camelCase(key) === camelCase(name));

  const Icon = useMemo(() => images[url], [url]);

  const handleClick = (event) => {
    isStopBubbling && event.stopPropagation();
    onClick(event);
  };

  if (!url) return null;

  const jackIconProps = {
    ref,
    className: `d-flex justify-content-center ${alignItems} ${className}`,
    onClick: handleClick,
    style: { ...styleDiv },
  };

  if (!!props["data-testid"]) {
    jackIconProps["data-testid"] = props["data-testid"];
  }
  if (!!props.role) {
    jackIconProps.role = props.role;
  }

  return (
    <div {...jackIconProps}>
      <Icon fill={fill} style={style} />
    </div>
  );
};
