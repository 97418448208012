import React from "react";
import crossBorderIcon from "../../assets/images/cross-border.svg";
import right from "../../assets/images/circle-right.svg";
import { MainCardHeaderMolecule } from "../../components/CardModal";
import {
  GothamLight,
  GothamMedium,
  GothamRegular,
} from "../../components/Text";
import LoadingSpinner from "../../components/Loading";
import { StatusAccountTag, StatusActivities } from "../../components/Status";
import { colors } from "../../assets/colors";

const BottomFont = ({ children, textAlign = "left" }) => (
  <GothamLight
    className="m-0 mt-1"
    style={{
      color: "white",
      width: "50%",
      lineHeight: "22px",
      textAlign: "center",
    }}
  >
    {children}
  </GothamLight>
);

export const ActivityDetailsHeader = ({
  toggle,
  isClose = true,
  isWhite = false,
  isSwitchTitle = false,
  data: {
    headerLeft = "",
    headerRight = "",
    type = "",
    icon = crossBorderIcon,
    text = "",
    title = "",
    leftText = "",
    rightText = "",
    state = "",
  },
  loading,
  ...props
}) => {
  const subComponent = () => {
    if (leftText && rightText)
      return (
        <div
          className="d-flex justify-content-around align-items-center"
          style={{ width: "100%" }}
        >
          <BottomFont>{leftText}</BottomFont>
          <img src={right} />
          <BottomFont textAlign="right">{rightText}</BottomFont>
        </div>
      );
    if (state)
      return (
        <StatusActivities
          state={state}
          style={{ fontSize: 12, lineHeight: "20px" }}
          containerStyle={{
            backgroundColor: "white",
            paddingLeft: 8,
            paddingRight: 8,
            borderRadius: 100,
          }}
        />
      );
    return (
      <GothamLight
        style={{
          color: "white",
          lineHeight: "22px",
          textAlign: "center",
        }}
      >
        {text}
      </GothamLight>
    );
  };

  const mainComponent = () => {
    if (loading) return <LoadingSpinner style={{ height: 100 }} />;

    return (
      <div
        style={{
          marginTop: 12 - 40,
          flexDirection: "column",
        }}
        className="w-100 align-items-center d-flex"
      >
        {icon && <img src={icon} style={{ marginBottom: 12 }} />}
        <GothamLight
          style={{
            marginBottom: 4,
            color: isWhite ? colors.grey33 : "white",
            lineHeight: "22px",
            textAlign: "center",
          }}
        >
          {type}
        </GothamLight>
        {isSwitchTitle ? (
          <>
            {subComponent()}
            <GothamMedium
              style={{
                marginTop: 8,
                color: isWhite ? colors.grey33 : "white",
                fontSize: 20,
                lineHeight: "24px",
              }}
            >
              {title}
            </GothamMedium>
          </>
        ) : (
          <>
            <GothamMedium
              style={{
                marginBottom: 4,
                color: isWhite ? colors.grey33 : "white",
                fontSize: 24,
                lineHeight: "32px",
              }}
            >
              {title}
            </GothamMedium>
            {subComponent()}
          </>
        )}
      </div>
    );
  };

  return (
    <MainCardHeaderMolecule
      isClose={isClose}
      toggle={toggle}
      headerArr={loading ? [] : [headerLeft, headerRight]}
      customComponent={mainComponent()}
      {...props}
    />
  );
};
