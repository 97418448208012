import React, { useEffect, useState } from "react";
import { BiDetail } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";
import { Modal } from "reactstrap";
import { colors } from "../assets/colors";
import leftArrowIcon from "../assets/images/left-arrow-pink.svg";
import xIcon from "../assets/images/x-icon-pink.svg";
import { BackNavigation } from "../pageComponents/accountDashboardComponents";
import LoadingSpinner from "./Loading";
import { useModalHook } from "./Modals";
import { GothamMedium, GothamRegular } from "./Text";
import { windowDimension } from "./tools";

const WingToggler = ({ toggle }) => (
  <div className="d-flex">
    <GothamRegular
      className="px-2 darkhover"
      style={{ color: "white" }}
      onClick={toggle}
    >
      <BiDetail className="mr-2 mb-1" />
      Details
    </GothamRegular>
  </div>
);

const WingContainer = ({ wingWidth, children }) => {
  const { width, sidebarTotalWidth, is1440, mainComponentWidth } =
    windowDimension();

  return (
    <div
      style={{
        boxShadow: `0px 8px 40px rgba(73, 171, 184, 0.3)`,
        position: "absolute",
        height: "100%",
        width: wingWidth
          ? wingWidth
          : is1440
          ? width - sidebarTotalWidth - (width - mainComponentWidth) / 2.7
          : width - sidebarTotalWidth - 35,
        backgroundColor: "white",
        right: 0,
        borderRadius: 24,
      }}
    >
      <div
        style={{
          width: "calc(100% - 400px)",
          height: "100%",
          padding: 20,
          paddingRight: 0,
          borderRight: `1px solid #E6E6E8`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

const RightCardModalAtomicRaw = ({ isOpen, toggle, hideModal, children }) => {
  const { height, width, isTabOrPhone, isSmall } = windowDimension();
  const paddingVertical = 20;
  return (
    <Modal
      toggle={() => {
        toggle && toggle();
      }}
      isOpen={isOpen}
      backdropTransition={{ timeout: 3000000 }}
      contentClassName={`right-card-modal  ${
        hideModal ? "fade-out" : "fade-in"
      }`}
      style={{
        position: "absolute",
        right: isSmall ? -4 : isTabOrPhone ? 0 : 20,
        top: isTabOrPhone ? 0 : paddingVertical - 30,
        width: isTabOrPhone ? width - 8 : 400,
        maxWidth: 400,
        paddingBottom: 20,
      }}
    >
      <div
        style={{
          border: "0px solid transparent",
          boxShadow: `0px 8px 40px rgba(73, 171, 184, 0.3)`,
          borderRadius: 24,
          paddingBottom: 20,
          minHeight: height - paddingVertical * 2,
        }}
      >
        {children}
      </div>
    </Modal>
  );
};
const RightCardWingPhoneModal = ({ isOpen, toggle, children }) => {
  return (
    <RightCardModalAtomicRaw isOpen={isOpen} toggle={toggle}>
      <div
        style={{
          padding: "20px 20px 36px 20px",
        }}
      >
        <BackNavigation onClick={toggle} />
        {children}
      </div>
    </RightCardModalAtomicRaw>
  );
};

export const RightCardModalAtomic = ({
  isOpen,
  headerStyle,
  header = null,
  middleStyle,
  middleHeight,
  middle = null,
  bodyStyle,
  body = null,
  paddingTop = 24,
  hideModal: hideModalProps,
  toggle: toggleProps,
  wingComponent,
  isWhite = false,
  idWings,
  loading,
  wingWidth = null,
}) => {
  const { figmaHeight, isTabOrPhone } = windowDimension();

  const toggle = () => {
    if (toggleProps) return toggleProps();
    return header?.props?.toggle && header?.props?.toggle();
  };
  const [isFadeIn, setIsFadeIn] = useState("slide-in");

  useEffect(() => {
    if (!idWings) return;
    setIsFadeIn("slide-in");
    setTimeout(() => setIsFadeIn("slide-out"), 1);
  }, [isOpen, isWhite, idWings]);

  const { isOpen: isOpenWingPhone, toggle: toggleWingPhone } = useModalHook();

  const hideModal = hideModalProps || isOpenWingPhone;

  return (
    <>
      <RightCardModalAtomicRaw
        isOpen={isOpen}
        toggle={toggle}
        hideModal={hideModal}
      >
        {wingComponent && !isTabOrPhone && (
          <WingContainer wingWidth={wingWidth}>{wingComponent}</WingContainer>
        )}
        <div className={idWings ? isFadeIn : ""}>
          <div
            style={{
              background: isWhite
                ? "white"
                : "linear-gradient(180deg, #102C57 0%, #486896 100%)",
              borderTopLeftRadius: isTabOrPhone ? 24 : wingComponent ? 0 : 24,
              borderTopRightRadius: 24,
              padding: "20px 20px 36px 20px",
              position: "relative",
              minHeight: figmaHeight(100),
              ...headerStyle,
            }}
          >
            {isTabOrPhone && Boolean(wingComponent) && (
              <WingToggler toggle={toggleWingPhone} />
            )}
            {loading ? (
              <div>
                <div className="d-flex justify-content-end">
                  <Skeleton
                    height={15}
                    width={70}
                    style={{ backgroundColor: colors.greye6 }}
                  />
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: 20 }}
                ></div>
                <div className="text-center" style={{ marginTop: 20 }}>
                  <Skeleton
                    height={15}
                    width={200}
                    style={{ backgroundColor: colors.greye6 }}
                  />
                  <Skeleton
                    height={30}
                    width={200}
                    style={{ backgroundColor: colors.greye6 }}
                  />
                </div>
              </div>
            ) : (
              header
            )}
          </div>
          <div
            style={{
              position: "relative",
              marginTop: -24,
              ...middleStyle,
            }}
          >
            {loading ? null : middle}
          </div>
          <div
            style={{
              position: "relative",
              padding: `${
                middleHeight ? `${middleHeight / 2 + paddingTop}px` : "12px"
              } 20px 0px 20px`,
              backgroundColor: "white",
              marginTop: -20,
              borderTopLeftRadius: 24,
              borderTopRightRadius: 24,
              ...bodyStyle,
            }}
          >
            {loading ? <LoadingSpinner /> : body}
          </div>
        </div>
      </RightCardModalAtomicRaw>
      <RightCardWingPhoneModal
        isOpen={isOpenWingPhone}
        toggle={toggleWingPhone}
      >
        {wingComponent}
      </RightCardWingPhoneModal>
    </>
  );
};

// HEADERS COMPONENTS
// atomic headers
export const CloseHeader = ({
  toggle,
  color = "white",
  isMedium = false,
  isJustClose = false,
}) => (
  <>
    <div></div>
    <div
      className={`d-flex ${
        isJustClose && "modal-close-button"
      } align-items-center`}
      onClick={toggle}
      style={{ cursor: "pointer" }}
    >
      {isMedium ? (
        <GothamMedium
          style={{
            fontSize: 12,
            marginBottom: 0,
            marginRight: 5,
            color,
          }}
        >
          Close
        </GothamMedium>
      ) : (
        <GothamRegular
          style={{
            fontSize: 12,
            marginBottom: 0,
            marginRight: 5,
            color,
          }}
        >
          Close
        </GothamRegular>
      )}
      <img src={xIcon} style={{ color: colors.pink }} />
    </div>
  </>
);

export const BackHeader = ({
  toggle,
  color = "white",
  isMedium = false,
  text = "Back",
}) => (
  <>
    <div
      className="d-flex align-items-center"
      onClick={toggle}
      style={{ cursor: "pointer" }}
    >
      <img src={leftArrowIcon} style={{ color: "#FFD27C" }} />
      <GothamRegular
        style={{
          fontFamily: isMedium ? "GothamMedium" : "GothamBook",
          fontSize: 12,
          marginBottom: 0,
          marginLeft: 5,
          color,
        }}
      >
        {text}
      </GothamRegular>
    </div>
    <div></div>
  </>
);
// atomic headers

export const DefaultCustomComponent = ({ title, subTitle, msg }) => (
  <>
    <GothamRegular style={{ margin: 0, marginTop: 12, color: "white" }}>
      {title}
    </GothamRegular>
    <GothamMedium
      style={{
        fontSize: 24,
        color: "white",
        marginTop: 4,
        marginBottom: 4,
      }}
    >
      {subTitle}
    </GothamMedium>
    {Boolean(msg) && (
      <GothamRegular
        className="m-0"
        style={{
          color: "white",
        }}
      >
        {msg}
      </GothamRegular>
    )}
  </>
);

export const MainCardHeaderMolecule = ({
  toggle,
  icon = null,
  title,
  subTitle,
  subtitle,
  headerArr = [],
  middleHeight = 0,
  smallFont,
  isClose = true,
  alignLeft = false,
  yellowBar = false,
  subTitle14 = false,
  customComponent,
  close,
}) => {
  const { figmaHeight } = windowDimension();
  const [minHeight, setMinHeight] = useState(0);

  const HeaderDecider = () => {
    if (close) return close;
    if (isClose) return <CloseHeader toggle={toggle} />;
    return <BackHeader toggle={toggle} />;
  };

  return (
    <>
      <div
        style={{ paddingBottom: figmaHeight(24) }}
        ref={(e) => {
          if (e) {
            const substractor = e.clientHeight + 40;
            const positive = figmaHeight(100);
            setMinHeight(positive - substractor);
          }
        }}
      >
        <div className="d-flex justify-content-between">
          <HeaderDecider />
        </div>
        {Boolean(headerArr.length) && (
          <div
            className="d-flex justify-content-between"
            style={{ paddingTop: figmaHeight(24) }}
          >
            {headerArr.map((header, index) => (
              <GothamRegular
                key={index}
                style={{ fontSize: 12, color: "white" }}
              >
                {header}
              </GothamRegular>
            ))}
          </div>
        )}
      </div>
      <div
        className={
          alignLeft
            ? ""
            : "d-flex flex-column align-items-center justify-content-center"
        }
        style={{
          minHeight,
          marginBottom: middleHeight / 2,
        }}
      >
        {yellowBar && (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                right: -20,
                height: 90,
                backgroundColor: colors.yellowFF,
                width: 8,
                borderRadius: 4,
              }}
            />
          </div>
        )}
        <div className="d-flex align-items-center justify-content-center">
          {icon}
        </div>
        {customComponent ? (
          customComponent
        ) : (
          <>
            <GothamMedium
              style={{
                marginTop: subTitle ? figmaHeight(smallFont ? 24 : 12) : 0,
                color: "white",
              }}
              className={smallFont ? "font14" : "font24"}
            >
              {title}
            </GothamMedium>
            <GothamMedium
              style={{
                marginBottom: figmaHeight(12),
                color: "white",
              }}
              className={smallFont ? "font14" : "font24"}
            >
              {subtitle}
            </GothamMedium>
            <GothamRegular
              style={{
                fontSize: subTitle14 ? 14 : 12,
                lineHeight: subTitle14 ? "22px" : "18px",
                color: colors.whiteFC,
              }}
            >
              {subTitle}
            </GothamRegular>
          </>
        )}
      </div>
    </>
  );
};
// HEADERS COMPONENTS

// MIDDLE COMPONENTS
export const MiddleWrapper = ({ children, height = 0, setHeight, style }) => (
  <div
    style={{
      width: "calc(100% - 40px)",
      position: "absolute",
      margin: "auto",
      bottom: -height / 2,
      left: 0,
      right: 0,
      zIndex: 1,
      ...style,
    }}
  >
    <div
      ref={(e) => {
        if (!setHeight) return;
        if (!e) return;
        setHeight(e.clientHeight);
      }}
    >
      {children}
    </div>
  </div>
);
// MIDDLE COMPONENTS
