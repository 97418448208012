import React, { useRef, useState } from "react";
import { Table } from "reactstrap";
import { colors } from "../assets/colors";
import checkSquareIcon from "../assets/images/check-square-icon.svg";
import ThreeDotsClicked from "../assets/images/DotsThree-clicked.svg";
import ThreeDots from "../assets/images/DotsThree-unclicked.svg";
import emptySquareIcon from "../assets/images/empty-square-icon.svg";
import refreshIcon from "../assets/images/refresh-table.svg";
import { JackIcons } from "../assets/jackIcons/parent";
import { useModalHook } from "../components/Modals";
import { MappedTableShimmer } from "../components/Shimmer";
import {
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../components/Text";
import { TransitionTr } from "../components/Transition";
import { useClickOutside } from "../universalFunctions/useClickOutside";
import { useTranslation } from "react-i18next";

export const TableRadio = ({ isActive, onClick }) => (
  <img
    src={isActive ? checkSquareIcon : emptySquareIcon}
    className="darkhover"
    onClick={onClick}
  />
);

export const CustomTable = ({ headerData, items, loading, style }) => {
  const TDConstructor = ({ item }) => {
    const td = [];
    headerData.map(({ title }, index) =>
      td.push(<td key={index}>{item[title]}</td>)
    );
    return td;
  };

  const maskingTitle = (title) => {
    if (title === "Bank Id") return "Bank Name";
    return title;
  };

  return (
    <div
      className="table-responsive mt-1"
      style={{ border: "1px solid rgba(194, 194, 194,.5)", ...style }}
    >
      <Table className="table-transfez w-100">
        <>
          <thead>
            <tr>
              {headerData.map((item, index) => {
                const { title, onClick, style, ...props } = item;
                if (onClick) {
                  return (
                    <TdHeader key={index} {...item}>
                      {maskingTitle(title)}
                    </TdHeader>
                  );
                }
                return (
                  <td key={index}>
                    <div style={{ width: 200, ...style }} {...props}>
                      {maskingTitle(title)}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <Tbody
            loading={loading}
            totalItem={3}
            totalHeader={headerData.length}
          >
            {items.map((item, index) => (
              <tr key={index}>
                <TDConstructor item={item} />
              </tr>
            ))}
          </Tbody>
        </>
      </Table>
    </div>
  );
};

export const TdHeader = ({
  children,
  onClick,
  textStyle,
  style,
  noSortButton,
  isCustomStyle = false,
  headerStyle,
  ...rest
}) => (
  <td {...rest} style={headerStyle}>
    <div
      className="mx-0 justify-content-between align-items-center d-flex"
      style={isCustomStyle ? style : { minWidth: 200 }}
    >
      <GothamMedium
        style={
          isCustomStyle
            ? { fontSize: 14, ...textStyle }
            : { width: 200 - 40, fontSize: 14 }
        }
        className="my-0"
      >
        {children}
      </GothamMedium>
      {noSortButton ? (
        <></>
      ) : (
        <img
          onClick={onClick}
          src={refreshIcon}
          className="darkhover"
          style={{ borderRadius: 1000 }}
        />
      )}
    </div>
  </td>
);

export const ThHeader = ({
  children,
  onClick,
  textStyle,
  style,
  noSortButton,
  isCustomStyle = false,
  ...rest
}) => (
  <th {...rest}>
    <div
      className="mx-0 justify-content-between align-items-center d-flex"
      style={isCustomStyle ? style : { minWidth: 200 }}
    >
      <GothamMedium
        style={
          isCustomStyle
            ? { fontSize: 14, ...textStyle }
            : { width: 200 - 40, fontSize: 14 }
        }
        className="my-0"
      >
        {children}
      </GothamMedium>
      {noSortButton ? (
        <></>
      ) : (
        <img
          onClick={onClick}
          src={refreshIcon}
          className="darkhover"
          style={{ borderRadius: 1000 }}
        />
      )}
    </div>
  </th>
);

export const Tbody = ({ children, loading, totalHeader, totalItem = 10 }) => {
  if (loading) {
    return (
      <MappedTableShimmer totalHeader={totalHeader} totalItem={totalItem} />
    );
  }
  return <tbody>{children}</tbody>;
};

export const TdText = ({ children, ...props }) => (
  <td style={{ cursor: "default" }} {...props}>
    <GothamRegular style={{ fontSize: 14, marginBottom: 0 }}>
      {children}
    </GothamRegular>
  </td>
);

export const TableRowDots = ({
  children,
  data = [],
  isLast,
  onClick,
  style,
  counter,
  className,
  trOnClick = () => {},
  woTd,
  ...props
}) => {
  const ref = useRef(null);
  const [hover, setHover] = useState(false);
  const { isOpen, toggle, close } = useModalHook();
  const { t } = useTranslation("common");

  useClickOutside({
    ref,
    clickOutside: close,
  });

  const noData = Boolean(!data.length);

  const trProps = { counter, style, className, ...props };

  if (noData)
    return (
      <TransitionTr {...trProps} onClick={() => trOnClick && trOnClick()}>
        {children}
        {!woTd && (
          <td
            style={{
              position: "relative",
              border: "none",
              cursor: "default",
            }}
          />
        )}
      </TransitionTr>
    );
  return (
    <TransitionTr {...trProps} onClick={() => trOnClick && trOnClick()}>
      {children}
      <td
        align="center"
        valign="center"
        style={{
          position: "relative",
          border: "none",
        }}
        ref={ref}
      >
        <img
          src={isOpen || hover ? ThreeDotsClicked : ThreeDots}
          onClick={(e) => {
            if (!e) return;
            e.preventDefault();
            e.stopPropagation();
            toggle();
          }}
          on
          className="threedot"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />

        {isOpen && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "white",
              right: 10,
              top: isLast ? -25 : 45,
              border: "1px solid #EAEAEA",
              borderRadius: 8,
              minWidth: 161,
              boxShadow:
                "0px 3px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12)",
              width: "auto",
              zIndex: 2,
            }}
          >
            {data.map((item, index) => {
              const { label, icon, style, fillIcon } = item;
              return (
                <div
                  style={{
                    display: "flex",
                    padding: 10,
                    alignItems: "center",
                    width: "auto",
                    height: 40,
                  }}
                  className="darkhover"
                  onClick={(e) => {
                    if (!e) return;
                    e.preventDefault();
                    e.stopPropagation();
                    onClick(item);
                    toggle();
                  }}
                >
                  {icon ? (
                    <JackIcons
                      name={icon}
                      fill={fillIcon}
                      style={{ marginRight: 16, width: 20, height: "auto" }}
                    />
                  ) : null}
                  <GothamRegular
                    key={index}
                    style={{
                      color: "#343434",
                      fontSize: 14,
                      marginBottom: -2,
                      ...style,
                    }}
                  >
                    {t(label)}
                  </GothamRegular>
                </div>
              );
            })}
          </div>
        )}
      </td>
    </TransitionTr>
  );
};
