import axios from "axios";
import {
  downloadApiChild,
  DownloadApiParams,
  handleDownloadRawChild,
  useDownloadChild,
} from "./functions/hooks/download";
import { FetchParams, fetchRaw } from "./functions/hooks/fetch";
import { UseMutationParams, useMutationRaw } from "./functions/hooks/mutation";
import errorInterceptor from "./functions/interceptors/error";
import defaultHeader from "./functions/interceptors/header";
import { responseInterceptor } from "./functions/interceptors/response";
import {
  mainDownloadURL,
  mainURL,
  urlDeciderChild,
} from "./functions/interceptors/url";

export const urlDecider = urlDeciderChild;

const createObject = (baseURL: string, timeout = 30000) => ({
  baseURL,
  timeout,
  CancelToken: axios.CancelToken,
});

export const errorApiDecider = (error: any | string) => {
  const stringErr = String(error || "");
  const isNoInternet = stringErr.includes("Network Error");
  const isServerError = stringErr.includes("500");
  const invalidData = stringErr.includes("422");
  const isUnauthorized = stringErr.includes("401") || stringErr.includes("404");

  return { isNoInternet, isServerError, invalidData, isUnauthorized };
};

export const apiBusiness = axios.create(createObject(mainURL));

apiBusiness.interceptors.request.use(defaultHeader);

apiBusiness.interceptors.response.use(responseInterceptor, errorInterceptor);

export const apiBusinessV2 = axios.create(createObject(mainURL));

apiBusinessV2.interceptors.request.use((config) => defaultHeader(config, true));
apiBusinessV2.interceptors.response.use(responseInterceptor, errorInterceptor);

export const apiBusinessDownload = axios.create(createObject(mainDownloadURL));

apiBusinessDownload.interceptors.request.use(defaultHeader);
apiBusinessDownload.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
);

export const apiBusinessWoHeaderDownload = axios.create(
  createObject(mainDownloadURL)
);

apiBusinessWoHeaderDownload.interceptors.request.use(async (config) => {
  config.baseURL = urlDecider(config.baseURL || "");
  return config;
});
apiBusinessWoHeaderDownload.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
);

export const apiBusinessLong = axios.create(createObject(mainURL));

apiBusinessLong.interceptors.request.use(defaultHeader);
apiBusinessLong.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
);

const strapi = axios.create(createObject("https://strapi.transfez.com"));

const apis = {
  strapi,
  apiBusinessLong,
  apiBusinessV2,
  apiBusiness,
};

export const fetch = <ResultVals>(res: FetchParams<ResultVals>) =>
  fetchRaw<ResultVals>({ ...res, ...apis });

export const useFetch = fetch;

export const useMutation = <
  Payload extends Record<string, any> = Record<string, any>,
  Result = any
>(
  res: UseMutationParams<Result>
) => useMutationRaw<Payload, Result>({ ...res, ...apis });

export const handleDownloadRaw = handleDownloadRawChild;

const apisDownload = {
  apiBusinessWoHeaderDownload,
  apiBusinessDownload,
  apiBusinessLong,
  apiBusiness,
};

export const downloadApi = async (res: DownloadApiParams) =>
  await downloadApiChild({ ...res, ...apisDownload });

export const useDownload = () => useDownloadChild(apisDownload);
