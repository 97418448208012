import { useEffect, useRef, useState } from "react";
import { NakedSearchInput } from "../../../../../../../components/inputs";
import { GothamMedium } from "../../../../../../../components/Text";
import { colors } from "../../../../../../../assets/colors";
import { fetch } from "../../../../../../../tools/api";
import { defaultPaginationFormatter } from "../../../../../../needActionComponents/pages/table/logics/formatter";
import { useGetAuth } from "../../../../../../../contexts/AuthContext";
import {
  canRefetchDecider,
  useDebounce,
} from "../../../../../../../components/tools";
import { EWalletFilterModalContentStatus } from "./status";
import { useRouter } from "next/router";
import { queryArray } from "./parent";

export const SimpleListQuery = ({
  tableHeight,
  name,
  loading,
  array,
  onScroll = () => {},
  tableRef = () => {},
}) => {
  return (
    <div
      style={{ height: tableHeight, overflowY: "scroll" }}
      ref={tableRef}
      onScroll={onScroll}
    >
      <EWalletFilterModalContentStatus
        activeObj={{ array, name, noSplit: true, loading }}
      />
    </div>
  );
};

const List = ({
  tableHeight,
  name,
  title,
  urlFunc = () => {},
  setHasData,
  text,
}) => {
  const [page, setPage] = useState(1);

  const { push, query } = useRouter();
  const url = `${urlFunc(page)}&q[name_cont]=${text}`;

  const { data, refetch, loading, setData } = fetch({
    url,
    formatter: defaultPaginationFormatter,
    defaultValue: {},
    woInit: true,
  });
  const [isFirstRender, setIsFirstRender] = useState(true);

  const debouncedText = useDebounce(text, 400);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
    setData({});
    if (page == 1) return refetch();
    setPage(1);
  }, [debouncedText]);

  useEffect(refetch, [page]);

  const { array: arrayRaw = [], total_page } = data || {};
  const array = toLabelValue(arrayRaw);
  const ids = array.map(({ value }) => value);

  const ref = useRef();

  useEffect(() => setHasData(array.length), [array.length]);

  const queryValue = queryArray(query, name);

  const isAllSelected =
    ids.filter((id) => queryValue.includes(id)).length == ids.length;

  const handleChange = (ids) => {
    let obj = query;
    obj[name] = ids;
    push({ query: obj });
  };

  const handleSelectAll = () => {
    if (!isAllSelected) return handleChange(ids);

    const newIds = queryValue.filter((id) => !ids.includes(id));
    handleChange(newIds);
  };

  if (!array.length) return null;

  return (
    <div>
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: 12 }}
      >
        <GothamMedium>{title}</GothamMedium>
        {!loading && (
          <GothamMedium
            style={{ color: colors.pink }}
            className="darkhover"
            onClick={handleSelectAll}
          >
            {isAllSelected ? "Unselect All" : "Select All"}
          </GothamMedium>
        )}
      </div>
      <SimpleListQuery
        array={array}
        loading={loading}
        name={name}
        tableHeight={tableHeight}
        onScroll={() => {
          const canRefetch = canRefetchDecider(ref, loading);
          if (!canRefetch) return;
          if (page >= total_page) return;
          setPage((p) => p + 1);
        }}
        tableRef={ref}
      />
    </div>
  );
};

const toLabelValue = (array) =>
  array.map(({ id, name }) => ({ value: String(id), label: name }));

export const EWalletFilterModalContentCard = ({ activeObj: { name } }) => {
  const [text, setText] = useState("");

  const [myCard, setMyCard] = useState(false);
  const [employeeCard, setEmployeeCard] = useState(false);

  const {
    user: { id },
  } = useGetAuth();

  const twoSections = myCard && employeeCard;

  const tableHeight = twoSections ? 126 : 300;

  const props = { tableHeight, name, text };

  return (
    <div className="h-100">
      <div>
        <NakedSearchInput
          onChange={(text) => setText(text)}
          styleInput={{ marginBottom: 16 }}
          placeholder="Search cardholder"
        />
      </div>
      <List
        {...props}
        title="Your Card"
        setHasData={setMyCard}
        urlFunc={(page) => `/cards?page=${page}&q[user_id_eq]=${id}`}
      />
      <List
        {...props}
        title="Employee Card"
        setHasData={setEmployeeCard}
        urlFunc={(page) => `/cards?page=${page}&q[user_id_not_eq]=${id}`}
      />
    </div>
  );
};
