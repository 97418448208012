import { get } from "lodash";
import { useRouter } from "next/router";
import styled from "styled-components";
import { colors } from "../assets/colors";
import copyIcon from "../assets/images/copy-icon.svg";
import { GothamMedium, GothamRegular } from "../components/Text";
import { TopTabs } from "../components/TopTabs";

export const BackNavigation = ({ onClick }) => {
  const router = useRouter();
  return (
    <button
      className="fake-button d-flex align-items-center hover px-0"
      style={{ marginBottom: 8 }}
      onClick={() => {
        if (typeof onClick == "function") return onClick();
        router.back();
      }}
    >
      <img src={"/images/left-arrow-back.svg"} />
      <GothamMedium
        style={{
          color: colors.pink,
          marginLeft: 8,
          fontSize: 12,
          marginBottom: 0,
          lineHeight: "18px",
        }}
      >
        Back
      </GothamMedium>
    </button>
  );
};

export const ActivityBackNavigator = ({ type }) => {
  const { push } = useRouter();
  return (
    <BackNavigation
      onClick={() => {
        push({ pathname: "/history/", query: { pageType: type } });
      }}
    />
  );
};

export const AccountDetailsListRekening = ({ data }) => {
  const getBankName = get(data, "fullBankName", "");
  const getAccName = get(data, "accountName", "");
  const getAccNumber = get(data, "uniquePaymentId", "");
  const getAddress = get(data, "bankAddress", "");
  const dataDetails = [
    {
      label: "Bank Name",
      value: `${getBankName}`,
    },
    {
      label: "Account Holder Name",
      value: `${getAccName}`,
    },
    {
      label: "Account Number",
      value: `${getAccNumber}`,
    },
    {
      label: "Address",
      value: `${getAddress}`,
    },
    // {
    //   label: "Bank Country",
    //   value: "Indonesia",
    // },
  ];
  return (
    <>
      <div style={{ padding: 16 }}>
        {dataDetails.map(({ label, value }, index) => {
          return (
            <div key={index}>
              <GothamRegular style={{ color: colors.grey72, fontSize: 12 }}>
                {label}
              </GothamRegular>
              <GothamMedium
                style={{
                  fontSize: 16,
                  marginBottom: 16,
                }}
              >
                {value}
                <img
                  src={copyIcon}
                  style={{ width: 16, marginLeft: 8 }}
                  className="darkhover"
                  onClick={(e) => {
                    navigator.clipboard.writeText(value);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </GothamMedium>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const AccountDetailsList = ({ data, isUsd }) => {
  return (
    <div
      style={{
        boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.06)",
        borderRadius: 8,
        backgroundColor: "white",
        paddingTop: 2,
      }}
    >
      {isUsd ? (
        <TopTabs
          data={[
            {
              screenName: "For US",
              screen: <AccountDetailsListRekening data={data[1]} />,
            },
            {
              screenName: "For Singapore",
              screen: <AccountDetailsListRekening data={data[0]} />,
            },
          ]}
        />
      ) : (
        <AccountDetailsListRekening data={data[0]} />
      )}
    </div>
  );
};

const AccountCardDiv = styled.div`
  padding: 24px !important;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  background-color: white !important;
  margin-bottom: 24px;
  margin-top: 24px;
  cursor: ${(p) => p.cursor || "pointer"};
`;

const AccountActionDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 8px;
  box-shadow: ${(p) => p.bshadow || "0px 8px 20px rgba(88, 88, 88, 0.1)"};
  border-radius: 12px;
  background-color: white !important;
  border: ${(p) => (p.border ? "1px solid #eaeaea" : "")};
`;

const AccountSubActionDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding: 5px 5px;
  min-width: ${(p) => p.minWidth || "85px"};
  max-width: ${(p) => p.maxWidth || "85px"};
  cursor: pointer;
`;

const AccountActionImg = styled.img`
  width: 28px;
  height: 28px;
  margin-bottom: 8px;
  filter: ${(p) => p.filter};
`;

const ThreeDotsImg = styled.img`
  transform: rotate(90deg);
  width: 30px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 35px;
  z-index: 5;
  :hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    transform: rotate(90deg);
  }
`;

const AccountDetailsTransDiv = styled.div`
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  flex-wrap: nowrap;
  margin-bottom: 16px;
`;
