import { useState } from "react";
import { colors } from "../../../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../../../components/Text";
import { EWalletFilterModalContent } from "./content/parent";

export const EWalletFilterModal = ({ isOpen, style, array = [], toggle }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  if (!isOpen) return null;

  const activeObj = { ...array[activeIndex], toggle };

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        width: 506,
        height: 424,
        top: 46,
        boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.15)",
        borderRadius: 12,
        zIndex: 100,
        ...style,
      }}
      className="fade-in d-flex"
    >
      <div
        style={{
          width: 170,
          height: "100%",
          backgroundColor: "rgb(252, 252, 252)",
          padding: "16px 12px 16px 12px",
          borderBottomLeftRadius: 12,
          borderTopLeftRadius: 12,
        }}
      >
        {array.map((item, index) => {
          const { label } = item;
          const isActive = activeIndex === index;
          if (!item) return null;
          return (
            <div
              key={index}
              className="darkhover mb-1"
              style={{
                padding: "4px 8px 4px 8px",
                backgroundColor: isActive ? colors.lightPink : "",
              }}
              onClick={() => setActiveIndex(index)}
            >
              {isActive ? (
                <GothamMedium style={{ color: colors.pink }}>
                  {label}
                </GothamMedium>
              ) : (
                <GothamRegular>{label}</GothamRegular>
              )}
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: "calc(100% - 170px)",
          padding: 12,
          backgroundColor: "white",
          position: "relative",
          borderBottomRightRadius: 12,
          borderTopRightRadius: 12,
        }}
      >
        {/* what you actually care about */}
        <EWalletFilterModalContent activeObj={activeObj} />
        {/* what you actually care about */}
      </div>
    </div>
  );
};
