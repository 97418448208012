import { AES, enc } from "crypto-js";

type Payload = Record<string, any>;

export const SecretKeyPayload = process.env.NEXT_PUBLIC_ENCRYPT_PAYLOAD;

const base64UrlSafe = (base64?: string) => {
  if (!base64) return "";
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

export const encryptPin = (pin: string | number, secretKey: string) => {
  const encrypted = AES.encrypt(`${pin}`, secretKey).toString();
  const base64 = enc.Base64.stringify(enc.Utf8.parse(encrypted));
  return base64UrlSafe(base64);
};

export const encryptPinPayload = (
  payloadRaw: Payload,
  secretKey: string,
  salus: string
) => {
  const { pin, visitorId, new_pin, ...rest } = payloadRaw;

  let payload = pin
    ? { ...rest, pin: encryptPin(pin, secretKey), salus }
    : { ...rest };

  if (new_pin) {
    const encryptedNewPin = encryptPin(new_pin, secretKey);
    payload = { ...payload, new_pin: encryptedNewPin, salus };
  }

  return payload;
};

export const encryptedPasswordFunc = (obj: Payload, visitorId: string) => {
  const keys = Object.keys(obj || {}).filter((key) => key.includes("password"));

  let passwordsObj: Record<string, string> = {};

  const secretKey = `${SecretKeyPayload}${visitorId}`;

  keys.forEach((key) => (passwordsObj[key] = encryptPin(obj[key], secretKey)));

  return { ...obj, ...passwordsObj, is_encrypted: true };
};
