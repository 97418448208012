import { useState } from "react";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import bannerTransferImg from "../../../../assets/jackImages/banner-transfer-service.svg";
import { Modal } from "reactstrap";
import { Hr32 } from "../../../../pageComponents/crossBorder/components";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import CustomTooltip from "components/Tooltip";
import { Trans, useTranslation } from "react-i18next";

export const SpanMedium = ({ style, children }) => {
  return (
    <span style={{ fontFamily: "GothamMedium", ...style }}>{children}</span>
  );
};

export const BackButton = ({ onClick, style }) => {
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  return (
    <div
      onClick={onClick}
      style={{
        borderRadius: 4,
        border: `1px solid ${jackColors.neutral500}`,
        padding: 6,
        gap: 8,
        marginBottom: 24,
        width: "fit-content",
        cursor: "pointer",
        ...style,
      }}
      className="d-flex align-items-center hover-400"
    >
      <JackIcons
        name="arrow-back"
        fill={jackColors.neutral900}
        style={{ width: 20, height: 20 }}
      />
      <GothamMedium>{tLocalTransfer("Back")}</GothamMedium>
    </div>
  );
};

export const BulletPoint = ({
  text,
  isHover,
  onlyOptimizedServices,
  isUnavailable,
}) => {
  return (
    <div
      style={{
        border: `1px solid ${jackColors.neutral500}`,
        borderRadius: 16,
        padding: "4px 8px",
        backgroundColor: isHover
          ? jackColors.neutral200
          : jackColors.neutral400,
        marginRight: 8,
        opacity: isUnavailable && onlyOptimizedServices && 0.6,
      }}
    >
      <GothamMedium className="font10" style={{ color: jackColors.neutral700 }}>
        {text}
      </GothamMedium>
    </div>
  );
};

export const TransferServiceTitle = ({ isPayroll, t = (item) => item }) => {
  // this

  const desc = isPayroll ? (
    <>
      {/* <Trans
        i18nKey={t("dynamic.style_text_6")}
        components={{ SpanMedium: <SpanMedium /> }}
      /> */}
      {t("Choose how your domestic transactions will be processed.")}
      {/* You can only select <SpanMedium>Optimized Route</SpanMedium> by Jack. This
      is to ensure the confidentiality of all payroll details. */}
    </>
  ) : (
    t("Choose how your domestic transactions will be processed.")
  );

  return (
    <div style={{ marginBottom: 32 }}>
      <GothamMedium className="font20 mb-2">
        {t("Transfer Service (Domestic)")}
      </GothamMedium>
      <GothamRegular
        className="font12"
        style={{ color: jackColors.neutral700 }}
      >
        {desc}
      </GothamRegular>
    </div>
  );
};

export const BannerQuotaTransferServices = ({
  onClick,
  t = (item) => item,
}) => {
  return (
    <div style={{ marginBottom: 24, position: "relative" }}>
      <img src={bannerTransferImg} />
      <div
        style={{ position: "absolute", left: "12px", top: "12px" }}
        className="d-flex align-items-center"
      >
        <Trans
          i18nKey={t("multistyle")}
          components={{
            GothamRegular: (
              <GothamRegular className="font12" style={{ color: "white" }} />
            ),
            SpanMedium: (
              <SpanMedium
                style={{ color: "white", fontSize: "12px", marginRight: 2 }}
              />
            ),
          }}
        />

        <JackIcons
          name="question-mark-circle"
          fill="white"
          style={{ width: 16, height: 16, cursor: "pointer" }}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export const BannerOutsideServiceHours = ({ t = (item) => item }) => {
  return (
    <div
      style={{
        backgroundColor: "#FFF6E6",
        borderRadius: 4,
        padding: 4,
        marginTop: 8,
        gap: 4,
      }}
      className="d-flex align-items-start"
    >
      <JackIcons
        name="info"
        fill={jackColors.yellowFF}
        style={{ width: 12, height: 12 }}
      />
      <div>
        <GothamMedium
          style={{ color: jackColors.neutral700 }}
          className="font10"
        >
          {t("Outside of service hours")}
        </GothamMedium>
        <GothamRegular
          style={{ color: jackColors.neutral700 }}
          className="font10"
        >
          {t(
            "You can select this, but transfer will be processed on the next working days."
          )}
        </GothamRegular>
      </div>
    </div>
  );
};

export const MainCardTransferService = (props) => {
  const {
    isPayroll,
    isVirtualAccount,
    array,
    setActive,
    activeTransferService,
    tempActiveService,
    t,
  } = props || {};

  const onlyOptimizedServices = isPayroll || isVirtualAccount;

  const arrayOptimized = array?.filter(({ isUnavailable }) => !isUnavailable);
  const arrayAllServices = array?.filter(({ isUnavailable }) => isUnavailable);

  if (onlyOptimizedServices) {
    return (
      <>
        <CardsTransferService {...props} array={arrayOptimized} />
        <CardsTransferService
          {...props}
          array={arrayAllServices}
          styleDiv={{
            marginTop: 16,
            border: `1px solid ${jackColors.neutral500}`,
            borderRadius: 12,
            padding: 8,
          }}
          customTitle={
            isPayroll ? (
              <PayrollServicesInfo t={t} />
            ) : isVirtualAccount ? (
              <VirtualAccountServicesInfo t={t} />
            ) : (
              false
            )
          }
        />
      </>
    );
  }
  return <CardsTransferService {...props} />;
};

export const CardsTransferService = ({
  isPayroll,
  isVirtualAccount,
  array,
  setActive,
  activeTransferService,
  tempActiveService,
  customTitle = false,
  styleDiv,
  t = (item) => item,
}) => {
  const [hoverValue, setHoverValue] = useState(false);

  return (
    <div style={{ ...styleDiv }}>
      {customTitle && customTitle}
      {array?.map((item, index) => {
        const {
          icon,
          key,
          value,
          title,
          description,
          transferFee,
          transferTime,
          transferMaxAmount,
          isUnavailable,
          unavailableText: unavailableTextRaw,
          outsideServiceHours,
        } = item || {};

        const isLast = index === array.length - 1;
        const isAvailable = !isUnavailable;

        const isChoosen = activeTransferService?.value === value;
        const isHover = isAvailable && hoverValue === value;
        const isActive = isChoosen;
        const isOptimized = value == "optimized_route";

        const onlyOptimizedServices = isPayroll || isVirtualAccount;

        const propsBullet = {
          isHover,
          onlyOptimizedServices,
          isUnavailable,
        };

        const unavailableText = () => (
          <div className="d-flex align-items-center">
            <JackIcons
              name="info"
              fill={jackColors.neutral800}
              style={{ width: 12, height: 12, marginRight: 4 }}
            />
            <SpanMedium style={{ marginRight: 1 }}>
              {t("Unavailable: ")}
            </SpanMedium>
            <span style={{ fontFamily: "GothamBook" }}>
              {unavailableTextRaw}
            </span>
          </div>
        );

        return (
          <div key={key} style={{ marginBottom: isLast ? 0 : 16 }}>
            <div
              style={{
                padding: 12,
                border: `1px solid ${
                  isActive
                    ? jackColors.neutral900
                    : isUnavailable
                    ? "rgba(230, 230, 232, 0.6)"
                    : jackColors.neutral500
                }`,
                borderRadius: 12,
                transition: "ease-in-out 100ms",
                cursor: isUnavailable ? "not-allowed" : "pointer",
                position: "relative",
                zIndex: 0,
                backgroundColor: isHover
                  ? jackColors.neutral400
                  : jackColors.neutral200,
              }}
              onMouseEnter={() => setHoverValue(value)}
              onMouseLeave={() => setHoverValue(false)}
              onClick={() => isAvailable && setActive(item)}
              className="d-flex align-items-center justify-content-between"
            >
              <div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    marginTop: onlyOptimizedServices && !isOptimized ? 36 : 60,
                  }}
                >
                  {onlyOptimizedServices && !isOptimized ? (
                    <></>
                  ) : isUnavailable && !onlyOptimizedServices ? (
                    <BulletPoint text={unavailableText()} {...propsBullet} />
                  ) : (
                    <>
                      <BulletPoint text={transferTime} {...propsBullet} />
                      <BulletPoint text={transferMaxAmount} {...propsBullet} />
                    </>
                  )}
                </div>
                {isAvailable && outsideServiceHours && (
                  <BannerOutsideServiceHours t={t} />
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  padding: 12,
                  borderBottom: `1px solid ${
                    isUnavailable
                      ? "rgba(230, 230, 232, 0.6)"
                      : jackColors.neutral500
                  }`,
                  borderRadius: 12,
                  width: "100%",
                  opacity: isUnavailable ? "60%" : "100%",
                  backgroundColor:
                    isUnavailable || isHover ? jackColors.neutral400 : "white",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div className="d-flex align-items-center mb-2">
                      <GothamMedium
                        className="font12"
                        style={{
                          color: isUnavailable
                            ? jackColors.neutral700
                            : jackColors.neutral900,
                        }}
                      >
                        {title}
                      </GothamMedium>
                      <div
                        style={{
                          opacity: isUnavailable && 0.6,
                          filter: isUnavailable && "grayscale(1)",
                        }}
                      >
                        {transferFee}
                      </div>
                    </div>
                    <div className="d-flex">
                      <GothamRegular
                        className="font10"
                        style={{ color: jackColors.neutral700 }}
                      >
                        {description}
                      </GothamRegular>
                    </div>
                  </div>
                  <div>
                    {isAvailable && isChoosen && (
                      <JackIcons
                        name={"checkmark-circle-2"}
                        fill={
                          isActive
                            ? jackColors.neutral900
                            : jackColors.neutral500
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PayrollServicesInfo = ({ t = (item) => item }) => {
  return (
    <div
      className="d-flex align-items-start"
      style={{ gap: 4, marginBottom: 16 }}
    >
      <JackIcons
        name="info"
        fill={jackColors.yellowFF}
        style={{ width: 16, height: 16 }}
      />
      <div>
        <GothamRegular
          className="font12"
          style={{ color: jackColors.neutral700 }}
        >
          <Trans
            i18nKey={t("dynamic.style_text_7")}
            components={{ SpanMedium: <SpanMedium /> }}
          />{" "}
          {/* <SpanMedium>To secure data for Payroll transaction(s)</SpanMedium>,
          these services are unavailable.{" "} */}
          <CustomTooltip
            text={t(
              "We aim to keep individual payroll data private by only showing the total amount. The services below do not support this."
            )}
            // popperClassName="white-arrow"
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              width: 250,
              textAlign: "left",
              backgroundColor: jackColors.neutral900,
              padding: 8,
            }}
          >
            <SpanMedium
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {t("Learn more")}
            </SpanMedium>
          </CustomTooltip>
        </GothamRegular>
      </div>
    </div>
  );
};

const VirtualAccountServicesInfo = ({ t = (item) => item }) => {
  return (
    <div
      className="d-flex align-items-start"
      style={{ gap: 4, marginBottom: 16 }}
    >
      <JackIcons
        name="info"
        fill={jackColors.yellowFF}
        style={{ width: 16, height: 16 }}
      />
      <div>
        <GothamRegular
          className="font12"
          style={{ color: jackColors.neutral700 }}
        >
          {/* this */}
          <Trans
            i18nKey={t("dynamic.style_text_5")}
            components={{
              SpanMedium: <SpanMedium />,
            }}
          />
          {/* <SpanMedium>
            Because you’re releasing Virtual Account transaction(s)
          </SpanMedium>
          , these services are unavailable. */}
        </GothamRegular>
      </div>
    </div>
  );
};

export const ModalInfoQuota = ({ isOpen, toggle, propsLimitQuota }) => {
  const { used, remaining, outOfQuota, partialFree } = propsLimitQuota || {};
  const { t } = useTranslation("local-transfer/local-transfer");

  const title = t("Exclusive Free Transfer");
  const description = (
    <>
      <Trans
        i18nKey={t("dynamic.style_text_8")}
        components={{ SpanMedium: <SpanMedium /> }}
      />
      {/* Enjoy free transfers on <SpanMedium>BI Fast & Optimized Route</SpanMedium>
      . Lower fees apply for others. */}
    </>
  );

  const arrayTable = [
    {
      title: t("Free Transfer Quota"),
      desc: t("Resets every 1st day of each month."),
      isHeader: true,
    },
    {
      title: t("Remaining"),
      desc: `${remaining ?? 0} ${t("transactions")}`,
      colorDesc: outOfQuota
        ? jackColors.redE7
        : partialFree
        ? jackColors.orangeF8
        : jackColors.green06,
    },
    {
      title: t("Used"),
      desc: `${used ?? 0} ${t("transactions")}`,
    },
  ];

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      contentClassName={`modal-transfez modal-border-radius`}
    >
      <div style={{ padding: 20, width: 440 }}>
        <div
          className="d-flex justify-content-between align-items-start"
          style={{ marginBottom: 16 }}
        >
          <div>
            <GothamMedium className="font16 mb-2">{title}</GothamMedium>
            <GothamRegular style={{ color: jackColors.neutral700 }}>
              {description}
            </GothamRegular>
          </div>
          <div
            style={{
              padding: 10,
              border: `1px solid ${jackColors.neutral400}`,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={toggle}
            className="hover-400"
          >
            <JackIcons
              name="close-outline"
              fill={jackColors.neutral900}
              style={{ width: 20, height: 20 }}
            />
          </div>
        </div>
        <Hr32 style={{ margin: "0px -20px 20px -20px" }} />
        <div
          style={{
            border: `1px solid ${jackColors.neutral500}`,
            borderRadius: 4,
            overflow: "hidden",
          }}
        >
          {arrayTable?.map((item, index) => {
            const { title, desc, colorDesc, isHeader } = item || {};
            const isFirst = index == 0;
            return (
              <div
                key={index}
                className={`${
                  !isHeader ? "d-flex" : ""
                } justify-content-between align-items-center`}
                style={{
                  backgroundColor: isHeader ? jackColors.neutral400 : "white",
                  padding: isHeader ? 12 : 16,
                  borderTop: !isFirst && `1px solid ${jackColors.neutral500}`,
                }}
              >
                <GothamMedium
                  style={{ color: jackColors.neutral800 }}
                  className="font12"
                >
                  {title}
                </GothamMedium>
                <GothamRegular
                  className="font12"
                  style={{
                    marginTop: isHeader ? 4 : 0,
                    fontFamily: isHeader ? "GothamBook" : "GothamMedium",
                    color: colorDesc ?? jackColors.neutral700,
                  }}
                >
                  {desc}
                </GothamRegular>
              </div>
            );
          })}
        </div>
        <Hr32 style={{ margin: "20px -20px 20px -20px" }} />
        <ButtonJack className="w-100" onClick={toggle}>
          {t("Close")}
        </ButtonJack>
      </div>
    </Modal>
  );
};
