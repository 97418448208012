import { FaSpinner } from "react-icons/fa";
import { colors } from "../assets/colors";
import { GothamMedium, GothamRegular } from "./Text";
import xIcon from "../assets/images/x-icon-grey.svg";
import checkSquarePinkIcon from "../assets/images/check-square-pink.svg";
import uploadSquarePinkIcon from "../assets/images/upload-images-square-icon.svg";
import { toKBString, toMBString } from "./inputs";
import { upperCase } from "lodash";
import { useTranslation } from "react-i18next";

export const FileInputCustomizeReceipt = ({
  name,
  onDragEnter,
  onDrop,
  onDragLeave,
  onDragOver,
  loading = false,
  onChange,
  value,
  onRemoveFile,
  maxSize,
  supportedFiles,
  ...style
}) => {
  const {
    label: labelValue,
    name: nameValue,
    size: sizeValue,
    realFile,
  } = value || {};
  const { size: sizeRealFile } = realFile || {};
  const { t } = useTranslation("reimbursement/reimbursement");
  const fileName = labelValue || nameValue;
  const size = sizeValue || sizeRealFile;
  const fileSize = size > 1000000 ? toMBString(size) : toKBString(size);
  const hasValue = Boolean(labelValue) || Boolean(nameValue);

  //   Supported Files
  const upperSupportedFiles = supportedFiles?.map((item) => upperCase(item));
  const lastFileType = upperSupportedFiles.pop();
  const supportedFilesString =
    upperSupportedFiles.join(", ") + " or " + lastFileType;
  //   Supported Files

  return (
    <div>
      <label
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        className="btn-fileinput text-center hover"
        style={{
          width: "100%",
          backgroundColor: "#FDD7F540",
          border: "3px dashed #DA649F",
          padding: "30px 12px",
          height: 154,
          position: "relative",
          ...style,
        }}
      >
        {loading && (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaSpinner className="icon-spin mr-2" />
            <GothamRegular>Uploading..</GothamRegular>
          </div>
        )}
        {hasValue ? (
          <div>
            <img
              src={xIcon}
              className="ml-2 darkhover"
              style={{
                zIndex: 1,
                position: "absolute",
                right: 10,
                top: 10,
                width: 24,
              }}
              onClick={(e) => {
                if (e) {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemoveFile();
                }
              }}
            />
            <img src={checkSquarePinkIcon} style={{ marginBottom: 12 }} />
            <GothamMedium style={{ color: colors.grey33 }}>
              {fileName}
            </GothamMedium>
            <GothamRegular style={{ color: colors.grey6c }}>
              {fileSize}
            </GothamRegular>
          </div>
        ) : (
          <div>
            <img src={uploadSquarePinkIcon} style={{ marginBottom: 12 }} />
            <GothamMedium style={{ color: colors.grey33 }}>
              {t("Browse or drag files here to upload")}
            </GothamMedium>
            <GothamRegular
              style={{ fontSize: 10, color: colors.grey6c, lineHeight: "14px" }}
            >
              {t("Max. size for each file:")} {toMBString(maxSize)}
            </GothamRegular>
            <GothamRegular
              style={{ fontSize: 10, color: colors.grey6c, lineHeight: "14px" }}
            >
              {supportedFilesString}
            </GothamRegular>
          </div>
        )}
        <input
          name="testing"
          type="file"
          style={{ display: "none" }}
          onClick={(e) => {
            e.target.value = null;
          }}
          onChange={onChange}
        />
      </label>
    </div>
  );
};
