import { useForm } from "@tools";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { CustomButton } from "../../../components/Buttons";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { RightCardModalAtomic } from "../../../components/CardModal";
import {
  CustomTextField,
  FileInputDottedThumbnail,
  SelectionForm,
} from "../../../components/inputs";
import { AtomicTextFieldJack } from "../../../components/inputs/textfield";
import {
  RightModal,
  useSmoothRightModal,
} from "../../../components/Modals/RightModal/parent";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import {
  applyRegexOnlyNumbers,
  formatCurrencyNoDecimal,
  FullDateFormatter,
  schemaGenerator,
  toFormData,
  unformatCurrencyDecimal,
} from "../../../components/tools";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { ActivityDetailsHeader } from "../../../modals/smartActivityModal/activityModalComponents";
import { useMutation } from "../../../tools/api";
import {
  CardDetailsBank,
  CountDownTopUpModal,
  ProcedureInstruction,
  WrapperModalButtons,
} from "./components";
import { expiresFormatter, useGetVaNumber } from "./logic";

export const TopUpManualModal = ({ isOpen, toggle, setAfterSuccessManual }) => {
  const resolver = schemaGenerator({
    objectArr: ["bank_name", "upload_file"],
    stringArr: [
      {
        name: "account_name",
        yup: yup.string().required().max(50, "Maximum 50 characters allowed"),
      },
      {
        name: "account_number",
        yup: yup.string().required().max(20, "Maximum 20 characters allowed"),
      },
    ],
  });

  const { localTransferBanks } = useConstants();
  const useFormObj = useForm({ resolver });
  const { watch, handleSubmit, setValue, reset } = useFormObj;
  const watchAccountNumber = watch("account_number");

  const handleToggle = () => {
    toggle();
    reset();
  };

  const afterSuccess = (val) => {
    const { data } = val?.data || {};
    const { id } = data || {};
    const newVal = {
      ...data,
      isSuccessModal: true,
      category: "topup_request",
      originator_id: id,
    };
    setAfterSuccessManual(newVal);
    reset();
  };

  const { mutation, loading } = useMutation({
    method: "post",
    url: "/topups",
    afterSuccess,
  });

  const onSubmit = (val) => {
    const { upload_file, bank_name, ...rest } = val || {};
    const data = {
      files: upload_file?.file,
      bank_name: bank_name?.label,
      ...rest,
    };
    const result = toFormData(data);
    mutation(result);
  };

  useEffect(() => {
    const newValue = applyRegexOnlyNumbers(watchAccountNumber || "");
    setValue("account_number", newValue);
  }, [watchAccountNumber]);

  const headerData = {
    title: (
      <div style={{ display: "relative", height: 80 }}>
        <GothamMedium style={{ fontSize: 24, marginBottom: 12 }}>
          Submit Proof of Payment
        </GothamMedium>
        <GothamRegular style={{ color: "white" }}>
          Please provide your source of payment and proof of payment for
          verification purposes.
        </GothamRegular>
      </div>
    ),
    icon: "",
  };

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      header={
        <>
          <ActivityDetailsHeader
            toggle={handleToggle}
            data={headerData}
            isClose={false}
          />
        </>
      }
      middleHeight={10}
      body={
        <div>
          <SelectionForm
            label="Bank Name"
            name="bank_name"
            useFormObj={useFormObj}
            options={localTransferBanks}
            valueListener
          />
          <CustomTextField
            name="account_number"
            label="Bank Account Number"
            useFormObj={useFormObj}
          />
          <CustomTextField
            name="account_name"
            label="Bank Account Name"
            useFormObj={useFormObj}
          />
          <FileInputDottedThumbnail
            useFormObj={useFormObj}
            fileTypes={["application/pdf", "image/jpeg"]}
            name="upload_file"
            maxSize={10000000}
            multiple
          />
          <CustomButton
            style={{ height: 40, width: "100%", marginTop: 12 }}
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
          >
            Submit Proof of Payment
          </CustomButton>
        </div>
      }
    />
  );
};

export const InputAmountModal = ({
  isOpen,
  toggle,
  setAfterSuccess,
  selectedBank = "bca",
}) => {
  const [amount, setAmount] = useState();
  const [amountComma, setAmountComma] = useState();
  const [isError, setIsError] = useState(false);
  const { toToggle, toListener } = useSmoothRightModal();

  const { t } = useTranslation("topup");

  const { t: tReimbursement } = useTranslation("reimbursement/reimbursement");
  const cleanAmount = Number(unformatCurrencyDecimal(amount));
  const isBelowMinimum = cleanAmount < 10000;
  const errorMsg = tReimbursement("Minimum amount: IDR 10,000");

  const afterSuccess = (val) => {
    setAfterSuccess(val?.data);
    toToggle();
  };

  const isMandiriVa = selectedBank === "mandiri";

  const { mutation, loading } = useMutation({
    method: "post",
    url: isMandiriVa ? "/collection_virtual_accounts" : "/topups",
    afterSuccess,
  });

  const handleSubmit = () => {
    if (isMandiriVa) {
      const resultMandiri = {
        amount: cleanAmount,
        bank: "Bank Mandiri",
        code: "008",
        usage_limit: 1,
        is_topup: true,
      };

      return mutation(resultMandiri);
    }

    const result = {
      amount: cleanAmount,
      inhouse_bank_code: selectedBank ? selectedBank : "bca",
    };

    mutation(result);
  };

  useEffect(() => {
    if (isBelowMinimum) return setIsError(true);
    setIsError(false);
  }, [cleanAmount]);

  useEffect(() => {
    setAmountComma(formatCurrencyNoDecimal(amount));
  }, [amount]);

  const isDisabled = isBelowMinimum || loading;

  const [ref, setRef] = useState();

  useEffect(() => {
    if (ref) {
      setTimeout(() => {
        ref?.focus();
      }, 600);
    }
    setAmount("10,000");
  }, [ref]);

  const components = [
    <WrapperModalButtons
      childrenButton={
        <>
          <ButtonJack
            type="outline"
            children={t("Back")}
            onClick={toToggle}
            style={{ width: "100%" }}
            leftIcon={<JackIcons name="arrow-back-outline" fill="black" />}
          />
          <ButtonJack
            isLoading={loading}
            disabled={isDisabled}
            type="filled"
            children={t("Continue")}
            onClick={handleSubmit}
            style={{ width: "100%", marginRight: 16 }}
            rightIcon={
              <JackIcons
                name="arrow-forward"
                fill={isDisabled ? jackColors.greyBB : "black"}
              />
            }
          />
        </>
      }
    >
      <GothamMedium className="font24" style={{ marginBottom: 32 }}>
        {t("How much do you want to top up?")}
      </GothamMedium>
      <GothamRegular
        className="font12"
        style={{ marginBottom: 8, fontWeight: "bold" }}
      >
        {t("Input amount")}
      </GothamRegular>
      <AtomicTextFieldJack
        inputRef={(e) => {
          setRef(e);
          ref;
        }}
        noBorder
        woLabel
        name="amount"
        defaultValue="10,000"
        value={amountComma}
        onChange={(e) => setAmount(e?.target?.value)}
        icon={<GothamRegular>IDR</GothamRegular>}
        style={{
          border: `1px solid ${jackColors.neutral500}`,
          padding: "12px 8px 14px 8px",
        }}
        isError={isError}
        helperText={isError && errorMsg}
        textFieldStyle={{
          border: "none",
          borderBottom: `1px solid ${jackColors.neutral700}`,
          borderRadius: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
          paddingTop: "0px",
          paddingBottom: "0px",
          height: "fit-content",
        }}
      />
    </WrapperModalButtons>,
  ];

  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      array={components}
      listener={toListener}
      width={[420]}
    />
  );
};

export const TopUpPaymentInstructionModal = ({
  data: dataFetch,
  isOpen,
  toggle,
  paid,
  onClickPaid,
  setAfterSuccessManual,
  isFromTrxPage = false,
  title = "",
}) => {
  if (!isOpen) return null;
  const isFetchedFromVa = !!dataFetch?.bank;
  const { details, data } = dataFetch || {};
  const {
    b2b_expiry_date,
    expiration_date: vaMandiriExpiration,
    bank: vaMandiriBank,
    id: vaMandiriId,
  } = isFromTrxPage && isFetchedFromVa ? dataFetch : data || {};
  const isMandiri = vaMandiriBank === "Bank Mandiri";

  const { expires_in, created_at, amount_with_code, amount, unique_code } =
    details || {};

  const { vaData, refetchVaData } = useGetVaNumber({
    id: vaMandiriId,
    canRun: isMandiri,
  });
  const vaNumber = vaData?.number;

  useEffect(() => {
    if (!isMandiri) return;
    if (isFromTrxPage) return;

    // below interval is for when va number is still null from hub
    // it will refetch every 1s until va number appears or after 10s
    // user will see skeleton until it appears
    let refetchInterval;

    const clearAndRefetch = () => {
      // clearInterval(refetchInterval);
      refetchVaData();
    };

    if (isMandiri && !vaNumber) {
      refetchInterval = setInterval(clearAndRefetch, 1000);
    }

    const timeoutId = setTimeout(() => {
      if (!vaNumber && refetchInterval) {
        clearInterval(refetchInterval);
      }
    }, 10000);

    return () => {
      if (refetchInterval) {
        clearInterval(refetchInterval);
      }
      clearTimeout(timeoutId);
    };
  }, [isMandiri, vaNumber]);

  const expiredIn = FullDateFormatter(
    (isMandiri ? vaMandiriExpiration : b2b_expiry_date) || "",
    t
  );

  const dataForCountDown = {
    amount_with_code,
    unique_code,
    expiredIn,
    amount,
  };

  const time = expiresFormatter(
    (isMandiri ? vaMandiriExpiration : b2b_expiry_date) || ""
  );
  const { t } = useTranslation("topup");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const ButtonPaid = paid ? (
    <ButtonJack
      children="I have paid"
      onClick={onClickPaid}
      style={{ width: "100%" }}
    />
  ) : null;

  const childButtonDecider = isMandiri ? (
    <ButtonJack
      type="outline"
      children={t("Back")}
      onClick={toggle}
      style={{ width: "100%" }}
    />
  ) : (
    ButtonPaid
  );

  const components = [
    <WrapperModalButtons childrenButton={childButtonDecider}>
      <GothamMedium className="font24" style={{ marginBottom: 32 }}>
        {title || tLocalTransfer("Payment instruction")}
      </GothamMedium>
      <CountDownTopUpModal
        time={time}
        data={dataForCountDown}
        style={{ marginBottom: 32 }}
        noInfoRates
      />
      <div>
        <CardDetailsBank
          data={dataForCountDown}
          vaData={vaData}
          va={isMandiri}
        />
        <ProcedureInstruction bankName={isMandiri ? `bank mandiri` : null} />
      </div>
    </WrapperModalButtons>,
  ];
  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        array={components}
        width={[420]}
      />
    </>
  );
};
