export const CurrencyFieldBoolean = (currencyRaw) => {
  const currency = currencyRaw?.toLowerCase();

  const isSGD = currency === "sgd";
  const isCNY = currency === "cny";
  const isSgdSwift = currency === "sgd-swift";
  const isUsdSwift = currency === "usd-swift";
  const isJPY = currency === "jpy";
  const isGBP = currency === "gbp";
  const isUSD = currency === "usd";

  const isUniqueCurrency = isCNY || isUsdSwift || isSgdSwift;
  const isCurrencyWithToggle = isGBP;

  return {
    isUSD,
    isSGD,
    isCNY,
    isJPY,
    isGBP,
    isUsdSwift,
    isSgdSwift,
    isUniqueCurrency,
    isCurrencyWithToggle,
  };
};

export const CountryFieldBoolean = (countryIsoRaw) => {
  const countryIso = countryIsoRaw?.toLowerCase();

  const isSG = countryIso === "sg";

  return { isSG };
};

export const FieldBoolean = (fieldRaw) => {
  const field = fieldRaw?.toLowerCase();

  const isIBAN = field === "iban";
  const isSwiftBIC = field === "swift_bic_code";
  const isBranchNumberJapan = field == "branch_number_japan";
  const isRoutingValue = field == "routing_code_value_1";
  const isBranchNumber = field == "branch_number";
  const isBankName = field == "bank_name";
  const isBankId = field == "bank_id";
  const isIdType = field == "id_type";
  const isMSISDN = field == "msisdn";
  const isIdNumber = field == "id_number";
  const isAccountType = field == "account_type";
  const isFirstName = field == "firstname";
  const isLastName = field == "lastname";
  const isCountryIsoCode = field == "country_iso_code";
  const isRecipientEmail =
    field == "recipient_email" || field == "recipient_email_required";
  const isReceivingGender = field == "receiving_gender" || field == "gender";
  const isDependentField = isBranchNumberJapan;
  const isHardcodeField = isIdType || isAccountType || isReceivingGender;
  const isAbaRoutingNumber = field == "aba_routing_number";
  const isRegistrationOpt = field == "receiving_registration_number_optional";
  const isPostalCode = field == "postal_code";
  const isTaxId = field == "tax_id";
  const isCity = field == "city";
  const isProvince = field == "province";
  const isRegion = field == "region";
  const isRoutingCode = field == "routing_code";
  const isSortCode = field == "sort_code";
  const isIfsCode = field == "ifs_code";
  const isNationalityIsoCode = field == "nationality_iso_code";
  const isCnaps = field == "cnaps";
  const isAddress = field == "address";
  const isCompanyRegistrationNumber = field == "company_registration_number";

  //CNY Custom
  const isRegisteredName =
    field === "receiving_registered_name" || field === "registered_name";
  const isPhoneNumber = field === "receiving_phone_number";
  const isAddressTextArea = field === "receiving_address_text_area";
  const isAccountNumber = field === "bank_account_number";
  const isAccountName = field === "bank_account_name";
  const isCompanySocialCreditCode = field == "company_social_credit_code";

  const isNotRequiredField =
    isRecipientEmail || isRegistrationOpt || isCompanyRegistrationNumber;

  return {
    isCity,
    isIBAN,
    isTaxId,
    isCnaps,
    isMSISDN,
    isIdType,
    isRegion,
    isBankId,
    isIfsCode,
    isAddress,
    isSortCode,
    isBankName,
    isProvince,
    isLastName,
    isSwiftBIC,
    isIdNumber,
    isFirstName,
    isPostalCode,
    isAccountName,
    isRoutingCode,
    isAccountType,
    isBranchNumber,
    isHardcodeField,
    isAccountNumber,
    isRecipientEmail,
    isRegisteredName,
    isCountryIsoCode,
    isReceivingGender,
    isAbaRoutingNumber,
    isNotRequiredField,
    isNationalityIsoCode,
    isCompanySocialCreditCode,
    isCompanyRegistrationNumber,
  };
};
