import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { NakedSearchInput } from "../../../../../../../components/inputs";
import { GothamMedium } from "../../../../../../../components/Text";
import { arraySplitter } from "../../../../../../../components/tools";
import { EWalletFilterModalContentStatus } from "./status";

export const EwalletMerchant = ({ activeObj }) => {
  const { pathname, query, push } = useRouter();

  return (
    <div className="w-100 h-100">
      <NakedSearchInput
        onChange={(text) => {
          push({ query: { ...query, merchant: text } });
        }}
        styleInput={{ marginsBottom: 12 }}
      />
      {/* {!!selectionArr.length && (
        <>
          <GothamMedium style={{ marginBottom: 12 }}>Recents</GothamMedium>
          <EWalletFilterModalContentStatus
            activeObj={{ name: "merchant", array: selectionArr }}
          />
        </>
      )} */}
    </div>
  );
};
