import { useRouter } from "next/router";
import selectedIcon from "../../../../../../../assets/images/selected-radio-icon.svg";
import unselectedIcon from "../../../../../../../assets/images/unselected-radio-icon.svg";
import { GothamRegular } from "../../../../../../../components/Text";

const Select = ({ isActive, children, onClick }) => (
  <div className="d-flex hover" onClick={onClick} style={{ marginBottom: 16 }}>
    <img
      src={isActive ? selectedIcon : unselectedIcon}
      style={{ marginRight: 8 }}
    />
    <GothamRegular>{children}</GothamRegular>
  </div>
);

export const EWalletFilterModalAttachment = ({
  activeObj: { array, name },
}) => {
  const { query, push } = useRouter();
  const queryValue = query[name];

  return array.map((item, index) => {
    const { label, value } = item || {};

    const isActiveDecider = () => {
      if (!value) return !queryValue;
      return value == queryValue;
    };

    const isActive = isActiveDecider();

    return (
      <Select
        key={index}
        onClick={() => {
          let obj = query;

          if (!value) {
            delete obj[name];
          } else {
            obj[name] = value;
          }

          push({ query: obj });
        }}
        isActive={isActive}
      >
        {label}
      </Select>
    );
  });
};
