import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

const useBankProcedureDecider = (bank) => {
  const { t } = useTranslation("topup");
  const { locale } = useRouter();
  const isIndo = locale == "id";

  const inputPaymentAmount = t("Input payment amount");
  const selectYourSourceOfFunds = t("Select your source of funds");
  const checkDataOnYourScreen = t("Check data on your screen");

  const inputThe16Digit = t("Input the 16-digit virtual account number");

  const paymentSucceed = t("Payment succeed");

  const CIMB = {
    "OCTO Clicks": [
      t("Login to OCTO Clicks"),
      t("Select “Pay Bills”"),
      t("Select your “Source Account” and “Payment Type”"),
      t("Select “Virtual Account”"),
      inputThe16Digit,
      inputPaymentAmount,
      checkDataOnYourScreen,
      t("Enter your mPIN and click “Submit”"),
      paymentSucceed,
    ],
    "OCTO Mobile": [
      t("Login to OCTO Mobile"),
      t("Select “Transfer” > “Transfer to Other CIMB Niaga Account”"),
      t("Select your source of funds (CASA or “Rekening Ponsel”)"),
      inputThe16Digit,
      inputPaymentAmount,
      checkDataOnYourScreen,
      t("Enter your OCTO mobile PIN"),
      paymentSucceed,
    ],
    ATM: [
      t("Select “Payment“ > “Continue” > “Virtual Account”"),
      inputThe16Digit,
      inputPaymentAmount,
      t("Check the data on your screen. If correct, choose “OK” to continue"),
      paymentSucceed,
    ],
  };

  const checkTheData = t(
    "Check the data on your screen. If correct, choose “YES” to continue"
  );

  const PERMATA = {
    PermataNet: [
      t("Login to PermataNet"),
      t("Select “Pay Bills” > “Virtual Account”"),
      inputThe16Digit,
      inputPaymentAmount,
      checkTheData,
      t("Enter your Response Code and click “Next”"),
      paymentSucceed,
    ],
    "Permata Mobile": [
      t("Login to Permata Mobile"),
      t("Select “Bill Payment” > “Virtual Account”"),
      inputThe16Digit,
      inputPaymentAmount,
      checkDataOnYourScreen,
      t("Enter your transaction authentication or token"),
      paymentSucceed,
    ],
    ATM: [
      t(
        "Select “Other Transactions” > “Payment” > “Other Payment” > “Virtual Account”"
      ),
      inputThe16Digit,
      inputPaymentAmount,
      selectYourSourceOfFunds,
      checkTheData,
      paymentSucceed,
    ],
  };

  const BRI = {
    BRImo: [
      t("Login to BRImo"),
      t("Select “Payment” > “BRIVA”"),
      t(inputThe16Digit),
      inputPaymentAmount,
      selectYourSourceOfFunds,
      checkDataOnYourScreen,
      t("Enter your mobile banking PIN"),
      paymentSucceed,
    ],
    ATM: [
      t("Select “Other Transaction” > “Payment” > “Other Payment” > “BRIVA”"),
      inputThe16Digit,
      inputPaymentAmount,
      checkTheData,
      selectYourSourceOfFunds,
      paymentSucceed,
    ],
  };

  const input15Digit = t("Input the 15-digit virtual account number");
  const mandiriCode = t(
    "Search and select Transfez as service provider (Code: 89528)"
  );

  const selectSourceFund = t("Select your preferred Source of Fund");

  const MANDIRI = {
    "Livin by Mandiri": [
      t("Login to Livin by Mandiri app"),
      t("Select “Payment”"),
      mandiriCode,
      input15Digit,
      inputPaymentAmount,
      t(
        "Check data on your screen. If all the data are correct, choose “Next” > “Send Money”"
      ),
      t("Input your PIN"),
      t("Payment succeeds"),
    ],
    "Mandiri Internet Banking": [
      t("Login to Mandiri Internet Banking"),
      t("Select “Payment” > “Multi-Payment”"),
      selectSourceFund,
      mandiriCode,
      input15Digit,
      inputPaymentAmount,
      t("Follow further instructions to complete the payment"),
    ],
    ATM: [
      t("Select “Payment” > “Other” > “Multi-Payment”"),
      mandiriCode,
      input15Digit,
      inputPaymentAmount,
      t(
        "Check data on your screen. If correct, input “1” and choose “YES” to continue"
      ),
      t("Payment succeeds"),
    ],
    "Mandiri Cash Management (MCM)": [
      t("Login to Mandiri Cash Management"),
      t("Select “Payment” > “Single Bill Payment”"),
      selectSourceFund,
      t("Input Transfez Biller code (89528)"),
      t("Input Jack Account Number then click “INQUIRY”"),
      t(
        "Check the data on your screen. Ensure the recipient and total payment are correct"
      ),
      t("Follow further instructions to complete the payment"),
      paymentSucceed,
    ],
  };

  const BNI = {
    "BNI Internet Banking": [
      t("Login to BNI Internet Banking"),
      t("Select “Transaction” > “Virtual Account Billing”"),
      inputThe16Digit,
      inputPaymentAmount,
      t("Select your source of funds and click “Continue”"),
      checkDataOnYourScreen,
      t("Enter your Token Authentication Code"),
      paymentSucceed,
    ],
    "BNI Mobile Banking": [
      t("Login to BNI Mobile Banking app"),
      t("Select “Transfer” > “Virtual Account Billing”"),
      selectYourSourceOfFunds,
      inputThe16Digit,
      inputPaymentAmount,
      t("Check the data on your screen"),
      t("Enter your transaction password"),
      paymentSucceed,
    ],
    ATM: [
      t("Select “Other Transaction” > “Transfer”"),
      selectYourSourceOfFunds,
      t("Select “Virtual Account Billing”"),
      inputThe16Digit,
      inputPaymentAmount,
      checkTheData,
      paymentSucceed,
    ],
  };

  const BTPN = {
    BTPN_ONLINE: [
      t(
        "Enter the following address: https://ibank.bni.co.id and click “enter”"
      ),
      t("Insert your User ID and Password."),
      t("Select VIRTUAL ACCOUNT"),
      t("Input 16 digit virtual account number XXXXXXXXXXXXXXXX."),
      t(
        "Select the type of account you are using to transfer. And press “CONTINUE”."
      ),
      t("Reconfirm the transaction"),
      t("Input your i-Banking token."),
      t("Payment succeed."),
    ],
    BTPN_MOBILE: [
      t(
        "Access BNI Mobile Banking from your phone, and insert your user ID and password"
      ),
      t("Select TRANSFER"),
      t("Select VIRTUAL ACCOUNT and then choose debit account"),
      t(
        "Input 16 digit virtual account number XXXXXXXXXXXXXXXX, on the menu INPUT NEW"
      ),
      t("Confirm your billing"),
      t("Input your password"),
      t("Payment succeed."),
    ],
    BTPN_ATM: [
      t("Input your ATM card and PIN."),
      t("Select OTHER TRANSACTION"),
      t("Select TRANSFER"),
      t(
        "Select the type of account you are using to transfer (i.e from Savings account)"
      ),
      t("Select VIRTUAL ACCOUNT"),
      t("Input 16 digit virtual account number XXXXXXXXXXXXXXXX."),
      t("Input payment amount."),
      t("Check your transaction again, then choose YES to proceed."),
      t("Payment succeed."),
    ],
  };

  const inputTransfezAccNumber = t("Input Transfez account number");
  const inputTheExactTransfer3Digits = t(
    "Input the exact transfer amount with the last 3 digits"
  );

  const checkDataOnYourScreenEnsure = t(
    "Check data on your screen. Ensure the recipient's name is PT INDOKOALA REMITTANCE"
  );
  const BCA = {
    KlikBCA: [
      t("Login to KlikBCA"),
      t("Select “Fund Transfer” > “Transfer to BCA Account”"),
      inputTransfezAccNumber,
      inputTheExactTransfer3Digits,
      checkDataOnYourScreenEnsure,
      t(`Input your KeyBCA Response and click "Submit"`),
    ],
    "BCA Mobile": [
      t("Login to BCA Mobile"),
      t("Select “m-Transfer” > “Antar Rekening”"),
      inputTransfezAccNumber,
      inputTheExactTransfer3Digits,
      checkDataOnYourScreenEnsure,
      t("Input your m-BCA PIN and click “OK”"),
    ],
    ATM: [
      t("Select “Transfer”"),
      inputTransfezAccNumber,
      inputTheExactTransfer3Digits,
      checkDataOnYourScreenEnsure,
      t("If correct, select YES"),
    ],
  };

  const getOtherArr = (string) => {
    const defaultArr = [
      t("Select Transfer to other banks"),
      string || t("Enter the virtual account number"),
      t("Enter the amount you wish to top-up"),
      t("Follow the app instructions to complete the transaction"),
      paymentSucceed,
    ];

    if (isIndo) return defaultArr;

    return [
      "Open and sign-in to your preferred mobile/internet banking apps",
      ...defaultArr,
    ];
  };

  const OTHER = {};

  const otherSteps = getOtherArr();

  const OTHERMANUAL = {};

  const otherManualSteps = getOtherArr(t("Enter the account number"));

  const otherBanksKey = t("Transfer from Other Banks");

  OTHER[otherBanksKey] = otherSteps;
  OTHERMANUAL[otherBanksKey] = otherManualSteps;

  if (!bank) return {};

  const isBankBRI = "Bank BRI".toLowerCase() === bank.toLowerCase();
  const isBankPermata = "Bank Permata".toLowerCase() === bank.toLowerCase();
  const isBankCIMBNiaga =
    "Bank CIMB Niaga".toLowerCase() === bank.toLowerCase();
  const isBankMandiri = "Bank Mandiri".toLowerCase() === bank.toLowerCase();
  const isBankBNI = "Bank BNI".toLowerCase() === bank.toLowerCase();
  const isBankBTPN = "Bank BTPN".toLowerCase() === bank.toLowerCase();
  const isBankBCA = "Bank BCA".toLowerCase() === bank.toLowerCase();

  if (isBankBRI) return { ...BRI, ...OTHER };
  if (isBankPermata) return { ...PERMATA, ...OTHER };
  if (isBankCIMBNiaga) return { ...CIMB, ...OTHER };
  if (isBankMandiri) return { ...MANDIRI };
  if (isBankBNI) return BNI;
  if (isBankBTPN) return { ...BTPN, ...OTHER };
  if (isBankBCA) return { ...BCA, ...OTHERMANUAL };
};

export default useBankProcedureDecider;
