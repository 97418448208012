import * as Sentry from "@sentry/nextjs";
import { isProduction } from "@tools";
import { LSUserDeletion } from "contexts/AuthContext";
import { encryptToken } from "../hash";

const pinLSKey = "pin-remaining-attempt";
const otpLSKey = "OTL";

const pinAndOTPInterceptor = (error: any) => {
  const defaultReturn = Promise.reject(error);

  const { data, url } = error?.config || {};
  const isFormData = data instanceof FormData;

  if (isFormData) return defaultReturn;

  const obj: { [key: string]: any } = data ? JSON.parse(data) : {};

  const { pin: pinRaw, otp_code } = obj;

  const pin = pinRaw || String(url).includes("pin=");

  if (!pin && !otp_code) return defaultReturn;

  const parentResponseData = error?.response?.data;
  const attemptsCountObj = parentResponseData?.hasOwnProperty(
    "remaining_attempt"
  )
    ? parentResponseData
    : parentResponseData?.data?.hasOwnProperty("remaining_attempt")
    ? parentResponseData?.data
    : {};

  const attemptsCount = attemptsCountObj?.remaining_attempt;

  const hasAttemptProperty =
    attemptsCountObj?.hasOwnProperty("remaining_attempt");

  const isSuspended = !attemptsCount && hasAttemptProperty;

  const LSKey = otp_code ? otpLSKey : pinLSKey;

  if (!isSuspended) {
    localStorage.setItem(LSKey, encryptToken(`${attemptsCount}`));
    return defaultReturn;
  }

  const isAuthorized = Boolean(localStorage.getItem("token"));

  if (isAuthorized) LSUserDeletion();

  const path = otp_code ? "/login" : "/login?maxInvalidPINReached=true";

  localStorage.removeItem(LSKey);

  window.location.href = path;

  return Promise.reject(error);
};

const errorInterceptor = (error: any) => {
  const { url } = error?.config || {};

  const isNetworkError = !error?.response;
  const isServerError = error?.response && error.response.status >= 500;
  const unsubmittedLogs = isNetworkError || isServerError;
  // const unsubmittedLogs = true;

  if (unsubmittedLogs && isProduction) {
    Sentry.withScope((scope) => {
      if (error.config) {
        scope.setTag("endpoint", error.config.url || "Unknown URL");
        scope.setTag("method", error.config.method || "Unknown Method");
      }

      if (error.response) {
        scope.setTag("status", error.response.status || "No Status");
        scope.setContext("response", {
          status: error.response.status,
          statusText: error.response.statusText,
          headers: error.response.headers,
          data: error.response.data,
        });
      } else {
        scope.setContext("network", {
          message: "Network error, no response received",
          baseURL: error.config?.baseURL || "Unknown Base URL",
          url: error.config?.url || "Unknown URL",
        });
      }
      Sentry.captureException(
        new Error(
          "Unsubmitted log: " + (url || error.message || "Unknown error")
        )
      );
    });
  }

  const isAuthenticate = url == "/authenticate";

  if (isAuthenticate) return Promise.reject(error);

  return pinAndOTPInterceptor(error);
};

export default errorInterceptor;
