import { useEffect, useState } from "react";
import { colors } from "../../assets/colors";
import chevronLeftPdf from "../../assets/images/chevronLeftPdf.svg";
import chevronRightPdf from "../../assets/images/chevronRightPdf.svg";
import { JackIcons } from "../../assets/jackIcons/parent";
import LoadingSpinner from "../../components/Loading";
import { useModalHook } from "../../components/Modals";
import { GothamLight, TypeFezNeueMedium } from "../../components/Text";
import { fileTypes } from "../../components/inputs";
import { toBase64, windowDimension } from "../../components/tools";
import dynamic from "next/dynamic";

export const useReactPDF = () => {
  const Document = dynamic(() =>
    import("react-pdf").then((res) => res.Document)
  );
  const Page = dynamic(() => import("react-pdf").then((res) => res.Page));

  useEffect(async () => {
    const { pdfjs } = await import("react-pdf");
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  return { Document, Page };
};

export const PDFOrImageGdriveViewer = ({ isPDF, src, docRef }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [file, setFile] = useState(null);
  const [scale, setScale] = useState(1);
  const disableZoomIn = scale >= 1.5;
  const disableZoomOut = scale == 1;
  const isUploaded = typeof file == "string";
  const [imageDimensions, setImageDimensions] = useState({});

  const { Document, Page } = useReactPDF();

  const { clientHeight, clientWidth } = imageDimensions;

  const { isOpen: isScaleUp, toggle, close } = useModalHook();
  const { height } = windowDimension();

  const reset = () => {
    close();
    setImageDimensions({});
    setNumPages(null);
    setPageNumber(1);
    setFile(null);
  };

  useEffect(async () => {
    reset();
    const isUploaded = typeof src == "string";
    if (!src) return;
    try {
      const { isPDF } = fileTypes(src?.type || src);
      if (isUploaded) {
        let blob = await fetch(src).then((r) => r.blob());
        const file = await toBase64(blob);
        scale != 1 && setScale(1);
        return setFile(file);
      }

      if (isPDF) return setFile(src);

      const base64 = await toBase64(src);

      return setFile(String(base64));
    } catch (error) {
      console.log("File not blob");
    }
  }, [src]);

  const scaleUp = isScaleUp && {
    width: clientWidth * 2,
    height: clientHeight * 2,
  };

  if (isPDF) {
    const Text = ({ children }) => (
      <TypeFezNeueMedium style={{ color: "#FFFFFF" }}>
        {children}
      </TypeFezNeueMedium>
    );

    const Arrow = ({ src, onClick }) => (
      <div
        style={{
          height: 20,
          width: 20,
          borderRadius: 0,
          marginLeft: 8,
          marginRight: 8,
        }}
        className="darkhover d-flex align-items-center justify-content-center"
        onClick={onClick}
      >
        <img
          src={src}
          style={{
            height: 12,
          }}
        />
      </div>
    );

    // remove annoying annotations from PDF viewer
    const removeAnnotations = () => {
      const textLayers = document.querySelectorAll(
        ".react-pdf__Page__textContent"
      );
      textLayers.forEach((layer) => {
        const { style } = layer;
        style.display = "none";
      });
    };
    const filePDF = isUploaded ? { url: file } : file;
    return (
      <>
        <div ref={docRef} className="preventblueblock">
          <Document
            file={filePDF}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
            }}
            onClick={toggle}
            loading={
              !file && (
                <LoadingSpinner
                  style={{
                    color: colors.pink,
                  }}
                />
              )
            }
          >
            <Page
              pageNumber={pageNumber}
              height={isScaleUp ? height : height * 0.8}
              renderAnnotationLayer={false}
              onLoadSuccess={removeAnnotations}
              scale={scale}
            />
          </Document>
        </div>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            position: "absolute",
            bottom: 10,
            width: "auto",
            height: 40,
            padding: "0px 12px",
            borderRadius: 12,
            gap: 8,
            display: "flex",
            alignItems: "center",
          }}
          ref={docRef}
        >
          <Arrow
            src={chevronLeftPdf}
            onClick={() =>
              setPageNumber((prev) => {
                if (prev <= 1) return 1;
                return prev - 1;
              })
            }
          />
          <Text>Page</Text>
          <Text>{pageNumber}</Text>
          <Text>/</Text>
          <Text>{numPages}</Text>
          <Arrow
            src={chevronRightPdf}
            onClick={() =>
              setPageNumber((prev) => {
                if (prev >= numPages) return numPages;
                return prev + 1;
              })
            }
          />
          <div style={{ display: "flex", gap: 12 }}>
            <JackIcons
              name="minus"
              onClick={() => {
                if (disableZoomOut) return;
                setScale((p) => p - 0.1);
              }}
              style={{ cursor: "pointer" }}
              fill={"white"}
            />
            <JackIcons
              name="plus_Outline"
              onClick={() => {
                if (disableZoomIn) return;
                setScale((p) => p + 0.1);
              }}
              fill={"white"}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </>
    );
  }
  const imageSrc = file;
  return (
    <>
      <img
        className="preventblueblock"
        ref={docRef}
        onClick={toggle}
        onLoad={(e) => {
          if (!e) return;
          const { clientHeight, clientWidth, src } = e.target;

          setImageDimensions((prev) => {
            if (prev.src == src) return prev;
            return { clientHeight, clientWidth, src };
          });
        }}
        src={imageSrc}
        style={{
          zIndex: 500000,
          maxHeight: "100%",
          maxWidth: "100%",
          cursor: "pointer",
          transform: `scale(${scale})`,
          transition: "transform 0.3s ease",
          ...scaleUp,
        }}
      />
      <ZoomBar
        setScale={setScale}
        disableZoomOut={disableZoomOut}
        disableZoomIn={disableZoomIn}
      />
    </>
  );
};

const ZoomBar = ({ setScale, disableZoomIn, disableZoomOut }) => (
  <div
    className="zoom-container"
    style={{
      position: "fixed",
      bottom: 10,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 500001,
    }}
  >
    <JackIcons
      name="minus"
      onClick={() => {
        if (disableZoomOut) return;
        setScale((p) => p - 0.1);
      }}
      style={{ cursor: "pointer" }}
      fill={"white"}
    />
    <JackIcons
      name="plus_Outline"
      onClick={() => {
        if (disableZoomIn) return;
        setScale((p) => p + 0.1);
      }}
      fill={"white"}
      style={{ cursor: "pointer" }}
    />
    <GothamLight
      className="font12"
      style={{ color: "white", cursor: "pointer" }}
      onClick={() => setScale(1)}
    >
      Reset
    </GothamLight>
  </div>
);
