import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { colors } from "../../../../../../../assets/colors";
import { NakedSearchInput } from "../../../../../../../components/inputs";
import { GothamMedium } from "../../../../../../../components/Text";
import {
  canRefetchDecider,
  useDebounce,
  widthOrHeight,
} from "../../../../../../../components/tools";
import { fetch } from "../../../../../../../tools/api";
import { defaultPaginationFormatter } from "../../../../../../needActionComponents/pages/table/logics/formatter";
import { queryArray } from "./parent";
import { EWalletFilterModalContentStatus } from "./status";

const arrayFormatter = (array, noPhone) =>
  array.map((item) => {
    const { id, mobile, country_code, name, email } = item;
    const nameOrEmail = (name || email).split(" ").slice(0, 2).join(" ");

    const labelDecider = () => {
      if (noPhone) return nameOrEmail;
      if (mobile && country_code) return `${nameOrEmail} - 0${mobile}`;
      return nameOrEmail;
    };

    const label = labelDecider();

    return { label, value: String(id) };
  });

export const EWalletFilterModalWallet = ({
  activeObj: { name, urlFunc = () => {}, noPhone },
}) => {
  const { query, push } = useRouter();
  const [page, setPage] = useState(1);

  const [text, setText] = useState("");

  const { data, loading, refetch, setData } = fetch({
    url: urlFunc(page, text),
    woInit: true,
    formatter: defaultPaginationFormatter,
  });
  const { array: arrayRaw = [], total_page } = data || {};

  const array = arrayFormatter(arrayRaw, noPhone);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const debouncedText = useDebounce(text, 400);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
    setData({});
    if (page == 1) return refetch();
    setPage(1);
  }, [debouncedText]);

  useEffect(refetch, [page]);

  const ref = useRef();
  const scrollRef = useRef();

  const { clientHeight } = widthOrHeight(ref);

  const values = array.map(({ value }) => value);

  const isAllSelected = !values.filter(
    (value) => !queryArray(query, name).includes(value)
  ).length;

  return (
    <div className="h-100">
      <div ref={ref}>
        <NakedSearchInput
          onChange={(text) => setText(text)}
          placeholder="Search by name or phone number"
        />
        {!!array.length && (
          <div style={{ paddingBottom: 16 }}>
            <GothamMedium
              style={{ color: colors.pink }}
              className="hover"
              onClick={() => {
                let obj = query;

                if (isAllSelected) {
                  delete obj[name];
                } else {
                  obj[name] = values;
                }
                push({ query: obj });
              }}
            >
              {isAllSelected ? "Unselect All" : "Select All"}
            </GothamMedium>
          </div>
        )}
      </div>
      <div
        style={{
          height: `calc(100% - ${clientHeight}px)`,
          overflowY: "scroll",
        }}
        ref={scrollRef}
        onScroll={() => {
          const canRefetch = canRefetchDecider(scrollRef, loading);
          if (!canRefetch) return;
          if (page >= total_page) return;
          setPage(page + 1);
        }}
      >
        <EWalletFilterModalContentStatus
          activeObj={{ array, name, noSplit: true, loading }}
        />
      </div>
    </div>
  );
};
