import { IRFilterCreatedDate } from "../../../../../../reimbursementComponents/filter/modal/content/createdDate";
import { EWalletFilterModalAmount } from "./amount";
import { EWalletFilterModalAttachment } from "./attachment";
import { EWalletFilterModalBank } from "./bank";
import { EWalletFilterModalContentCard } from "./card";
import { CreatedByModalContent } from "./createdBy";
import { CreatedByRmb } from "./createdByRmb";
import { EWalletFilterKeyword } from "./keyword";
import { EwalletMerchant, MerchantModalContent } from "./merchant";
import { EWalletFilterModalContentStatus } from "./status";
import { EWalletFilterModalTeam } from "./team";
import { EWalletFilterModalWallet } from "./wallet";

export const eWalletFilterTypeBooleans = (type) => {
  const isStatus = type == "status";
  const isMerchant = type == "merchant";
  const isDate = type == "date";
  const isCreatedBy = type == "created_by";
  const isCreatedByRmb = type == "created_by_rmb";
  const isKeyword = type == "keywords";
  const isCard = type == "card";
  const isFetch = type == "fetch";
  const isAmount = type == "amount";
  const isTeam = type == "team";
  const isRadio = type == "radio";
  const isBank = type == "bank";
  return {
    isBank,
    isRadio,
    isStatus,
    isMerchant,
    isDate,
    isCreatedBy,
    isCreatedByRmb,
    isKeyword,
    isCard,
    isFetch,
    isAmount,
    isTeam,
  };
};

export const queryArray = (query, name) => {
  const queryValue = query[name];

  const result = Array.isArray(queryValue) ? queryValue : [queryValue];

  return result.filter((item) => item);
};

export const EWalletFilterModalContent = ({ activeObj }) => {
  const { type } = activeObj || {};

  const {
    isStatus,
    isMerchant,
    isDate,
    isCreatedBy,
    isKeyword,
    isCard,
    isFetch,
    isAmount,
    isTeam,
    isRadio,
    isBank,
    isCreatedByRmb,
  } = eWalletFilterTypeBooleans(type);

  if (isMerchant) return <EwalletMerchant activeObj={activeObj} />;

  if (isStatus)
    return <EWalletFilterModalContentStatus activeObj={activeObj} />;

  if (isDate) return <IRFilterCreatedDate activeFilterObj={activeObj} />;

  if (isCreatedBy) return <CreatedByModalContent activeObj={activeObj} />;

  if (isKeyword) return <EWalletFilterKeyword activeObj={activeObj} />;

  if (isCard) return <EWalletFilterModalContentCard activeObj={activeObj} />;

  if (isFetch) return <EWalletFilterModalWallet activeObj={activeObj} />;

  if (isAmount) return <EWalletFilterModalAmount activeObj={activeObj} />;

  if (isTeam) return <EWalletFilterModalTeam activeObj={activeObj} />;

  if (isRadio) return <EWalletFilterModalAttachment activeObj={activeObj} />;

  if (isBank) return <EWalletFilterModalBank activeObj={activeObj} />;

  if (isCreatedByRmb) return <CreatedByRmb activeObj={activeObj} />;
  return null;
};
