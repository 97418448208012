import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { NakedSearchInput } from "../../../../../../../components/inputs";
import { GothamMedium } from "../../../../../../../components/Text";
import { arraySplitter } from "../../../../../../../components/tools";
import { EWalletFilterModalContentStatus } from "./status";

export const EWalletFilterKeyword = ({ activeObj }) => {
  const { pathname, query, push } = useRouter();
  const key = `${pathname}keywords`;

  const [text, setText] = useState("");
  const [array, setArray] = useState([]);

  const selectionArr = array.map((key) => ({
    label: key,
    value: key,
  }));

  useEffect(() => {
    const array = JSON.parse(localStorage.getItem(key)) || [];
    setArray(array);
  }, []);

  const onSubmit = () => {
    // 3 stack array
    const newArr = arraySplitter([text, ...array], 3)[0];
    setArray(newArr);

    push({ query: { ...query, keywords: text } });

    const isDuplicated = array.includes(text);
    if (isDuplicated) return;
    // save to local storage
    localStorage.setItem(key, JSON.stringify(newArr));
  };

  return (
    <div className="w-100 h-100">
      <NakedSearchInput
        onChange={(text) => setText(text)}
        styleInput={{ marginBottom: 12 }}
        onKeyPress={({ key }) => {
          const isEnter = key == "Enter";
          if (!isEnter) return;
          onSubmit();
        }}
      />
      {!!selectionArr.length && (
        <>
          <GothamMedium style={{ marginBottom: 12 }}>Recents</GothamMedium>
          <EWalletFilterModalContentStatus
            activeObj={{ name: "keywords", array: selectionArr }}
          />
        </>
      )}
    </div>
  );
};
