import { useRouter } from "next/router";
import React, { useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import titleCase from "title-case";
import { colors } from "../assets/colors";
import filterIcon from "../assets/images/filter-icon.svg";
import greyfilter from "../assets/images/greyfilter.svg";
import greyfilterstrong from "../assets/images/greyfilterstrong.svg";
import search from "../assets/images/search.svg";
import searchDarkIcon from "../assets/images/search-dark-grey-icon.svg";
import whitefilter from "../assets/images/whitefilter.svg";
import xIconGrey from "../assets/images/x-icon.svg";
import { CustomButton } from "../components/Buttons";
import {
  defaultIconStyleQueryInput,
  QueryDatePicker,
  QueryInput,
  QuerySelection,
} from "../components/inputs";
import {
  aWeek,
  dateFilterConstructor,
  numberOfFilterQueriesApplied,
} from "../components/tools";
import { useClickOutside } from "../universalFunctions/useClickOutside";
import { useModalHook } from "./Modals";
import { GothamMedium, GothamRegular } from "./Text";

const FilterInputItems = ({ data }) =>
  data.map(
    (
      {
        name = "",
        label,
        options,
        loading,
        columnProps,
        placeholder = "",
        date,
        ...rest
      },
      index
    ) => {
      const allProps = {
        name,
        label,
        options,
        loading,
        columnProps,
        placeholder,
        ...rest,
      };

      if (!name) return null;

      const defaultSelectionProps = {
        isLoading: loading,
        key: index,
        label: label ? label : titleCase(name),
      };

      if (date)
        return <QueryDatePicker {...allProps} {...defaultSelectionProps} />;

      if (options)
        return <QuerySelection {...allProps} {...defaultSelectionProps} />;

      return <QueryInput isCol key={index} {...allProps} />;
    }
  );

const NumberOfFilter = ({ number, className, style, isWhite }) => (
  <div
    style={{
      fontSize: 14,
      backgroundColor: isWhite ? "white" : colors.teal21,
      color: isWhite ? colors.teal21 : "white",
      borderRadius: 1000,
      height: 26,
      width: 26,
      ...style,
    }}
    className={`d-flex align-items-center justify-content-center mr-2 ${className}`}
  >
    <GothamMedium className="m-0 mt-1">{number}</GothamMedium>
  </div>
);

export const FilterButton = ({ onClick = () => {}, isActive }) => {
  const { query } = useRouter();
  const filteredNumber = numberOfFilterQueriesApplied(query);
  return (
    <CustomButton
      className="btn-blue px-4"
      onClick={onClick}
      style={{
        backgroundColor: isActive ? colors.teal : "",
        height: 48,
        fontSize: 20,
      }}
    >
      {filteredNumber === 0 ? (
        <img src={filterIcon} className="mr-2" />
      ) : (
        <NumberOfFilter number={filteredNumber} />
      )}
      Filters
    </CustomButton>
  );
};

export const DefaultQueryInput = ({
  placeholder,
  queryName = "search_box",
  woSearchIcon,
  darkIcon = false,
  ...props
}) => {
  const icon = darkIcon ? searchDarkIcon : search;
  return (
    <QueryInput
      name={queryName}
      placeholder={placeholder}
      icon={!woSearchIcon && <img src={icon} {...defaultIconStyleQueryInput} />}
      {...props}
    />
  );
};

export const QueryClearInput = ({
  placeholder,
  queryName = "search_box",
  woSearchIcon,
  darkIcon = false,
  ...props
}) => {
  const icon = darkIcon ? searchDarkIcon : search;
  return (
    <QueryInput
      name={queryName}
      placeholder={placeholder}
      icon={!woSearchIcon && <img src={icon} {...defaultIconStyleQueryInput} />}
      xIcon={
        <img
          src={xIconGrey}
          style={{
            position: "absolute",
            right: "20px",
            top: "12px",
          }}
        />
      }
      woClearButton={false}
      {...props}
    />
  );
};

const SearchAndFilterLayout = ({ placeholder, children, bigSearch, style }) => (
  <div className="mx-0" style={style}>
    <Row className="mx-0 justify-content-between">
      <Col md={4} className="mx-0 px-0">
        <div style={{ width: bigSearch ? "95%" : "85%" }}>
          <DefaultQueryInput placeholder={placeholder || ""} />
        </div>
      </Col>
      <Col md={6} className="px-0 d-flex justify-content-end">
        {children}
      </Col>
    </Row>
  </div>
);

const searchAndFilterDropDownProps = ({ number, isOpen }) => {
  const defaultProps = {
    backgroundColor: colors.pink,
    src: whitefilter,
    fontFamily: "GothamMedium",
    color: "white",
  };
  if (number) return defaultProps;
  if (isOpen)
    return {
      ...defaultProps,
      src: greyfilterstrong,
      backgroundColor: colors.greyea,
      color: colors.grey72,
    };
  return {
    src: greyfilter,
    fontFamily: "GothamBook",
    backgroundColor: "",
    color: colors.grey72,
  };
};

export const SearchAndFilterDropDown = ({
  placeholder,
  bigSearch,
  style,
  data = [],
  woDate,
  rightComponent = null,
}) => {
  const customizedData = data.map((item, index) => {
    const isLast = index == data.length - 1;
    return {
      mainContainerStyle: { marginBottom: isLast ? 0 : 16 },
      woClearButton: true,
      ...item,
    };
  });
  const { query, push } = useRouter();
  const { isOpen, close, toggle } = useModalHook();

  const number = numberOfFilterQueriesApplied(query);

  const { backgroundColor, fontFamily, src, color } =
    searchAndFilterDropDownProps({
      number,
      isOpen,
    });

  const handleClearAll = () => {
    const { team, start_date, end_date, ...rest } = query;
    close();
    push({ query: rest });
  };

  const ref = useRef(null);
  useClickOutside({ ref, clickOutside: close });

  const [width, setWidth] = useState(0);

  return (
    <SearchAndFilterLayout
      placeholder={placeholder}
      style={style}
      bigSearch={bigSearch}
    >
      <div className="d-flex align-items-center" style={{ height: 42 }}>
        <div
          ref={ref}
          onClick={toggle}
          className="darkhover d-flex align-items-center"
          style={{
            height: 42,
            paddingLeft: 12,
            paddingRight: 12,
            borderRadius: 8,
            border: `1px solid ${colors.greyC2}`,
            backgroundColor,
          }}
        >
          <img src={src} className="mr-2" />
          <GothamRegular
            style={{
              fontFamily,
              color,
              fontSize: 14,
              margin: 0,
              marginTop: 3,
            }}
          >
            Filters
          </GothamRegular>
          {Boolean(number) && (
            <NumberOfFilter number={number} isWhite className="mr-0 ml-2" />
          )}
          {isOpen && (
            <div
              className="no-hover"
              onClick={(e) => {
                if (!e) return;
                e.preventDefault();
                e.stopPropagation();
              }}
              style={{
                zIndex: 1,
                boxShadow: `0px 8px 20px rgba(88, 88, 88, 0.1)`,
                backgroundColor: "white",
                borderRadius: 8,
                width: 280,
                right: width,
                position: "absolute",
                top: 42 + 8,
                padding: 12,
                paddingTop: 16,
                paddingBottom: 16,
              }}
            >
              <div
                className="d-flex justify-content-between"
                style={{
                  marginBottom: 20,
                }}
              >
                <GothamMedium className="m-0">Filters</GothamMedium>
                <GothamRegular
                  style={{
                    color: colors.redE9,
                    fontSize: 14,
                  }}
                  className="m-0 darkhover"
                  onClick={handleClearAll}
                >
                  Clear All
                </GothamRegular>
              </div>
              {!woDate && (
                <QueryDatePicker
                  isRangeDate
                  label="Date"
                  name="date"
                  defaultEnd={new Date()}
                  defaultDate={dateFilterConstructor(aWeek)}
                />
              )}
              <FilterInputItems data={customizedData} />
            </div>
          )}
        </div>
        <div ref={(e) => setWidth(e?.clientWidth || 0)}>{rightComponent}</div>
      </div>
    </SearchAndFilterLayout>
  );
};
