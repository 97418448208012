import { isEmpty, pickBy } from "lodash";
import {
  encryptedPasswordFunc,
  encryptPinPayload,
  SecretKeyPayload,
} from "./hash";
import { GetDataOptions } from "@fingerprintjs/fingerprintjs-pro-react";
import {
  ExtendedGetResult,
  GetResult,
} from "@fingerprintjs/fingerprintjs-pro-spa";

type Method = "put" | "post" | "patch" | "delete";

interface Obj {
  pin?: string;
  data?: {
    [key: string]: any;
  };
  new_pin?: string;
  [key: string]: any;
}

const containsUnsafeString = (obj: Obj) => {
  const jsonString = String(JSON.stringify(obj)).toLowerCase();
  const scriptTagPattern =
    /<\s*script\b[^>]*>([\s\S]*?)<\/\s*script\s*>|<\s*script\b[^>]*\/?\s*>/i;

  const result = scriptTagPattern.test(jsonString);

  return result;
};

export const payloadMutationMaker = async ({
  payload: payloadRaw,
  getData,
  method,
  url,
}: {
  method: Method;
  url: string;
  getData: (
    getDataOptions?: GetDataOptions<boolean> | undefined
  ) => Promise<GetResult | ExtendedGetResult>;
  payload: Obj;
}) => {
  let payload = payloadRaw;

  const isScript = containsUnsafeString(payload);

  if (isScript) return "Internal server error (420)";

  const { pin, data, new_pin } = payload || {};

  const password = !isEmpty(
    pickBy(payload || {}, (_, key) => key.includes("password"))
  );
  const { pin: nestedPin } = data || {};
  const hasNestedPin = nestedPin && method == "delete";

  const hasPin = pin || hasNestedPin || new_pin;
  const isSalus = hasPin || password;

  if (!isSalus) return payload;

  let salus = payload?.salus;
  let visitorId = payload?.visitorId;

  const generateSalus = !(salus && visitorId);

  if (generateSalus) {
    const { visitorId: visitorIdGenerated, requestId } = await getData();

    salus = requestId;
    visitorId = visitorIdGenerated;
  }

  const secretKey = `${SecretKeyPayload}${visitorId}`;
  const withoutPasswordEncryptionUrls = ["/business_users/accept_invitation"];
  const isWithoutPasswordEncryption =
    withoutPasswordEncryptionUrls.includes(url);

  if (password) {
    if (!isWithoutPasswordEncryption) {
      payload = encryptedPasswordFunc(payload, visitorId);
    }

    if (!hasPin) return payload;
  }

  const toEncrypt = (obj: Obj) => encryptPinPayload(obj, secretKey, salus);

  if (!hasNestedPin) return toEncrypt(payload);

  if (payload?.data) return { data: toEncrypt(payload?.data) };

  return toEncrypt(payload);
};
