import { noCase } from "change-case";
import React, { useState } from "react";
import { NakedSearchInput } from "../../../../../../../components/inputs";
import { useConstants } from "../../../../../../../contexts/ConstantsContext/parent";
import { SimpleListQuery } from "./card";

export const EWalletFilterModalBank = ({ activeObj }) => {
  const { name } = activeObj || {};
  const [text, setText] = useState("");
  const { localTransferBanks, loadingTransferBanks } = useConstants();

  const banks = localTransferBanks
    .map(({ value, ...rest }) => ({
      ...rest,
      value: String(value),
    }))
    .filter((item) => {
      if (!text) return true;
      const { label } = item || {};
      if (noCase(label).includes(noCase(text))) return true;
      return false;
    });

  return (
    <div className="w-100">
      <NakedSearchInput
        onChange={(text) => setText(text)}
        styleInput={{ marginBottom: 16 }}
        placeholder="Search bank"
      />
      <SimpleListQuery
        array={banks}
        tableHeight={330}
        loading={loadingTransferBanks}
        name={name}
      />
    </div>
  );
};
