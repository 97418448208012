import bcaIcon from "../assets/images/bca-icon.svg";
import bniIcon from "../assets/images/bni-icon.svg";
import briIcon from "../assets/images/bri-icon.svg";
import danamonIcon from "../assets/images/danamon-icon.svg";
import mandiriIcon from "../assets/images/mandiri-icon.svg";

export const bankLists = [
  {
    bank: "BCA",
    name: "PT Indo Koala Remittance",
    number: "5230393933",
    icon: bcaIcon,
  },
  {
    bank: "BNI",
    icon: bniIcon,
    name: "PT. Indo Koala Remittance",

    number: "3001030023",
  },
  {
    bank: "BRI",
    icon: briIcon,
    name: "PT. Indo Koala Remittance",
    number: "042701000961305",
  },
  {
    bank: "Danamon",
    name: "PT. Indo Koala Remittance",
    icon: danamonIcon,
    number: "003628468310",
  },
  {
    bank: "Mandiri",
    icon: mandiriIcon,
    name: "PT. Indo Koala Remittance",
    number: "1290007394089",
  },
];
