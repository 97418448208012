import React, { useEffect, useState } from "react";
import { colors } from "../assets/colors";

const useDefaultHooks = (counter, perPage, isQuick) => {
  const newCounter = counter % perPage;
  const timer = isQuick ? 25 * newCounter : 100 * newCounter;

  const [show, setShow] = useState(false);
  useEffect(() => {
    const showTimeout = setTimeout(() => setShow(true), timer);

    return () => clearTimeout(showTimeout);
  }, []);

  return show;
};

export const TransitionDiv = ({
  counter = 0,
  perPage = 10,
  style,
  children,
  isQuick = false,
  ...props
}) => {
  const show = useDefaultHooks(counter, perPage, isQuick);

  return (
    <div
      style={{
        opacity: show ? 1 : 0,
        transition: "all 0.4s linear",
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export const TransitionTr = ({
  counter = 0,
  perPage = 10,
  style,
  children,
  isQuick = false,
  isSelected,
  ...props
}) => {
  if (isQuick)
    return (
      <tr
        style={{
          opacity: 1,
          transition: "all 0.4s linear",
          backgroundColor: isSelected && colors.neutral500,
          ...hideBorder,
          ...style,
        }}
        {...props}
      >
        {children}
      </tr>
    );

  const show = useDefaultHooks(counter, perPage, isQuick);

  const hideBorder = show ? {} : { borderWidth: 0 };

  return (
    <tr
      style={{
        opacity: show ? 1 : 0,
        transition: "all 0.4s linear",
        backgroundColor: isSelected && colors.neutral500,
        ...hideBorder,
        ...style,
      }}
      {...props}
    >
      {children}
    </tr>
  );
};
