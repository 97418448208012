import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { NakedSearchInput } from "../../../../../../../components/inputs";
import { GothamMedium } from "../../../../../../../components/Text";
import { arraySplitter } from "../../../../../../../components/tools";
import { EWalletFilterModalContentStatus } from "./status";

export const CreatedByRmb = ({ activeObj }) => {
  const { query, push } = useRouter();

  return (
    <div className="w-100 h-100">
      <NakedSearchInput
        placeholder="Search User"
        onChange={(text) => {
          push({ query: { ...query, created_by: text } });
        }}
        styleInput={{ marginBottom: 12 }}
      />
    </div>
  );
};
