import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { ToasterHook } from "contexts/ToasterContext";
import React, { useState } from "react";
import { payloadMutationMaker } from "./payload";

export interface UseMutationParams<R> {
  type?: string;
  defaultValue?: R;
  method?: "put" | "post" | "patch" | "delete";
  url: string;
  resultFormatter?: (data: any, prev?: R, payload?: any) => R;
  afterSuccess?: (
    result: R,
    payload: any,
    response: AxiosResponse<any>,
    value: any
  ) => Promise<void> | void;
  withError?: boolean;
  handleError?: (
    error: any,
    setResult: React.Dispatch<React.SetStateAction<R>>
  ) => void;
  defaultLoading?: boolean;
  beforeApiCall?: (payload: any) => Promise<void> | void;
}

interface UseMutationParamsLocal<R> extends UseMutationParams<R> {
  strapi: AxiosInstance;
  apiBusinessLong: AxiosInstance;
  apiBusinessV2: AxiosInstance;
  apiBusiness: AxiosInstance;
}

export const useMutationRaw = <
  P extends Record<string, any> = Record<string, any>,
  R = any
>({
  type,
  defaultValue = {} as R,
  method = "put",
  url,
  resultFormatter = (data) => data as R,
  afterSuccess,
  withError = true,
  handleError,
  defaultLoading = false,
  beforeApiCall = () => {},
  strapi,
  apiBusinessLong,
  apiBusinessV2,
  apiBusiness,
}: UseMutationParamsLocal<R>) => {
  const apiDecider = (type?: string) => {
    if (type == "axios") return axios;
    if (type == "strapi") return strapi;
    if (type == "long") return apiBusinessLong;
    if (type == "v2") return apiBusinessV2;
    return apiBusiness;
  };
  const [loading, setLoading] = useState(defaultLoading);
  const [result, setResult] = useState<R>(defaultValue);

  const { errorToasterApi, errorToaster } = ToasterHook();
  const { getData } = useVisitorData(
    { ignoreCache: true },
    { immediate: false }
  );

  const mutation = async (value: P) => {
    try {
      setLoading(true);

      const payloadMutation = await payloadMutationMaker({
        payload: value,
        getData,
        method,
        url,
      });

      if (typeof payloadMutation === "string") {
        errorToaster("", payloadMutation);
        return;
      }

      const api = apiDecider(type);
      await beforeApiCall(payloadMutation);
      const response = await api[method](url, payloadMutation);
      const result = resultFormatter(response);

      setResult((prev) => resultFormatter(response, prev, value));

      if (typeof afterSuccess === "function") {
        await afterSuccess(result, value, response, value);
      }
    } catch (error) {
      handleError && handleError(error, setResult);

      if (withError) errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, mutation, result, setResult };
};
