import "@material/react-text-field/dist/text-field.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { urlDecider } from "tools/api";
import ErrorBoundary from "../errorBoundaries";
import SEOComponents from "../pageComponents/script/seo";
import "../styles/badge.css";
import "../styles/button.css";
import "../styles/draftjs.css";
import "../styles/globals.css";
import "../styles/icon.css";
import "../styles/input.css";
import "../styles/modals.css";
import "../styles/sidebar.css";
import "../styles/slick.css";
import "../styles/summarydetails.css";
import "../styles/table.scss";
import "../styles/text.css";
import "../styles/toaster.css";
import "../styles/tooltip.css";
import "../styles/transition.css";
import { initEvents } from "../universalFunctions/events";
import dynamic from "next/dynamic";
import { mainDownloadURL } from "tools/api/functions/interceptors/url";
import { useWindowDimension } from "../contexts/DimensionContext";

// Dynamic imports for Provider components
const FpjsProvider = dynamic(() =>
  import("@fingerprintjs/fingerprintjs-pro-react").then(
    (mod) => mod.FpjsProvider
  )
);
const GuidedTourProvider = dynamic(() =>
  import("contexts/GuidedTourContext/typescript").then((mod) => mod.default)
);
const AlertAttentionProvider = dynamic(() =>
  import("../contexts/AlertAttentionContext").then(
    (mod) => mod.AlertAttentionProvider
  )
);
const AlertContextProvider = dynamic(() =>
  import("../contexts/AlertContext/parent").then(
    (mod) => mod.AlertContextProvider
  )
);
const AuthProvider = dynamic(() =>
  import("../contexts/AuthContext").then((mod) => mod.AuthProvider)
);
const ConstantsProvider = dynamic(() =>
  import("../contexts/ConstantsContext/parent").then(
    (mod) => mod.ConstantsProvider
  )
);
const DimensionProvider = dynamic(() =>
  import("../contexts/DimensionContext").then((mod) => mod.DimensionProvider)
);
const FilePreviewProvider = dynamic(() =>
  import("../contexts/FilePreviewContext/parent").then(
    (mod) => mod.FilePreviewProvider
  )
);
const GoogleDrivePreviewProvider = dynamic(() =>
  import("../contexts/GoogleDrivePreviewContext/parent").then(
    (mod) => mod.GoogleDrivePreviewProvider
  )
);
const InvoiceProvider = dynamic(() =>
  import("../contexts/InvoiceContext").then((mod) => mod.InvoiceProvider)
);
const LayoutProvider = dynamic(() =>
  import("../contexts/Layout/parent").then((mod) => mod.LayoutProvider)
);
const TaskProvider = dynamic(() =>
  import("../contexts/TaskContext/parent").then((mod) => mod.default)
);
const TimerProvider = dynamic(() =>
  import("../contexts/TimerContext").then((mod) => mod.TimerProvider)
);
const ToasterProvider = dynamic(() =>
  import("../contexts/ToasterContext").then((mod) => mod.ToasterProvider)
);
const TranslationProvider = dynamic(() =>
  import("../contexts/TranslationContext/parent").then((mod) => mod.default)
);

const OpenReplay = ({ children }: { children: React.ReactNode }) => {
  // whats this?
  // Its a feature for monitoring, thats more powerful than sentry (u can monitors user behavior step by step here)

  // Database: https://openreplay.transfez-jack-engineering.xyz => asks devops for permission (giri)

  // how to debug on localhost:
  // 1. uncomment this __DISABLE_SECURE_MODE
  // 2. isActive => change to true
  // 3. Manual refresh (no hot reload)

  // --- IMPORTANT
  // `sanitizer` for network is the only tricky part here. (on html => network => sanitizer)
  // It filters out sensitive data (like pin or password), go to this code if u want to add filter

  const activeApi = urlDecider(mainDownloadURL);
  const __html = `
  var initOpts = {
    projectKey: "F2PiKJYotrOnL8UEooQ1",
    defaultInputMode: 2,
    obscureTextNumbers: true,
    obscureTextEmails: true,
    // __DISABLE_SECURE_MODE: true,
    network: {
      capturePayload: true,
      ignoreHeaders: true,
      sanitizer: (res) => {
         try {
          // Ensure the request body is a string before parsing

          const isOwnApi = res.url.includes("${activeApi}");

          const isString = typeof res.request.body === "string";

          if (!isOwnApi || !isString) return res;

          const rawBody = res.request.body;
          const rawObj = JSON.parse(rawBody) || {};

          let obj = {};

          const keys = Object.keys(rawObj);

          keys.forEach((key) => {
            const isPassword = key.toLowerCase().includes("pass");
            const isPin = key.toLowerCase().includes("pin");

            if (isPassword || isPin) {
              obj[key] = "****"; // Mask the sensitive data
            } else {
              obj[key] = rawObj[key];
            }
          });

          // Re-assign the sanitized object back to the request body
          res.request.body = JSON.stringify(obj);
          return res;
        } catch (error) {
          // Handle parsing errors
          console.error("Error in sanitizer function: ", error);
          return res;
        }
      },
    },
  };
  var startOpts = { userID: "" };
  (function(A,s,a,y,e,r){
    r=window.OpenReplay=[e,r,y,[s-1, e]];
    s=document.createElement('script');s.src=A;s.async=!a;
    document.getElementsByTagName('head')[0].appendChild(s);
    r.start=function(v){r.push([0])};
    r.stop=function(v){r.push([1])};
    r.setUserID=function(id){r.push([2,id])};
    r.setUserAnonymousID=function(id){r.push([3,id])};
    r.setMetadata=function(k,v){r.push([4,k,v])};
    r.event=function(k,p,i){r.push([5,k,p,i])};
    r.issue=function(k,p){r.push([6,k,p])};
    r.isActive=function(){return false};
    r.getSessionToken=function(){};
  })("//static.openreplay.com/latest/openreplay.js",1,0,initOpts,startOpts);
`;
  const { isActiveOpenReplay } = useWindowDimension();

  const isActive =
    !["localhost"].find((string) => window.location.href.includes(string)) &&
    isActiveOpenReplay;
  // const isActive = true;
  return (
    <>
      {isActive && (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html,
          }}
        />
      )}
      {children}
    </>
  );
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(initEvents, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;

    const isInternal = window.location.href.includes("internal");

    const obj = isInternal ? {} : { swPath: "/serviceworker.js", swScope: "/" };

    const config = {
      app_id: `${process.env.NEXT_PUBLIC_MOENGAGE_APP_ID}`,
      cluster: "DC_1",
      enableSPA: true,
      debug_logs: 0,
      ...obj,
    };

    if (typeof window !== "undefined") {
      import("@moengage/web-sdk")
        .then((MoEngage) => MoEngage.default.initialize(config))
        .catch((err) => console.error("MoEngage SDK loading failed", err));
    }
  }, []);

  const tagManagerArgs = { gtmId: "GTM-567HLBD" };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const { locale } = useRouter();

  useEffect(() => {
    if (!locale) return;
    localStorage.setItem("locale", locale);
  }, [locale]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/serviceworker.js")
        .then((registration) => console.log("scope is: ", registration.scope));
    }
  }, []);

  return (
    <FpjsProvider
      loadOptions={{
        apiKey: String(process.env.NEXT_PUBLIC_FINGERPRINT),
        endpoint: ["https://ZhQbh9K4tf9t.itsjack.com", { default: "endpoint" }],
        scriptUrlPattern: [
          "https://ZhQbh9K4tf9t.itsjack.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          "https://fpnpmcdn.net/v<version>/<apiKey>/loader_v<loaderVersion>.js",
        ],
        region: "ap",
      }}
    >
      <TranslationProvider>
        <DimensionProvider>
          <ToasterProvider>
            <AuthProvider>
              <AlertContextProvider>
                <InvoiceProvider>
                  <AlertAttentionProvider>
                    <TimerProvider>
                      <ConstantsProvider>
                        <GoogleDrivePreviewProvider>
                          <TaskProvider>
                            <FilePreviewProvider>
                              <SEOComponents />
                              <LayoutProvider>
                                <ErrorBoundary>
                                  <GuidedTourProvider>
                                    <OpenReplay>
                                      <Component {...pageProps} />
                                    </OpenReplay>
                                  </GuidedTourProvider>
                                </ErrorBoundary>
                              </LayoutProvider>
                            </FilePreviewProvider>
                          </TaskProvider>
                        </GoogleDrivePreviewProvider>
                      </ConstantsProvider>
                    </TimerProvider>
                  </AlertAttentionProvider>
                </InvoiceProvider>
              </AlertContextProvider>
            </AuthProvider>
          </ToasterProvider>
        </DimensionProvider>
      </TranslationProvider>
    </FpjsProvider>
  );
};

export default appWithTranslation(MyApp);
