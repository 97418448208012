import _ from "lodash";
import { universeFunc } from "../../components/RecipientDetailsModal";
import { titleCase } from "change-case";

export const UniverseDataGenerator = ({ data, t = () => {}, isBahasa = false }) => {
  const bankName = universeFunc("bank_name", data);
  const receivingRegisteredName = universeFunc(
    "receiving_registered_name",
    data
  );
  const registeredName = universeFunc("registered_name", data);
  const address = universeFunc("receiving_address", data) || universeFunc("address", data);
  const phoneNumber = universeFunc("MSISDN", data);
  const receivingFirstName = universeFunc("receiving_first_name", data);
  const receivingLastName = universeFunc("receiving_last_name", data);
  const firstName = universeFunc("firstname", data);
  const lastName = universeFunc("lastname", data);
  const bankAccountName = universeFunc("bank_account_name", data);
  const bankAccountNumber = universeFunc("bank_account_number", data);
  const ibanNumber = universeFunc("iban", data);
  const swiftCode = universeFunc("swift_bic_code", data);
  const recipientEmail = universeFunc("recipient_email", data);
  const abaRoutingNumber = universeFunc("aba_routing_number", data);
  const companySocialCreditCode = universeFunc("company_social_credit_code", data);
  const postalCode = universeFunc("postal_code", data);
  const city = universeFunc("city", data);
  const sortCode = universeFunc("sort_code", data);
  const userName =
    receivingRegisteredName ||
    registeredName ||
    `${receivingFirstName || firstName} ${receivingLastName || lastName}`;

  const CapitalizeTranslation = (key) => _.capitalize(t(titleCase(key)));

  const personalInformation = [
    ...(userName ? [{ title: CapitalizeTranslation("Name"), value: userName }] : []),
    ...(recipientEmail ? [{ title: CapitalizeTranslation("Email"), value: recipientEmail }] : []),
    ...(phoneNumber ? [{ title: CapitalizeTranslation("Phone number"), value: phoneNumber }] : []),
    ...(address ? [{ title: CapitalizeTranslation("Address"), value: address }] : []),
    ...(postalCode ? [{ title: CapitalizeTranslation("Postal Code"), value: postalCode }] : []),
    ...(city ? [{ title: CapitalizeTranslation("City"), value: city }] : []),
    ...(companySocialCreditCode ? [{ title: "Company Social Credit Code (USCC)", value: companySocialCreditCode }] : []),
  ];
  
  const bankInformation = [
    ...(bankName ? [{ title: CapitalizeTranslation("Bank name"), value: bankName }] : []),
    ...(bankAccountName ? [{ title: CapitalizeTranslation("Account name"), value: bankAccountName }] : []),
    ...(bankAccountNumber ? [{ title: CapitalizeTranslation("Account number"), value: bankAccountNumber }] : []),
    ...(ibanNumber ? [{ title: CapitalizeTranslation("IBAN"), value: ibanNumber }] : []),
    ...(swiftCode ? [{ title: isBahasa ? "Kode SWIFT/BIC" : "SWIFT/BIC code", value: swiftCode }] : []),
    ...(abaRoutingNumber ? [{ title: CapitalizeTranslation("ABA routing number"), value: abaRoutingNumber }] : []),
    ...(sortCode ? [{ title: CapitalizeTranslation("Sort code"), value: sortCode }] : []),
  ];  

  const universeData = [...personalInformation, ...bankInformation];

  return { universeData, bankInformation, personalInformation };
};
