import { snakeCase } from "change-case";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { pickBy } from "lodash";
import mixpanel from "mixpanel-browser";
import { getUserRoleBooleans } from "../contexts/AuthContext";
import * as Sentry from "@sentry/nextjs";
import { decryptToken } from "tools/api/functions/hash";

const isProduction = process.env.NEXT_PUBLIC_TRANSFEZ_APP_ENV == "PRODUCTION";
const isStaging = process.env.NEXT_PUBLIC_TRANSFEZ_APP_ENV == "STAGING";

export const initEvents = () => {
  initsMixpanel();
  initsFirebase();
};

const initsMixpanel = () => {
  const key = isProduction
    ? "26c0d29e656bc08045814bb50e4714d7"
    : "a1f98ddc14d5e6e22cc32efb68938b1f";
  const debug = isProduction ? false : true;
  mixpanel.init(key, { debug });
};

const firebaseConfig = isProduction
  ? {
      apiKey: "AIzaSyCp6Hz3X9XY3c0MBo3Bj3x-xtf5NhPNPNs",
      authDomain: "transfez-b2b-production.firebaseapp.com",
      projectId: "transfez-b2b-production",
      storageBucket: "transfez-b2b-production.appspot.com",
      messagingSenderId: "431457978503",
      appId: "1:431457978503:web:a6ef567b0a29707d411022",
      measurementId: "G-637KMH86VM",
    }
  : {
      apiKey: "AIzaSyBtBAqBkzLGc3rgX7dkxtwMD9vLJcHUswI",
      authDomain: "transfez-b2b-staging.firebaseapp.com",
      projectId: "transfez-b2b-staging",
      storageBucket: "transfez-b2b-staging.appspot.com",
      messagingSenderId: "1041904216143",
      appId: "1:1041904216143:web:1068c8ac4379b332c0a23a",
      measurementId: "G-JPGG63QT2J",
    };

const initsFirebase = () => initializeApp(firebaseConfig);

const firebaseAnalytics = () => {
  const app = initsFirebase();
  const analytics = getAnalytics(app);
  return analytics;
};

export const eventsTracker = async (eventName, object = {}) => {
  let obj = pickBy(object, (_, key) => key !== "token");

  if (!!localStorage.getItem("user")) {
    const user = JSON.parse(decryptToken(localStorage.getItem("user"))) || {};

    const { partner, email: global_email, name: global_user_name } = user || {};

    const { name: global_business_name } = partner || {};
    const userProps = {
      global_business_name,
      global_email,
      global_user_name,
    };

    obj = { ...obj, ...userProps };
  }

  const event_name = snakeCase(eventName);
  // dont remove
  !isProduction && console.log("event_name:", event_name);
  // dont remove

  logEvent(firebaseAnalytics(), event_name, obj);
  // const { Moengage } = window;
  const { default: moengage } = await import("@moengage/web-sdk");

  isProduction && moengage.track_event(event_name, obj);

  setTimeout(() => mixpanel.track(event_name, obj), 200);
};

const userProps = (obj) => {
  const { email, name, partner, id } = obj || {};
  const { phone, name: business_name } = partner || {};
  const [first_name, ...last_names] = name.split(" ");
  const last_name = last_names.join(" ");
  const { roleName: role } = getUserRoleBooleans(obj);

  return { email, name, id, phone, first_name, last_name, role, business_name };
};

const setMixpanelUser = (obj) => {
  const { id, email, ...props } = userProps(obj);

  let result = props;

  result["$email"] = email;

  mixpanel.identify(id);

  mixpanel.people.set(result);
};

const setMoengageUser = async (obj) => {
  try {
    const props = userProps(obj);
    const { email, first_name, id, last_name, name, phone } = props || {};
    const { role, team } = obj || {};
    const { name: team_name } = team || {};

    const additionObj = pickBy({ role, team_name }, (val) => val);

    if (!isProduction && !isStaging) return;

    const { default: moengage } = await import("@moengage/web-sdk");

    if (!moengage.add_first_name) return;

    await moengage.add_unique_user_id(id);
    moengage.add_first_name(first_name);
    moengage.add_last_name(last_name);
    moengage.add_email(email);
    moengage.add_mobile(phone);
    moengage.add_user_name(name);

    Object.keys(additionObj).forEach((key) => {
      const value = additionObj[key];
      moengage.add_user_attribute(key, value);
    });
  } catch (error) {
    console.log("error moengage:", error);
  }
};

export const setUserEvents = (obj) => {
  const result = {
    ...obj,
    date: new Date(),
    business_name: obj?.partner?.name,
  };

  setUserProperties(firebaseAnalytics(), result);

  setMoengageUser(result);

  setMixpanelUser(result);

  const { email, id, partner } = obj;

  window?.OpenReplay?.setUserID(email);
  isProduction && Sentry.setUser({ email, id, username: partner?.name });
};

export const logoutEvents = (user) => {
  // const { Moengage } = window;
  import("@moengage/web-sdk").then((MoEngage) => {
    const moengage = MoEngage.default;
    (isProduction || isStaging) && moengage.destroy_session();
  });

  mixpanel.reset();
};
