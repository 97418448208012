import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const ChangeLocaleButton = ({ children = <></>, path = "/" }) => {
  const router = useRouter();
  return (
    <>
      <Link href={path} locale={router.locale === "en" ? "id" : "en"}>
        {/* {<button>{`change locale: ${router.locale}`}</button>} */}
        {children}
      </Link>
    </>
  );
};

export const useLanguage = () => {
  const { locale } = useRouter();
  const isEnglish = locale == "en";
  const isBahasa = locale == "id";
  return { isEnglish, isBahasa };
};

export const DebugTranslationFloating = () => {
  const { i18n } = useTranslation();
  const [position, setPosition] = useState({ x: 20, y: 20 });
  const [dragging, setDragging] = useState(false);
  const [rel, setRel] = useState(null); // Position relative to the cursor

  const { asPath, push, pathname, query, locale } = useRouter();
  // console.log("useRouter():", useRouter());

  const handleMouseDown = (e) => {
    // Check if the target is the container div
    if (e.target.id !== "floating-container") return;
    const boundingRect = e.target.getBoundingClientRect();
    setRel({
      x: e.pageX - boundingRect.left,
      y: e.pageY - boundingRect.top,
    });
    setDragging(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleMouseUp = (e) => {
    setDragging(false);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    setPosition({
      x: e.pageX - rel.x,
      y: e.pageY - rel.y,
    });
    e.stopPropagation();
    e.preventDefault();
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    push({ pathname, query }, asPath, { locale: value });
  };

  const floatingContainerStyle = {
    position: "fixed",
    top: `${position.y}px`,
    left: `${position.x}px`,
    backgroundColor: "#f9f9f9",
    border: "1px solid #ddd",
    borderRadius: "16px",
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    cursor: "move", // Change cursor to indicate draggable
  };

  const labelStyle = {
    display: "block",
    textAlign: "center",
    marginBottom: "10px",
    fontSize: "14px",
    color: "#333",
  };

  const selectStyle = {
    width: "100%",
    padding: "10px",
    fontSize: "18px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    outline: "none",
    transition: "border-color 0.3s",
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  return (
    <div
      id="floating-container"
      style={floatingContainerStyle}
      onMouseDown={handleMouseDown}
    >
      <label htmlFor="language-select" style={labelStyle}>
        DRAG ME!
      </label>
      <select
        id="language-select"
        style={selectStyle}
        onChange={handleSelectChange}
        defaultValue={locale}
      >
        <option value="en">EN</option>
        <option value="id">ID</option>
      </select>
    </div>
  );
};
