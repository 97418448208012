import { useLanguage } from "public/locales/translationFunctions";
import {
  applyRegexAlphanumeric,
  applyRegexAlphanumericSpecificSymbol,
  applyRegexAlphanumericWithoutSymbols,
  applyRegexOnlyAlphabet,
  applyRegexOnlyNumbers,
  windowDimension,
  applyRegexAlphanumericAndDash,
} from "../../../../../components/tools";
import { CurrencyFieldBoolean, FieldBoolean } from "./boolean";
import {
  fieldBankDetails,
  fieldRecipientDetails,
  fieldSelection,
  fieldTextArea,
  gbpAccNumberFields,
  gbpIbanFields,
} from "./constant";
import { LabelDecider, PlaceHolderDecider } from "./masking";

export const AddRecipientValidator = ({
  useFormObj,
  transaction,
  t = () => {},
  isBahasa,
}) => {
  const { setError, watch } = useFormObj;
  const { currency } = transaction?.destination_country || {};
  const watchObj = watch();
  const keys = Object.keys(watchObj);

  let invalid = false;

  for (let key of keys) {
    const value = watchObj[key];
    const { isNotRequiredField } = FieldBoolean(key);
    const { error: errorMsgMaxLength, isError: isErrorMaxLength } =
      ErrorMaxLengthField({ key, value, t, isBahasa });

    if (!value && !isNotRequiredField) {
      setError(key, {
        message: t(`Please fill out this field`),
      });
      invalid = true;
    }
    if (!!value && isErrorMaxLength) {
      setError(key, { message: errorMsgMaxLength });
      invalid = true;
    }
  }
  return invalid;
};

export const ErrorMaxLengthField = ({ key, value, t = () => {}, isBahasa }) => {
  let error = "";

  const {
    isIBAN,
    isSwiftBIC,
    isAbaRoutingNumber,
    isSortCode,
    isIfsCode,
    isCnaps,
  } = FieldBoolean(key);

  const lengthVal = value?.length;

  const minMaxLengthField = isIBAN || isSwiftBIC;
  const exactLengthField =
    isAbaRoutingNumber || isSortCode || isIfsCode || isCnaps;

  const MinMaxLengthFunc = () => {
    if (isIBAN) return { min: 16, max: 34 };
    if (isSwiftBIC) return { min: 8, max: 11 };
    if (isAbaRoutingNumber) return { exact: 9 };
    if (isSortCode) return { exact: 6 };
    if (isIfsCode) return { exact: 11 };
    if (isCnaps) return { exact: 12 };
    return {};
  };

  const { min, max, exact } = MinMaxLengthFunc();

  const minMaxErrorString = isBahasa
    ? `harus diisi ${min} hingga ${max} karakter.`
    : `must be ${min} to ${max} digits long.`;
  const exactErrorString = isBahasa
    ? `harus diisi ${exact} karakter.`
    : `must be ${exact} digits long.`;

  if (minMaxLengthField) {
    if (lengthVal < min || lengthVal > max)
      error = `${LabelDecider(key, "", t)} ${minMaxErrorString}`;
  }
  if (exactLengthField) {
    if (lengthVal !== exact)
      error = `${LabelDecider(key, "", t)} ${exactErrorString}`;
  }

  const isError = !!error.length;

  return { error, isError };
};

export const SubmitFormatterAddRecipient = ({ value, transaction }) => {
  const { isB2B, destination_country } = transaction || {};
  const { country_id } = destination_country || {};

  // bank formatter
  const {
    id_type: idType,
    gender: genderRaw,
    bank_id: bankIdRaw,
    account_type: accountType,
    country_iso_code: countryIsoCode,
    nationality_iso_code: nationalityIsoCode,
    branch_number: branchNumber,
    ...rest
  } = value || {};
  const { iso_3: countryIso3, name: countryName } = countryIsoCode || {};
  const { iso_3: nationalityIso3, name: nationalityName } =
    nationalityIsoCode || {};
  const { id: bank_id, name: bank_name } = bankIdRaw || {};
  const { value: account_type } = accountType || {};
  const { value: id_type } = idType || {};
  const { value: gender } = genderRaw || {};
  const { branch_code } = branchNumber || {};

  // recipient type - Mandatory
  const recipient_type = isB2B ? "Business" : "personal";

  const universe_data = {
    account_type,
    gender,
    id_type,
    bank_id,
    bank_name,
    country_iso_code: countryIso3,
    country: countryName,
    nationality_iso_code: nationalityIso3,
    nationality: nationalityName,
    branch_number: branch_code,
    ...rest,
  };

  const result = {
    user_recipient: {
      recipient_type,
      country_id,
      universe_data,
    },
  };

  return result;
};

export const useResolutionAddRecipient = ({ isModal }) => {
  const { width } = windowDimension();
  const is544OrBelow = width <= 560;
  const widthParent = isModal
    ? is544OrBelow
      ? (width * 19) / 20
      : 544
    : "100%";

  return { widthParent };
};

export const FieldsParentDataFormatter = ({
  fields: fieldsRaw,
  isCurrencyWithToggle = false,
  isToggleLeftActive,
  isDynamicChecker,
}) => {
  if (!fieldsRaw) return [];

  // add optional fields
  const optional_fields = ["recipient_email"];
  const combineFields = isDynamicChecker
    ? [...fieldsRaw]
    : [...fieldsRaw, ...optional_fields];

  // filtering fields based on type input
  const filteringFields = (arrayConstant) =>
    combineFields?.filter((item) => arrayConstant.includes(item));

  // decider fields
  const FieldsDecider = ({ isBankDetails = false, isCombineArray = false }) => {
    if (isCurrencyWithToggle && isBankDetails) {
      if (isCombineArray) return filteringFields(fieldBankDetails);
      if (isToggleLeftActive) return filteringFields(gbpAccNumberFields);
      return filteringFields(gbpIbanFields);
    }
    if (isBankDetails) return filteringFields(fieldBankDetails);
    return filteringFields(fieldRecipientDetails);
  };

  const data = [
    {
      title: "Recipient Details",
      fields: FieldsDecider({ isBankDetails: false }),
    },
    {
      title: "Bank Details",
      fields: FieldsDecider({ isBankDetails: true }),
      combineFields: FieldsDecider({
        isBankDetails: true,
        isCombineArray: isCurrencyWithToggle,
      }),
    },
  ];

  return data;
};

export const FieldTypeGenerator = (field) => {
  const isSelection = fieldSelection.includes(field);
  const isTextArea = fieldTextArea.includes(field);
  return { isSelection, isTextArea };
};

export const ResetToggleFields = (useFormObj, fields) => {
  const { unregister, setValue } = useFormObj;
  fields.map((field) => {
    setValue(field, "");
    unregister(field);
  });
};

export const InputDefaultProps = ({
  field,
  index,
  useFormObj,
  transaction,
  t = () => {},
}) => {
  const { destination_country } = transaction || {};
  const { currency } = destination_country || {};

  const { isIBAN, isSwiftBIC } = FieldBoolean(field);
  const label = LabelDecider(field, currency, t);
  const placeholder = PlaceHolderDecider(field, label, t);

  const inputDefaultProps = {
    key: isIBAN ? 100 : isSwiftBIC ? 99 : index,
    name: field,
    useFormObj,
    label,
    placeholder,
    transaction,
  };

  return inputDefaultProps;
};

export const DependentFieldProps = ({ useFormObj, transaction, name }) => {
  const { watch } = useFormObj;

  const { bank_id } = watch();
  const bankName = bank_id?.label;

  const { currency } = transaction?.destination_country || {};
  const { isJPY } = CurrencyFieldBoolean(currency);
  const { isBranchNumber } = FieldBoolean(name);

  const isBranchNumberJapan = isJPY && isBranchNumber;
  const isDisabledBranchNumber = isBranchNumberJapan && !bankName;

  return { isBranchNumberJapan, bankName, isDisabledBranchNumber };
};

export const ListenerRegexMaxLengthFields = ({ useFormObj }) => {
  const { watch, setValue } = useFormObj;
  const watchObj = watch();

  const keys = Object.keys(watchObj);

  for (let key of keys) {
    const value = watchObj[key];

    // regex field
    const getRegexFunc = RegexFuncDecider(key);
    const valueAfterRegex = getRegexFunc(value || "");

    // max length field listener (when input)
    const valueAfterSlicing = MaxLengthField({
      field: key,
      value: valueAfterRegex,
    });

    setValue(key, valueAfterSlicing);
  }
};

const RegexFuncDecider = (field) => {
  const {
    isAccountNumber,
    isMSISDN,
    isPostalCode,
    isTaxId,
    isIdNumber,
    isRegisteredName,
    isFirstName,
    isLastName,
    isProvince,
    isCity,
    isRegion,
    isAccountName,
    isSwiftBIC,
    isIBAN,
    isRoutingCode,
    isAbaRoutingNumber,
    isSortCode,
    isIfsCode,
    isCnaps,
    isAddress,
    isRecipientEmail,
    isCompanySocialCreditCode,
  } = FieldBoolean(field);

  const fieldAlphanumeric = isRegisteredName; // || isSwiftBIC || isIBAN || isIfsCode || isAccountNumber;

  const fieldAlphanumericWithoutSpace =
    isSwiftBIC ||
    isIBAN ||
    isIfsCode ||
    isAccountNumber ||
    isCompanySocialCreditCode;

  const fieldOnlyNumber =
    isMSISDN ||
    isPostalCode ||
    isTaxId ||
    isIdNumber ||
    isRoutingCode ||
    isAbaRoutingNumber ||
    isSortCode ||
    isCnaps;

  const fieldAlphabet =
    isFirstName ||
    isLastName ||
    isCity ||
    isProvince ||
    isRegion ||
    isAccountName;

  const fieldAlphanumericSpecificSymbol = isAddress || isRecipientEmail;
  const fieldAlphanumericAndDash = isCompanySocialCreditCode;

  if (fieldOnlyNumber) return applyRegexOnlyNumbers;
  if (fieldAlphanumeric) return applyRegexAlphanumeric;
  if (fieldAlphanumericWithoutSpace)
    return applyRegexAlphanumericWithoutSymbols;

  if (fieldAlphabet) return applyRegexOnlyAlphabet;
  if (fieldAlphanumericSpecificSymbol)
    return applyRegexAlphanumericSpecificSymbol;
  if (fieldAlphanumericAndDash) return applyRegexAlphanumericAndDash;
  return (value) => value;
};

const MaxLengthField = ({ field, value: valueRaw }) => {
  const { isAccountNumber, isCompanySocialCreditCode } = FieldBoolean(field);

  const maxLengthAlphaNumeric = isAccountNumber;

  const MaxLengthDecider = () => {
    if (isCompanySocialCreditCode) return 18;
    if (maxLengthAlphaNumeric) return 34;
  };

  const maxLength = MaxLengthDecider();
  const value =
    valueRaw?.length > maxLength ? valueRaw.slice(0, maxLength) : valueRaw;

  return value;
};
