import {
  createTheme,
  TextareaAutosize,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { noCase, sentenceCase, titleCase, upperCase } from "change-case";
import { getMonth, getYear } from "date-fns";
import { get, isEmpty, range } from "lodash";
import { isArray } from "lodash";
import { useRouter } from "next/router";
import * as Papa from "papaparse";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Search } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import Select, { components } from "react-select";
import { Col, Modal, Row } from "reactstrap";
import { colors, jackColors } from "../assets/colors";
import dateIcon from "../assets/images/date.svg";
import leftArrow from "../assets/images/left-arrow-pagination.svg";
import rightArrow from "../assets/images/right-arrow-pagination.svg";
import uploadBlueIcon from "../assets/images/upload-icon-b19.svg";
import xIcon from "../assets/images/x-upload.svg";
import { JackIcons } from "../assets/jackIcons/parent";
import { phoneSimpleLabelSplitter } from "../assets/phoneCode";
import {
  applyRegexOnlyNumbers,
  formatCurrencyNoDecimal,
  useForm,
} from "../components/tools";
import { ToasterContext } from "../contexts/ToasterContext";
import { EmptyScreenSearchRecipients } from "../pageComponents/crossBorder/components";
import { FileInputCustomizeReceipt } from "./FileInputs";
import { MultipleInputTable } from "./MultipleInputTable";
import {
  GothamMedium,
  GothamRegular,
  OldGothamRegular,
  TextInlineMedium,
  TextInlineRegular,
} from "./Text";
import {
  formatCurrency,
  toBase64,
  useDebounce,
  windowDimension,
} from "./tools";
import { useReactPDF } from "contexts/GoogleDrivePreviewContext/viewer";
import { Trans, useTranslation } from "react-i18next";
import { SpanMedium } from "modals/smartActivityModal/crossBorderSingleModal/transferServiceModal/components";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useMissingValueInTextInput = (currValue) => {
  const [missingValue, setMissingValue] = useState(false);
  const [testing, setTesting] = useState("");
  const [text, setText] = useState("");
  useEffect(() => {
    setText((p) => {
      setTesting((prev) => {
        if (prev && !p) {
          setMissingValue(true);
          return p;
        }
        return p;
      });
      setMissingValue(false);
      return currValue;
    });
  }, [currValue]);
  return missingValue;
};

const labelColorDecider = ({ isActive, isError }) => {
  if (isError) return { border: colors.errorRed, text: colors.errorRed };
  if (isActive) return { border: colors.pink, text: colors.pink };
  return { text: colors.grey72, border: colors.greyC2 };
};

const CustomTextFieldPrefix = ({ prefix }) => {
  if (!prefix) return null;
  return (
    <div className="d-flex align-items-center mr-1">
      <GothamMedium style={{ color: colors.greyC2, marginBottom: 0 }}>
        {prefix}
      </GothamMedium>
    </div>
  );
};
const CustomTextFieldHelp = ({ help }) => {
  if (!help) return null;
  return (
    <h5
      style={{
        color: colors.grey72,
        fontSize: 12,
        lineHeight: "20px",
        marginBottom: 0,
      }}
    >
      {help}
    </h5>
  );
};
export const CustomTextFieldError = ({
  show,
  message,
  errorStyle,
  chillError,
}) => {
  if (!show) return null;
  return (
    <h5
      style={{
        fontFamily: "GothamBook",
        color: chillError ? "black" : colors.errorRed,
        fontSize: 12,
        lineHeight: "20px",
        marginBottom: 0,
        ...errorStyle,
      }}
    >
      {message}
    </h5>
  );
};

// CustomTextFieldRef IS THE SAME AS CustomTextField (but with forwardRef)
const CustomTextFieldRef = forwardRef(
  (
    {
      name = "",
      label = "",
      useFormObj: { register, watch, errors },
      error,
      help,
      className = "",
      disabled: disabledRaw, // same
      isDisabled, // same (used for datepicker)
      containerStyle,
      required = true,
      shrink = false,
      emptyField = "",
      fieldInfo,
      style,
      labelStyle,
      defaultValue,
      fromVA = false,
      prefix = "",
      errorStyle,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const disabled = disabledRaw || isDisabled;

    const currValue = watch(name);

    const hasValue = Boolean(currValue);

    const missingValue = useMissingValueInTextInput(currValue);

    const isActive = isFocused || hasValue || prefix;

    const errorMessage = errors[name]?.message;

    const isError = Boolean(error || errorMessage);

    const shrinkObj = shrink ? { shrink } : {};

    const getLabel = label || titleCase(name);

    const { border: borderColor } = labelColorDecider({
      isActive: isFocused,
      isError,
    });

    const { text: labelColor } = labelColorDecider({
      isActive: isFocused || hasValue,
      isError,
    });
    const errorString = () => {
      if (typeof error == "string") return error;
      if (emptyField) return emptyField;
      if (errorMessage) return sentenceCase(errorMessage);
      return getLabel + " cannot be empty";
    };

    const { ref: inputRef, onChange } = register({ name, required });

    return (
      <div
        style={{ marginBottom: 20, ...containerStyle }}
        className={className}
      >
        <div>
          <TextField
            ref={ref}
            name={name}
            disabled={disabled}
            label={getLabel}
            defaultValue={defaultValue}
            onChange={onChange}
            inputRef={inputRef}
            variant="standard"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            InputProps={{
              disableUnderline: true,
              startAdornment: prefix && (
                <CustomTextFieldPrefix prefix={prefix} />
              ),
              style: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                marginLeft: 8,
                marginRight: 8,
                color: "black",
                fontFamily: "GothamBook",
                fontSize: 14,
                marginTop: 12,
              },
            }}
            style={{
              height: 44,
              backgroundColor: disabled ? colors.greyea : "transparent",
              borderRadius: 8,
              borderWidth: ".5px",
              width: "100%",
              border: `1px solid ${borderColor}`,
              ...style,
            }}
            InputLabelProps={{
              ...shrinkObj,
              style: {
                position: "absolute",
                fontFamily: "GothamBook",
                fontSize: isActive ? 12 : 14,
                left: 8,
                top: missingValue || isActive ? 2 : -10,
                color: labelColor,
                ...labelStyle,
              },
            }}
            {...props}
          />
        </div>
        <CustomTextFieldHelp help={help} />
        <CustomTextFieldError
          show={isError}
          message={errorString()}
          errorStyle={errorStyle}
        />
        <CustomTextFieldError
          show={fieldInfo}
          message={String(fieldInfo)}
          errorStyle={{ color: colors.grey72 }}
        />
      </div>
    );
  }
);
// CustomTextFieldRef IS THE SAME AS CustomTextField (but with forwardRef)

// OVERRIDE MUI

// this small component costs me 5 hrs+.
// we can OVERRIDE MUI's classNames by using theme.
// how to implement:
// 1. Go to your browser and pick the element you want to modify
// 2. See the absurd classes there? Pick the first letters and pray that it is defined by MUI.
// 3. auto complete is your best friend. Check if you can override it.
// 4. Trial and error which key is the correct one. In my case it is `shrink` and `filled`
// 5. enjoy
const theme = createTheme({
  overrides: {
    MuiInputLabel: {
      shrink: {
        marginTop: 14,
      },
    },
    MuiFormLabel: {
      filled: {
        marginTop: 14,
      },
    },
  },
});
// OVERRIDE MUI

export const CustomTextField = ({
  name = "",
  label = "",
  useFormObj: { register, watch, errors },
  error,
  help,
  className = "",
  disabled: disabledRaw, // same
  isDisabled, // same (used for datepicker)
  containerStyle,
  required = true,
  shrink = false,
  emptyField = "",
  fieldInfo = "",
  style,
  labelStyle,
  defaultValue,
  fromVA = false,
  prefix = "",
  errorStyle,
  inputProps,
  chillError = false, // its error but not red (weird? ikr),
  autoComplete,
  maxLength = null,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const disabled = disabledRaw || isDisabled;

  const currValue = watch(name);

  const hasValue = Boolean(currValue);

  const isActive = isFocused || hasValue || prefix;

  const errorMessage = errors[name]?.message;

  const isError = Boolean(error || errorMessage);

  const shrinkObj = shrink ? { shrink } : hasValue ? { shrink: true } : {};

  const shrinkStyle = shrink ? { fontSize: 12 } : {};

  const getLabel = label || titleCase(name);

  const { border: borderColor, text: labelColor } = labelColorDecider({
    isActive: isFocused,
    isError: chillError ? false : isError,
  });

  const errorString = () => {
    if (typeof error == "string" && error) return error;
    if (emptyField) return emptyField;
    if (errorMessage) return sentenceCase(errorMessage);
    return getLabel + " cannot be empty";
  };
  const { ref, onChange } = register({ name, required });

  return (
    <div style={{ marginBottom: 20, ...containerStyle }} className={className}>
      <div>
        <ThemeProvider theme={theme}>
          <TextField
            name={name}
            disabled={disabled}
            label={required ? `${getLabel}*` : getLabel}
            defaultValue={defaultValue}
            inputRef={ref}
            onChange={onChange}
            variant="standard"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            autoComplete={autoComplete}
            inputProps={{ maxLength }}
            InputProps={{
              disableUnderline: true,
              startAdornment: prefix && (
                <CustomTextFieldPrefix prefix={prefix} />
              ),
              style: {
                marginLeft: 8,
                marginRight: 8,
                color: "black",
                fontFamily: "GothamBook",
                fontSize: 14,
                marginTop: 14,
              },
              ...inputProps,
            }}
            style={{
              height: 44,
              backgroundColor: disabled ? colors.greyea : "transparent",
              borderRadius: 8,
              borderWidth: ".5px",
              width: "100%",
              border: `1px solid ${borderColor}`,
              ...style,
            }}
            InputLabelProps={{
              ...shrinkObj,
              style: {
                position: "absolute",
                fontFamily: "GothamBook",
                fontSize: isActive ? 12 : 14,
                left: 8,
                top: -10,
                color: labelColor,
                ...shrinkStyle,
                ...labelStyle,
              },
            }}
            {...props}
          />
        </ThemeProvider>
      </div>
      <CustomTextFieldHelp help={help} />
      <CustomTextFieldError
        show={isError}
        message={errorString()}
        errorStyle={errorStyle}
        chillError={chillError}
      />
      <CustomTextFieldError
        show={fieldInfo}
        message={String(fieldInfo)}
        errorStyle={{
          fontSize: 10,
          lineHeight: "12px",
          color: jackColors.neutral700,
          marginTop: 4,
        }}
      />
    </div>
  );
};

export const NakedTextField = ({
  containerStyle,
  className = "",
  prefix,
  inputProps,
  disabled,
  error,
  style,
  labelStyle,
  value = "",
  label = "",
  ...props
}) => {
  const isError = Boolean(error);

  const [isFocused, setIsFocused] = useState(false);

  const isActive = isFocused || prefix;

  const { border: borderColor, text: labelColor } = labelColorDecider({
    isActive: isFocused,
    isError,
  });

  return (
    <div style={{ marginBottom: 20, ...containerStyle }} className={className}>
      <TextField
        value={value}
        label={label}
        disabled={disabled}
        variant="standard"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        InputProps={{
          disableUnderline: true,
          startAdornment: prefix && <CustomTextFieldPrefix prefix={prefix} />,
          style: {
            marginLeft: 8,
            marginRight: 8,
            color: "black",
            fontFamily: "GothamBook",
            fontSize: 14,
            marginTop: 14,
          },
          ...inputProps,
        }}
        style={{
          height: 44,
          backgroundColor: disabled ? colors.greyea : "transparent",
          borderRadius: 8,
          borderWidth: ".5px",
          width: "100%",
          border: `1px solid ${borderColor}`,
          ...style,
        }}
        InputLabelProps={{
          style: {
            position: "absolute",
            fontFamily: "GothamBook",
            fontSize: isActive ? 12 : 14,
            left: 8,
            top: isActive || value ? 4 : -10,
            color: labelColor,
            ...labelStyle,
          },
        }}
        {...props}
      />
    </div>
  );
};

export const CurrencyField = (props) => {
  const { useFormObj, name } = props;
  const { setValue, watch } = useFormObj;

  const currentValue = watch(name);

  useEffect(() => {
    setValue(name, formatCurrency(currentValue || 0));
  }, [currentValue]);

  return <CustomTextField {...props} />;
};

export const CustomSelect = ({
  options,
  containerStyle,
  className = "",
  inputStyle,
  iconLeft,
  indicatorStyle,
  ...props
}) => (
  <Select
    {...props}
    className={className}
    instanceId="custom-react-select"
    styles={{
      control: (style, { isFocused }) => ({
        ...style,
        borderRadius: 8,
        height: 48,
        fontSize: 16,
        fontFamily: "GothamBook",
        borderColor: isFocused ? colors.pink : colors.greyC2,
        boxShadow: "0 0 0 0 black",
        ...containerStyle,
      }),
      input: (style) => ({
        ...style,
        fontFamily: "GothamBook",
        ...inputStyle,
        ...iconLeft,
      }),
      option: (style, { isSelected, isFocused }) => ({
        ...style,
        fontSize: 16,
        fontFamily: "GothamBook",
        backgroundColor: isSelected
          ? colors.pink
          : isFocused
          ? colors.lightPink
          : "transparent",
      }),
      menu: (style) => ({
        ...style,
        border: `1px solid ${colors.pink}`,
        borderRadius: 8,
      }),
      indicatorSeparator: () => ({}),
      dropdownIndicator: (style) => ({
        ...style,
        ...indicatorStyle,
      }),
    }}
    options={options}
    name="date"
  />
);

const MinimalistTextFieldPrefix = ({ prefix }) => {
  if (!prefix) return null;
  return (
    <div className="d-flex align-items-center mr-1" style={{ width: 35 }}>
      <GothamRegular style={{ color: colors.grey33, marginBottom: 0 }}>
        {prefix}
      </GothamRegular>
    </div>
  );
};

export const MinimalistTextField = ({
  label = "",
  name = "",
  value = "",
  style,
  prefix = "",
  required = false,
  onSubmit = () => {},
  error,
  errorMsg,
  setValue,
  isNumber = false,
  inputPropsStyle,
  maxLength = false,
  showHelperBeneathTextInputStyle = {},
  t = () => {},
  ...props
}) => {
  const { pathname } = useRouter();
  const isLocalTransaction = pathname.includes("/local-transfer/create");
  const [isFocused, setIsFocused] = useState(false);
  const isEmptyValue = !Boolean(value);
  const isEmptyLabel = !!label.length;
  const inputRef = useRef(null);

  const LabelDecider = required ? (
    <>
      {label}
      <span style={{ color: "red" }}>*</span>
    </>
  ) : (
    label
  );

  const topPositionDecider = () => {
    if (!isFocused && isEmptyValue && Boolean(prefix)) return -3;
    if (!isFocused && isEmptyValue) return -10;
    return -3;
  };

  const onChange = (event) => {
    const { value } = event?.target || {};

    const setValueFunc = (val) => {
      if (maxLength) {
        const canType = maxLength >= val.length;
        if (canType) return setValue(val);
        return;
      }
      return setValue(val);
    };

    if (isNumber) {
      const numberValue = applyRegexOnlyNumbers(value || "");
      return setValueFunc(numberValue);
    }

    return setValueFunc(value);
  };

  const topPosition = topPositionDecider();
  const showCounterMaxLength = value.length && maxLength && isLocalTransaction;
  const showHelperBeneathTextInput = isLocalTransaction && !error;
  const isDynamicBorderBottomColor = isLocalTransaction
    ? error
      ? "1px solid #E73126"
      : value.length
      ? "1px solid #BBBBC0"
      : "1px solid #343434"
    : "1px solid #E6E6E8";

  return (
    <div style={{ position: "relative", width: style?.width || "100%" }}>
      <TextField
        inputRef={inputRef}
        name={name}
        label={LabelDecider}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(event) => onChange(event)}
        onKeyPress={(event) => {
          if (event.key == "Enter") {
            isLocalTransaction && inputRef.current?.blur();
            onSubmit();
          }
        }}
        InputProps={{
          "aria-errormessage": <MinimalistTextField prefix={"hehe"} />,
          disableUnderline: true,
          startAdornment: prefix && (
            <MinimalistTextFieldPrefix prefix={prefix} />
          ),
          style: {
            fontSize: 14,
            fontFamily: "GothamBook",
            lineHeight: "20px",
            height: 36,
            borderBottom: isDynamicBorderBottomColor,
            ...inputPropsStyle,
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: "GothamBook",
            fontSize: 14,
            lineHeight: "20px",
            // top: topPosition,
            top: 0,
            color: "#BBBBC0",
          },
        }}
        style={{ width: "100%", ...style }}
        {...props}
      />
      {showCounterMaxLength ? (
        <div style={{ position: "absolute", right: 8, bottom: 8 }}>
          <GothamRegular className="font10" style={{ color: colors.greyBB }}>
            {value.length} / {maxLength}
          </GothamRegular>
        </div>
      ) : null}
      {showHelperBeneathTextInput ? (
        <div
          style={{
            position: "absolute",
            right: 8,
            bottom: -14,
            ...showHelperBeneathTextInputStyle,
          }}
        >
          <GothamRegular className="font10" style={{ color: colors.greyBB }}>
            <Trans
              i18nKey={t("dynamic.style_text_12")}
              components={{ SpanMedium: <SpanMedium /> }}
            />
          </GothamRegular>
        </div>
      ) : null}
      <CustomTextFieldError
        errorStyle={{ position: "absolute", fontSize: 10 }}
        message={errorMsg}
        show={error}
      />
    </div>
  );
};

const CustomMultiSelectOptions = ({
  data,
  innerProps,
  selectProps,
  customVAOptions,
}) => {
  const { value, label, icon, iconSize, fee } = data || {};
  const { options, withIcon } = selectProps;
  const withIconStyle = withIcon
    ? { display: "flex", alignItems: "center", gap: 10, borderRadius: 8 }
    : {};
  const isFirst = data === options[0];
  const { t } = useTranslation("va/create");

  return (
    <div {...innerProps} style={{ backgroundColor: "white" }}>
      {isFirst && (
        <GothamMedium
          className="mx-2 font12 mb-2"
          style={{ color: jackColors.neutral900 }}
        >
          {t("Select Bank")}
        </GothamMedium>
      )}
      <div
        className="hover-600 mx-2"
        style={{ padding: "12px 0px", cursor: "pointer", ...withIconStyle }}
      >
        {withIcon && (
          <div
            style={{
              borderRadius: 4,
              border: `1px solid ${jackColors.neutral500}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 2,
              width: 24,
              height: 24,
              marginLeft: 8,
            }}
          >
            <img src={icon} alt="bank-icon" style={iconSize} />
          </div>
        )}
        {!customVAOptions && (
          <GothamRegular
            style={{
              fontSize: 14,
              color: jackColors.neutral800,
              cursor: "pointer",
            }}
          >
            {label}
          </GothamRegular>
        )}
        {!!customVAOptions && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <GothamMedium
              style={{
                fontSize: 14,
                color: jackColors.neutral800,
                cursor: "pointer",
              }}
            >
              {label}
            </GothamMedium>
            <GothamRegular
              style={{
                fontSize: 14,
                color: jackColors.neutral700,
                cursor: "pointer",
              }}
            >
              {`${t("Admin fee")} : IDR ${formatCurrencyNoDecimal(fee)}`}
            </GothamRegular>
          </div>
        )}
      </div>
    </div>
  );
};

const DropdownIndicator = (props) => {
  const { noArrowDropDown } = props;
  return (
    <div style={{ position: "absolute", right: 0, width: 18, height: 18 }}>
      {!noArrowDropDown && (
        <JackIcons name="chevron-down" fill={jackColors.neutral900} />
      )}
    </div>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  const { required, noAsterisk } = props || {};
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
        {required ||
          (!noAsterisk && <span style={{ color: colors.redE7 }}>*</span>)}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const NoOptionsMessageMinimalist = (props) => {
  const { selectProps, forTableInput } = props;
  const { inputValue } = selectProps || {};
  return (
    <components.NoOptionsMessage {...props}>
      <EmptyScreenSearchRecipients
        text={inputValue}
        forTableInput={forTableInput}
      />
    </components.NoOptionsMessage>
  );
};

const { ValueContainer, Placeholder } = components;

const FooterInfo = ({ text = "" }) => {
  return (
    <div
      style={{
        padding: "12px 8px",
        display: "flex",
        gap: 8,
        backgroundColor: jackColors.neutral400,
        alignItems: "flex-start",
        border: `1px solid ${jackColors.neutral500}`,
        borderRadius: "0px 0px 4px 4px",
      }}
    >
      <JackIcons
        name="info"
        style={{ height: 16, width: 16 }}
        fill={jackColors.neutral700}
      />

      <GothamRegular
        className="font12"
        style={{ color: jackColors.neutral800 }}
      >
        {text}
      </GothamRegular>
    </div>
  );
};

const MenuList = (props) => {
  const children = isArray(props.children) ? props.children : [];
  const footerText = props.footerInfo;

  return (
    <div>
      <components.MenuList {...props}>{children}</components.MenuList>
      {!!footerText && <FooterInfo text={footerText} />}
    </div>
  );
};

export const MinimalistSelect = ({
  value,
  options,
  onChange,
  defaultValue,
  noArrowDropDown = false,
  required,
  placeholder = "Bank",
  noOptionsMsg = "Your bank not found",
  error,
  errorMsg,
  noAsterisk,
  menuStyle,
  customVAOptions,
  footerInfo,
  ...props
}) => {
  const getValue = options.filter((item) => item.value == value)[0] || "";
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const componentsDecider = () => {
    return {
      // Option: CustomMultiSelectOptions,
      Option: (props) => (
        <CustomMultiSelectOptions
          {...props}
          customVAOptions={customVAOptions}
        />
      ),
      DropdownIndicator: (props) => (
        <DropdownIndicator {...props} noArrowDropDown={noArrowDropDown} />
      ),
      // // ValueContainer: (props) => (
      // //   <CustomValueContainer
      // //     {...props}
      // //     required={required}
      // //     noAsterisk={noAsterisk}
      // //   />
      // // ),
      NoOptionsMessage: (props) => (
        <NoOptionsMessageMinimalist
          {...props}
          msg={noOptionsMsg}
          forTableInput
        />
      ),
      ...(footerInfo && {
        MenuList: (props) => <MenuList {...props} footerInfo={footerInfo} />,
      }),
    };
  };

  const components = componentsDecider();

  return (
    <div>
      <Select
        {...props}
        options={options}
        onChange={onChange}
        value={getValue}
        components={components}
        defaultValue={defaultValue}
        placeholder={placeholder}
        noOptionsMessage={() => `${noOptionsMsg}`}
        menuPortalTarget={document.body}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        styles={{
          container: (style) => ({
            ...style,
          }),
          input: (style) => ({
            ...style,
            fontFamily: "GothamBook",
            borderBottom: "1px solid #e6e6e8",
            padding: "8px 0px 7px 0px",
            fontSize: 14,
            margin: 0,
            cursor: "pointer",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible",
            padding: 0,
          }),
          control: (style) => ({
            ...style,
            border: "none",
            fontFamily: "GothamBook",
            fontSize: 14,
            boxShadow: "none",
            position: "relative",
            "&:hover": {
              border: `none`,
            },
          }),
          option: (style, { isSelected, isFocused }) => ({
            ...style,
            fontSize: 14,
            fontFamily: "GothamBook",
            margin: 14,
            borderRadius: 14,
            backgroundColor: isSelected
              ? colors.pink
              : isFocused
              ? colors.lightPink
              : "transparent",
            boxShadow: "none",
          }),
          singleValue: (style) => ({
            ...style,
            margin: "0px 0px -5.5px 0px",
            paddingRight: 20,
          }),
          menu: (style) => ({
            ...style,
            boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
            border: "none",
            width: 266,
            zIndex: 999,
            marginLeft: -8,
            ...menuStyle,
          }),
          menuPortal: (style) => ({
            ...style,
            zIndex: 999999,
          }),
          indicatorSeparator: () => ({}),
          dropdownIndicator: (style) => ({
            ...style,
            display: "none",
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            zIndex: 20,
            fontSize: 14,
            fontFamily: "GothamBook",
            color: "#BBBBC0",
            top: state.hasValue || state.selectProps.inputValue ? -5 : "27%",
            left: -2,
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
          }),
        }}
      />
      {!isMenuOpen ? (
        <div
          style={{
            position: "absolute",
            right: 63,
            // bottom: -14,
          }}
        >
          <GothamRegular className="font10" style={{ color: colors.greyBB }}>
            <Trans
              i18nKey={tLocalTransfer("dynamic.style_text_12")}
              components={{ SpanMedium: <SpanMedium /> }}
            />
          </GothamRegular>
        </div>
      ) : null}
      <CustomTextFieldError
        errorStyle={{ position: "absolute", fontSize: 10 }}
        message={errorMsg}
        show={error}
      />
    </div>
  );
};

export const CustomTextArea = ({
  name = "",
  label = "",
  style,
  placeholder = "Placeholder",
  useFormObj: { setValue, register, watch, errors },
  className = "",
  defaultValue,
  disabled,
  maxLength = 0,
}) => {
  const value = watch(name) || "";

  useEffect(() => register({ name }), []);

  useEffect(() => {
    if (!defaultValue) return;
    setValue(name, defaultValue);
  }, [defaultValue]);

  const onChange = (val) => {
    const text = val?.target?.value;
    if (maxLength) {
      const canType = maxLength >= text.length;
      if (canType) return setValue(name, text);
      return;
    }
    setValue(name, text);
  };

  return (
    <div style={{ position: "relative" }}>
      {maxLength ? (
        <div style={{ position: "absolute", right: 8, bottom: 8 }}>
          <GothamRegular className="font10" style={{ color: colors.greyBB }}>
            {value.length} / {maxLength}
          </GothamRegular>
        </div>
      ) : null}
      <TextareaAutosize
        disabled={disabled}
        defaultValue={defaultValue}
        minRows={3}
        name={name}
        label={label}
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        style={{
          resize: "none",
          width: "100%",
          color: colors.grey72,
          border: "1px solid #C2C2C2",
          borderRadius: 8,
          padding: "13px 8px",
          outline: "none",
          ...style,
        }}
      />
    </div>
  );
};

const BatchSelection = ({
  data,
  innerProps,
  style,
  isSelected,
  notSelection,
}) => {
  const { label, currency, country_iso } = data || {};
  const fontColor = isSelected ? "white" : "black";

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${
        notSelection ? "" : "pinkhover"
      }`}
      style={{
        position: "absolute",
        bottom: 0,
        left: -2,
        width: "100%",
        height: "100%",
        backgroundColor: isSelected ? colors.pink : "",
        ...style,
      }}
      {...innerProps}
    >
      <div style={{ marginLeft: 10, marginBottom: notSelection ? -8 : 0 }}>
        <GothamRegular style={{ marginBottom: 0, color: fontColor }}>
          {label}
        </GothamRegular>
      </div>
      <div className="d-flex align-items-center">
        <GothamMedium
          className="mt-1"
          style={{ marginBottom: 0, color: fontColor }}
        >
          {currency}
        </GothamMedium>
        <GetFlag iso={noCase(country_iso)} />
      </div>
    </div>
  );
};

const BatchSingleValue = ({ selectProps }) => {
  const { value } = selectProps || {};
  return <BatchSelection data={value} notSelection />;
};

const BatchOptions = ({ data, innerProps, isSelected }) => (
  <BatchSelection
    data={data}
    innerProps={innerProps}
    style={{ position: "relative", height: 44 }}
    isSelected={isSelected}
  />
);

const BusinessTypeOptions = ({ data, innerProps, isSelected }) => {
  const [hover, setHover] = useState(false);
  const { label, iconGrey, iconPink, id } = data || {};
  const isHover = hover === id;
  return (
    <div
      className="pink-hover d-flex"
      style={{
        border: "none",
        padding: "12px 8px",
        gap: 8,
        backgroundColor: isHover && colors.lightPink,
      }}
      onMouseEnter={() => setHover(id)}
      onMouseLeave={() => setHover(false)}
      {...innerProps}
    >
      <img src={isHover ? iconPink : iconGrey} />
      <GothamMedium>{label}</GothamMedium>
    </div>
  );
};

export const Selection = ({
  options,
  label: labelProps = "",
  onChange = () => {},
  name = "",
  woTitleCase,
  isCalculator = false,
  icon = null,
  error,
  isLoading,
  containerStyle,
  inputStyle,
  labelStyle,
  optionStyle,
  menuStyle,
  className = "",
  isPhoneCode,
  isBatchCountry,
  isBusinessType,
  isTopBottom,
  isSearch,
  isPhoneSimple,
  isFlag,
  isCentered,
  placeholder,
  ...props
}) => {
  const height = 44;
  const { value, disabled } = props;
  const ref = useRef();
  const hasValue = Boolean((ref?.current?.state?.selectValue || []).length);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const label = isMenuOpen ? "" : labelProps;

  useEffect(() => {
    if (value) return;
    ref?.current?.clearValue();
  }, [value]);

  const optionsWLoading = isLoading ? [] : options;

  const IndicatorSeparator = (props) => {
    const { isFocused, hasValue } = props;
    const isActive = isFocused || hasValue;
    return (
      <div
        className="d-flex align-items-center"
        style={{
          position: "absolute",
          left: isFlag ? 0 : 8,
          top: isFlag ? -2 : 0,
          height,
          width: "80%",
        }}
      >
        {icon}
        <OldGothamRegular
          style={{
            color: colors.grey72,
            fontSize: isActive ? 10 : 14,
            ...labelStyle,
          }}
          className={`my-0 ${isActive ? "align-self-start pt-1" : ""}`}
        >
          {woTitleCase ? label : titleCase(label)}
        </OldGothamRegular>
      </div>
    );
  };

  const TopBottomSingleValue = ({ selectProps, innerProps }) => {
    const { value } = selectProps;
    const { label, top } = value || {};
    return (
      <div
        className={`d-flex justify-content-between align-items-center px-2`}
        style={{
          position: "absolute",
          bottom: -7,
          width: "100%",
          height: "100%",
        }}
        {...innerProps}
      >
        <GothamRegular style={{ marginBottom: 0 }}>
          {top || label}
        </GothamRegular>
      </div>
    );
  };

  const PhoneCodeSingleValue = ({ selectProps }) => {
    const { iso, number } = phoneSimpleLabelSplitter(selectProps?.value?.label);

    return (
      <div
        className="d-flex align-items-center"
        style={{ position: "absolute", bottom: 10, left: -2 }}
      >
        {iso && (
          <GetFlag
            iso={noCase(iso)}
            width="16px"
            height="16px"
            style={{ filter: "brightness(92%)" }}
          />
        )}
        {number && (
          <span
            style={{
              color: colors.grey25,
              fontFamily: "GothamMedium",
              fontSize: 14,
              marginBottom: -3,
            }}
          >
            {number}
          </span>
        )}
      </div>
    );
  };

  const PhoneSimpleSingleValue = ({ selectProps }) => {
    const { number } = phoneSimpleLabelSplitter(selectProps?.value?.label);

    return (
      <div
        className="d-flex align-items-center"
        style={{ position: "absolute", bottom: 10, left: 8, maxWidth: "15px" }}
      >
        <span
          style={{
            color: colors.grey25,
            fontFamily: "GothamBook",
            fontSize: 14,
            marginBottom: -3,
            marginLeft: isFlag ? 36 : 0,
          }}
        >
          {number}
        </span>
      </div>
    );
  };

  const PhoneSimpleOptions = (props) => {
    const { innerProps, isSelected, data } = props || {};
    const { number } = phoneSimpleLabelSplitter(data?.label);

    const fontColor = isSelected ? "white" : "black";
    return (
      <div
        className="d-flex justify-content-between align-items-center pinkhover"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: isSelected ? colors.pink : "",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
        }}
        {...innerProps}
      >
        <GothamRegular style={{ color: fontColor }}>{number}</GothamRegular>
      </div>
    );
  };
  const componentsDecider = () => {
    if (isPhoneSimple)
      return {
        IndicatorSeparator,
        SingleValue: PhoneSimpleSingleValue,
        Option: PhoneSimpleOptions,
      };

    if (isBatchCountry)
      return {
        IndicatorSeparator,
        SingleValue: BatchSingleValue,
        Option: BatchOptions,
      };

    if (isBusinessType)
      return {
        IndicatorSeparator,
        Option: BusinessTypeOptions,
      };

    const calculatorComponents = {
      Option: CustomOptions,
      IndicatorSeparator,
    };

    if (isPhoneCode)
      return {
        ...calculatorComponents,
        SingleValue: PhoneCodeSingleValue,
      };

    if (isCalculator) return calculatorComponents;

    if (isTopBottom)
      return {
        IndicatorSeparator,
        Option: TopBottomOption,
        SingleValue: TopBottomSingleValue,
      };

    return {
      IndicatorSeparator,
    };
  };

  const components = componentsDecider();
  return (
    <Select
      ref={ref}
      isPhoneCode={isPhoneCode}
      className={className}
      instanceId={"custom-react-selection" + name}
      isCalculator={isCalculator}
      isDisabled={disabled}
      components={components}
      styles={{
        dropdownIndicator: (style, { isFocused }) => {
          return {
            ...style,
            color: error ? "#E95757" : isFocused ? colors.pink : colors.greyC2,
          };
        },
        control: (style, { isFocused, isDisabled }) => ({
          ...style,
          borderRadius: 8,
          height,
          fontSize: 14,
          fontFamily: "GothamBook",
          color: "transparent",
          backgroundColor: isDisabled ? "#eaeaea" : "white",
          borderColor: error
            ? "#E95757"
            : isFocused
            ? colors.pink
            : colors.greyC2,
          boxShadow: "0 0 0 0 black",
          "&:hover": {
            border: `1px solid ${colors.pink}`,
          },
          ...containerStyle,
        }),
        singleValue: (style) => ({
          ...style,
          fontFamily: "GothamBook",
          fontSize: 14,
          marginTop: isCentered ? -2 : 12,
          marginLeft: 0,
        }),
        input: (style) => ({
          ...style,
          fontFamily: "GothamBook",
          marginLeft: isFlag ? 36 : 0,
          fontSize: 14,
          marginTop: isFlag || isCentered ? 2 : isMenuOpen ? 4 : 15,
          ...inputStyle,
        }),
        option: (style, { isSelected, isFocused }) => ({
          ...style,
          fontSize: 14,
          fontFamily: "GothamBook",
          backgroundColor: isSelected
            ? colors.pink
            : isFocused
            ? colors.lightPink
            : "transparent",
          ...optionStyle,
        }),
        menu: (style) => ({
          ...style,
          border: `none`,
          // border: `1px solid ${colors.pink}`,
          borderRadius: 8,
          ...menuStyle,
        }),
        indicatorSeparator: () => ({}),
      }}
      onMenuOpen={() => {
        if (!isSearch) return;
        if (hasValue) return;
        setIsMenuOpen(true);
      }}
      onMenuClose={() => setIsMenuOpen(false)}
      options={optionsWLoading}
      name={name}
      onChange={onChange}
      isLoading={isLoading}
      {...props}
      placeholder={isMenuOpen ? "Search..." : placeholder ? placeholder : ""}
    />
  );
};

export const defaultIconStyleQueryInput = {
  style: {
    position: "absolute",
    left: "9px",
    top: "10px",
    color: "#727272",
  },
};

const ColWrapper = ({ isCol, columnProps, children }) => {
  if (isCol)
    return (
      <Col className="d-flex align-items-center px-1" {...columnProps}>
        {children}
      </Col>
    );
  return children;
};

const defaultDateDecider = (currentValue) => {
  if (currentValue) {
    const getDate = new Date(currentValue);
    const dateString = String(getDate);
    const invalid = dateString.includes("Invalid");
    if (invalid) {
      return null;
    }
    return getDate;
  }
  return null;
};

export const QueryDatePicker = ({
  columnProps,
  name,
  label,
  isRangeDate,
  defaultDate,
  defaultEnd,
  woClearButton,
  containerStyle,
  hideClear = false,
  showClear,
  maxDate,
  minDate,
  disabled,
  isMinimalist = false, // No Icon Date & Label Text Grey
  woPage,
  ...rest
}) => {
  const { push, query } = useRouter();

  const currentValue = query[name] || query["start_date"];
  const currentValueEnd = query["end_date"];
  const defaultValue = defaultDateDecider(currentValue || defaultDate);
  const defaultValueEnd = defaultDateDecider(currentValueEnd || defaultEnd);

  const [date, setDate] = useState(defaultValue);
  const [endDate, setEndDate] = useState(defaultValueEnd);

  const useFormObj = useForm();
  const ref = useRef();

  const onClickIcon = () => {
    ref?.current?.setOpen(true);
  };
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    // needs setTimeout, caused by inconsistency of `defaultValue`
    if (isFirstRender)
      return setTimeout(() => {
        setIsFirstRender(false);
      }, 100);
    setDate(defaultValue);
    setEndDate(defaultValueEnd);
  }, [isFirstRender]);

  const defaultPush = (query) => {
    if (woPage) return push({ query });
    push({ query: { ...query, page: 1 } });
  };

  // run this when its not date range
  useEffect(() => {
    const stopper = isRangeDate || !date || isFirstRender;
    if (stopper) return;

    let newObj = {};
    const currentDate = new Date(date);
    newObj[name] = String(currentDate);
    const queries = { ...query, ...newObj };
    defaultPush(queries);
  }, [date, isFirstRender]);
  // run this when its not date range

  // run this when its date range
  useEffect(() => {
    const stopper = !isRangeDate || isFirstRender || !endDate;

    if (stopper) return;

    const newObj = {
      start_date: String(new Date(date)),
      end_date: String(new Date(endDate)),
    };
    const queries = { ...query, ...newObj };

    defaultPush(queries);
  }, [endDate, isFirstRender]);
  // run this when its date range

  const handleClear = () => {
    if (isRangeDate) {
      delete query["start_date"];
      delete query["end_date"];
    }

    delete query[name];
    defaultPush(query);

    setDate(null);
    setEndDate(null);
  };

  const propsDecider = () => {
    if (isRangeDate) {
      const onChange = (dates) => {
        const [start, end] = dates;
        setDate(start);
        setEndDate(end);
      };

      const onCalendarClose = () => {
        // if it has defaultValue, run this
        if (defaultEnd) {
          if (!endDate) return setEndDate(new Date());
          return;
        }
        // if it has defaultValue, run this
        if (!endDate) {
          setDate(null);
          setEndDate(null);
          return;
        }
      };

      return {
        startDate: date,
        endDate,
        selectsRange: true,
        onChange,
        maxDate: new Date(),
        onCalendarClose,
      };
    }

    return {
      onSelect: (date) => setDate(date),
    };
  };

  const getProps = propsDecider();
  const showClearButton =
    !woClearButton &&
    ((Boolean(date) && !isRangeDate && !hideClear) ||
      (isRangeDate && showClear && endDate));

  const getLabel = isRangeDate
    ? "Start Date - End Date"
    : label
    ? label
    : titleCase(name);

  const { isTabOrPhone, isTabOrPhoneStyleMain } = tabOrPhoneStyles();
  const labelStyle = isMinimalist ? { color: colors.grey6c } : {};

  return (
    <ColWrapper isCol={!isTabOrPhone} columnProps={columnProps}>
      <div style={{ position: "relative", width: "100%" }}>
        <DatePicker
          {...getProps}
          dateFormat="yyyy/MM/dd"
          name={name}
          selected={date}
          ref={ref}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
          customInput={
            <CustomTextFieldRef
              name={name}
              label={getLabel}
              isDisabled
              useFormObj={useFormObj}
              style={{ backgroundColor: "white" }}
              containerStyle={{ ...containerStyle, ...isTabOrPhoneStyleMain }}
              labelStyle={labelStyle}
            />
          }
          {...rest}
        />
        {!isMinimalist && (
          <img
            onClick={onClickIcon}
            src={dateIcon}
            className="darkhover"
            style={{
              position: "absolute",
              right: 10,
              top: 12.5,
            }}
          />
        )}
        {showClearButton && (
          <button
            className="fake-button"
            style={{
              position: "absolute",
              bottom: -3,
              left: 0,
              textDecoration: "underline",
            }}
            onClick={handleClear}
          >
            Clear
          </button>
        )}
      </div>
    </ColWrapper>
  );
};
//start
const DatePickerCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  customRangeYears = null,
}) => {
  const { minYear, maxYear } = customRangeYears || {};
  const years = customRangeYears
    ? range(minYear, maxYear + 1, 1)
    : range(1990, getYear(new Date()) + 3, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const ArrowButton = ({ disabled, onClick, isLeft }) => (
    <GothamMedium
      className={`${disabled ? "" : "darkhover"} h-100 font16 ${
        isLeft ? "ml-1" : "mr-1"
      }`}
      style={{
        color: disabled ? colors.greyC2 : colors.grey72,
        width: 20,
      }}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
    >
      {isLeft ? "<" : ">"}
    </GothamMedium>
  );

  return (
    <div className=" d-flex justify-content-between align-items-center">
      <ArrowButton
        isLeft
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
      />
      <div
        className="d-flex"
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <select
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div style={{ width: 4 }}></div>
        <select
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <ArrowButton disabled={nextMonthButtonDisabled} onClick={increaseMonth} />
    </div>
  );
};
export const CustomDateInputv2 = ({
  name,
  label,
  useFormObj: { watch, errors, control },
  defaultValue = "",
  error,
  disabled,
  required = true,
  emptyField = "",
  prefix = "",
  minDate,
  maxDate,
  inputProps,
  style,
  errorStyle,
  containerStyle,
  chillError = false,
  labelStyle,
  showError = false,
  customRangeYears = null,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const currValue = watch(name);
  const hasValue = Boolean(currValue);
  const errorMessage = errors[name]?.message;
  const isError = Boolean(error || errorMessage);
  const ref = useRef();
  const getLabel = label || titleCase(name);
  const onClickIcon = () => {
    ref?.current?.setOpen(true);
  };
  const { border: borderColor, text: labelColor } = labelColorDecider({
    isActive: isFocused,
    isError: chillError ? false : isError,
  });
  const errorString = () => {
    if (typeof error == "string" && error) return error;
    if (emptyField) return emptyField;
    if (errorMessage) return sentenceCase(errorMessage);
    return getLabel + " cannot be empty";
  };
  return (
    <div style={{ position: "relative", ...containerStyle }}>
      <Controller
        style={{ position: "relative", width: "100%" }}
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <DatePicker
              dateFormat="dd/MM/yyyy"
              renderCustomHeader={(args) =>
                DatePickerCustomHeader({ ...args, customRangeYears })
              }
              selected={field.value}
              ref={ref}
              minDate={minDate}
              maxDate={maxDate}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(e) => field?.onChange(e)}
              disabled={disabled}
              required={required}
              onCalendarClose={() => setIsFocused(false)}
              customInput={
                <TextField
                  label={getLabel}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      marginLeft: 8,
                      marginRight: 8,
                      color: "black",
                      fontFamily: "GothamBook",
                      fontSize: 14,
                      marginTop: 14,
                    },
                    ...inputProps,
                  }}
                  InputLabelProps={{
                    style: {
                      position: "absolute",
                      fontFamily: "GothamBook",
                      fontSize: 14,
                      left: 8,
                      top: -10,
                      color: labelColor,
                      ...labelStyle,
                    },
                  }}
                  value={field.value}
                  inputRef={field.ref}
                  style={{
                    height: 44,
                    backgroundColor: disabled ? colors.greyea : "transparent",
                    borderRadius: 8,
                    width: "100%",
                    borderWidth: 0.5,
                    border: `1px solid ${borderColor}`,
                    ...style,
                  }}
                />
              }
            />
          );
        }}
      />
      <CustomTextFieldError
        show={isError}
        message={errorString()}
        errorStyle={errorStyle}
        chillError={chillError}
      />
      <img
        onClick={onClickIcon}
        src={dateIcon}
        className="darkhover"
        style={{
          position: "absolute",
          right: 10,
          top: 12.5,
        }}
      />
    </div>
  );
};

//end
const tabOrPhoneStyles = () => {
  const { isTabOrPhone } = windowDimension();
  const isTabOrPhoneStyleMain = isTabOrPhone ? { marginBottom: 12 } : {};
  const isTabOrPhoneStyle = isTabOrPhone ? { width: "100%" } : {};

  return { isTabOrPhoneStyleMain, isTabOrPhoneStyle, isTabOrPhone };
};

export const QueryInput = ({
  name,
  placeholder,
  icon,
  columnProps,
  isCol,
  isMargin = true,
  containerStyle,
  inputStyle,
  woQuery,
  onChange,
  woDebounce,
  disabled = false,
  isNumber = false,
  defaultValue,
  stickyPlaceholder,
  maxLength,
  woClearButton = true,
  xIcon,
  autoFocus,
}) => {
  const { query, push } = useRouter();
  const value = query[name] || "";
  const [search, setSearch] = useState(query[name] || defaultValue || "");

  const debouncedSearch = useDebounce(search, woDebounce ? 0 : 750);

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!value) setSearch("");

    const numberValue = String(search)
      .split("")
      .filter((number) => !isNaN(number) && number !== " ")
      .join("");

    const cappedNumberValue = maxLength
      ? numberValue.slice(0, maxLength)
      : numberValue;

    setSearch(isNumber ? formatCurrency(cappedNumberValue) : search);
  }, [value, search]);

  useEffect(() => {
    if (defaultValue) setSearch(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
    if (woQuery) {
      onChange(search);
      return;
    }

    let obj = {};
    obj[name] = search;
    push({ query: { ...query, page: 1, ...obj } });
  }, [debouncedSearch]);

  const { isTabOrPhoneStyle, isTabOrPhoneStyleMain } = tabOrPhoneStyles();

  return (
    <ColWrapper isCol={isCol} columnProps={columnProps}>
      <div
        className={isMargin ? "form-group has-search d-flex" : ""}
        style={{
          width: "100%",
          marginTop: isCol ? 18 : 0,
          ...isTabOrPhoneStyleMain,
          ...containerStyle,
        }}
      >
        {icon}
        {!woClearButton && (
          <div onClick={() => setSearch("")} style={{ cursor: "pointer" }}>
            {xIcon}
          </div>
        )}
        {stickyPlaceholder && (
          <div
            style={{
              position: "absolute",
              left: "9px",
              top: "11px",
              color: "#727272",
            }}
          >
            <GothamMedium style={{ color: "#333334" }}>
              {stickyPlaceholder}
            </GothamMedium>
          </div>
        )}
        <input
          autoFocus={autoFocus}
          type="text"
          value={search}
          onChange={(e) => e && setSearch(e.target.value)}
          placeholder={placeholder}
          className="searchBatch"
          disabled={disabled}
          style={
            icon
              ? {
                  height: 42,
                  paddingTop: 10,
                  ...isTabOrPhoneStyle,
                  ...inputStyle,
                }
              : {
                  padding: 16,
                  paddingTop: 10,
                  height: 42,
                  width: "100%",
                  paddingLeft: "40px",
                  ...isTabOrPhoneStyle,
                  ...inputStyle,
                }
          }
        />
      </div>
    </ColWrapper>
  );
};

export const QuerySelection = ({
  options: optionsRaw = [],
  containerStyle,
  mainContainerStyle,
  inputStyle,
  className = "",
  height = 56,
  label = "",
  name,
  onChange = () => {},
  style,
  columnProps,
  woClearButton,
  ...props
}) => {
  const defaultQueryOption = { label: "All", value: "all_hidden" };
  const options = [defaultQueryOption, ...optionsRaw];

  const { push, query } = useRouter();
  const valueFromQuery = query[name];

  const [getValue, setGetValue] = useState(null);

  const noOptions = options.length == 1;

  const hideClear =
    getValue?.value == defaultQueryOption.value || woClearButton || noOptions;

  useEffect(() => {
    if (noOptions) return;

    if (!valueFromQuery) return setGetValue(defaultQueryOption);

    const isAll = valueFromQuery == defaultQueryOption.value;
    if (isAll) return handleClear();

    const result = options.filter(({ value }) => valueFromQuery == value)[0];
    setGetValue(result || null);

    // clear query if not found
    const isNoResult = !result && valueFromQuery;
    if (isNoResult) handleClear();
    // clear query if not found
  }, [query, noOptions]);

  const handleClear = () => {
    delete query[name];
    push({ query });
  };

  const defaultOnChange = (val) => {
    if (!val) return;
    let obj = {};
    const safeValue = get(val, "value", "");
    if (safeValue) {
      obj[name] = safeValue;
    }
    push({ query: { ...query, ...obj, page: 1 } });
  };

  const { isTabOrPhone, isTabOrPhoneStyle, isTabOrPhoneStyleMain } =
    tabOrPhoneStyles();

  return (
    <ColWrapper isCol={!isTabOrPhone}>
      <div
        style={{
          width: "100%",
          // overflowX: "hidden",
          ...mainContainerStyle,
          ...isTabOrPhoneStyleMain,
        }}
      >
        <Selection
          {...props}
          options={options}
          containerStyle={{ ...containerStyle, ...isTabOrPhoneStyle }}
          className={className}
          inputStyle={inputStyle}
          height={height}
          label={label}
          name={name}
          value={getValue}
          onChange={(val) => {
            defaultOnChange(val);
            onChange(val);
          }}
        />
        {!hideClear && (
          <button
            className="fake-button"
            style={{ position: "absolute", textDecoration: "underline" }}
            onClick={handleClear}
          >
            Clear
          </button>
        )}
      </div>
    </ColWrapper>
  );
};

export const SelectionForm = ({
  useFormObj: { watch, setValue, register, errors },
  name,
  label,
  options,
  required = "Please select an option",
  defaultValue = undefined,
  error,
  woSpacer,
  errorStyle,
  onChange,
  alwaysShow = false,
  valueListener = true,
  containerStyle,
  customErrorMessage,
  ...props
}) => {
  useEffect(() => {
    register({ name, required });
  }, [name, register]);

  const value = watch(name) || defaultValue;

  const isSingleOptions = options.length == 1;

  useEffect(() => {
    const stopper = options.length == 0;
    const isObject = typeof value == "object";
    if (stopper) return;
    if (isObject) return;

    const result = options.filter(
      ({ value: itemValue }) => itemValue == value
    )[0];

    if (isSingleOptions) return setValue(name, options[0]);

    if (!result) return setValue(name, undefined);

    setValue(name, result);
  }, [value, options.length]);

  const handleChange = (item) => {
    onChange && onChange(item);
    setValue(name, item);
  };

  const errorMsgFunc = () => {
    if (valueListener)
      return Boolean(value?.value) ? "" : errors[name]?.message;
    return errors[name]?.message;
  };

  const errorMessage = errorMsgFunc();
  const isErrorMsgTooLong = (errorMessage || "").length > 50;

  const getErrorMsg = isErrorMsgTooLong ? `${label} is invalid` : errorMessage;

  const showError = Boolean(error || errorMessage);

  const isError = Boolean(error || errorMessage);

  return (
    <div style={{ display: isSingleOptions && !alwaysShow && "none" }}>
      <Selection
        containerStyle={containerStyle}
        options={options}
        label={label}
        labelStyle={{ color: isError ? colors.errorRed : colors.grey72 }}
        name={name}
        value={value}
        onChange={handleChange}
        error={showError}
        {...props}
      />
      <CustomTextFieldError
        errorStyle={errorStyle}
        message={
          customErrorMessage ||
          sentenceCase(getErrorMsg || `${label} cannot be empty`)
        }
        show={showError}
      />
      {!woSpacer && <div style={{ height: 16 }}></div>}
    </div>
  );
};

export const CountrySelection = ({
  options,
  containerStyle,
  className = "",
  inputStyle,
  height = 44,
  label = "",
  useFormObj: { register, watch, setValue },
  name,
  isLoading = false,
  defaultValue,
  disabled,
  onChange = () => {},
  style,
  labelStyle,
  ...props
}) => {
  const currentVal = watch(name);
  const [selectedCountryId, setSelectedCountryId] = useState(false);

  useEffect(() => {
    setSelectedCountryId(currentVal?.country_id);
  }, [currentVal?.country_id]);

  const isEmpty = !currentVal && !defaultValue;

  useEffect(() => {
    register({ name });
  }, [name]);

  const defaultFont = { fontSize: 14, fontFamily: "GothamBook" };
  const [showCurr, setShowCurr] = useState(true);

  return (
    <div style={style}>
      <GothamRegular
        style={{
          color: colors.grey72,
          lineHeight: "14px",
          ...labelStyle,
        }}
      >
        {label}
      </GothamRegular>
      <Select
        {...props}
        onMenuOpen={() => setShowCurr(false)}
        onMenuClose={() => setShowCurr(true)}
        isDisabled={disabled}
        defaultValue={currentVal ? currentVal : defaultValue}
        isLoading={isLoading}
        name={name}
        className={className}
        instanceId={"custom-react-selection" + name}
        components={{
          Option: (props) => (
            <CustomOptions selectedCountryId={selectedCountryId} {...props} />
          ),
          IndicatorSeparator: (props) => {
            const { country_iso, currency } = currentVal || defaultValue || {};
            return (
              <Row
                style={{
                  height,
                  top: 0,
                  left: 1,
                  position: "absolute",
                }}
                className="mx-0 "
              >
                {!isEmpty && <GetFlag iso={noCase(country_iso)} />}

                {/* {showCurr && (
                  <GothamMedium
                    style={{
                      color: colors.pink,
                      fontSize: 14,
                    }}
                    className="mt-1"
                  >
                    {currency}
                  </GothamMedium>
                )} */}
              </Row>
            );
          },
        }}
        styles={{
          dropdownIndicator: (style, { isFocused }) => ({
            ...style,
            display: disabled && "none",
            color: isFocused ? colors.pink : colors.greyC2,
          }),
          control: (style, { isFocused }) => ({
            ...style,
            borderRadius: 4,
            height,
            ...defaultFont,
            color: "transparent",
            border: `1px solid ${jackColors.neutral500}`,
            paddingLeft: 32,
            ...containerStyle,
          }),
          singleValue: (style) => ({
            ...style,
            ...defaultFont,
            // marginTop: !showCurr ? 0 : 15,
            marginLeft: 4,
            color: jackColors.neutral600,
          }),
          input: (style) => ({
            ...style,
            ...defaultFont,
            marginLeft: 5,
            color: colors.grey72,
            ...inputStyle,
          }),
          option: (style, { isSelected, isFocused }) => ({
            ...style,
            ...defaultFont,
            backgroundColor: isSelected
              ? colors.pink
              : isFocused
              ? colors.lightPink
              : "transparent",
          }),
          placeholder: (style) => ({
            ...style,
            display: isEmpty ? "" : "none",
          }),
          menu: (style) => ({
            ...style,
            border: `1px solid ${jackColors.neutral500}`,
            borderRadius: 4,
          }),
          indicatorSeparator: () => ({}),
        }}
        options={options}
        onChange={(e) => {
          setValue(name, Boolean(e) && e);
          onChange(e);
          setShowCurr(true);
        }}
      />
    </div>
  );
};

const TopBottomOption = ({ data, innerProps, isSelected }) => {
  const { label, top, bottom } = data;
  const defaultFontStyle = {
    color: isSelected ? "white" : "black",
    marginBottom: 0,
  };
  if (top && bottom)
    return (
      <Row
        className="option mx-0  align-items-center px-2"
        {...innerProps}
        style={
          isSelected
            ? { backgroundColor: colors.pink, color: "white", height: 56 }
            : { height: 56 }
        }
      >
        <div>
          <GothamRegular
            style={{ fontSize: 14, lineHeight: "22px", ...defaultFontStyle }}
          >
            {top}
          </GothamRegular>
          <GothamRegular
            style={{ fontSize: 10, lineHeight: "16px", ...defaultFontStyle }}
          >
            {bottom}
          </GothamRegular>
        </div>
      </Row>
    );
  if (label)
    return (
      <Row
        className="option mx-0  align-items-center px-2"
        {...innerProps}
        style={
          isSelected
            ? { backgroundColor: colors.pink, color: "white", height: 56 }
            : { height: 56 }
        }
      >
        <GothamRegular
          style={{ fontSize: 14, lineHeight: "22px", ...defaultFontStyle }}
        >
          {label}
        </GothamRegular>
      </Row>
    );
  return null;
};

const CustomOptions = ({
  data,
  innerProps,
  isSelected: isSelectedRaw,
  selectProps,
  selectedCountryId,
}) => {
  const { isCalculator, isPhoneCode } = selectProps || {};

  const getData = () => {
    if (isPhoneCode) {
      const { iso: country_iso, number: label } = phoneSimpleLabelSplitter(
        data?.label
      );
      return { label, country_iso };
    }
    return data;
  };

  const { currency, label, country_iso, country_id } = getData() || {};

  const isSelected = selectedCountryId
    ? country_id == selectedCountryId
    : isSelectedRaw;

  const safeLabel = label || "";
  const getLabel = isCalculator ? safeLabel.split("/")[0] : safeLabel;

  return (
    <Row
      className="option mx-0  align-items-center px-3"
      {...innerProps}
      style={
        isSelected
          ? { backgroundColor: colors.pink, color: "white", height: 56 }
          : { height: 56 }
      }
    >
      <GetFlag iso={noCase(country_iso)} style={{ margin: 0 }} />
      <div className="ml-3 pt-2">
        <GothamRegular style={{ color: isSelected ? "white" : colors.grey25 }}>
          {getLabel}
        </GothamRegular>
        <GothamRegular style={{ color: isSelected ? "white" : colors.grey25 }}>
          {currency}
        </GothamRegular>
      </div>
    </Row>
  );
};
export const GetFlag = ({
  iso = "id",
  width = 24,
  height = 24,
  isRectangle,
  style,
}) => (
  <img
    src={`http://purecatamphetamine.github.io/country-flag-icons/1x1/${iso.toUpperCase()}.svg`}
    width={width}
    height={height}
    className="align-self-center"
    style={{
      filter: "brightness(95%)",
      marginLeft: 12,
      marginRight: 7,
      borderRadius: isRectangle ? 4 : 10000,
      ...style,
    }}
  />
);

const papaParseFormatter = (data, file) => {
  const getKeys = data[0];
  const getValues = data
    .filter((_, index) => index !== 0)
    .filter((item) => item.length > 1);
  const result = getValues.map((item, index) => {
    let object = {};
    for (let i = 0; i < item.length; i++) {
      object[getKeys[i]] = item[i];
    }
    object["file_name"] = file.name;
    object["index"] = index;
    return object;
  });
  return result;
};

const fileTooLargeDecider = (files) => {
  let getSizeArr = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    getSizeArr.push(file.size);
  }
  const totalFileSize = getSizeArr.reduce((acc, curr) => acc + curr, 0);
  const allowedFileSize = 100000000;
  const invalidSize = allowedFileSize < totalFileSize;
  return invalidSize;
};

const isValidDecider = ({ fileTypes, file, maxSize }) => {
  const { getSize, getType } = fileProps(file);
  const isValid = fileTypes.includes(getType) && getSize < maxSize;
  const validButLimit = fileTypes.includes(getType) && getSize > maxSize;
  return { isValid, validButLimit };
};

const fileProps = (file, type, multiple) => {
  const isNotMultiGeneral = type === "general" && !multiple;
  const isMultiGeneral = type === "general" && multiple;
  const getType = get(file, "type", "");
  const getName = get(file, "name", "");
  const getSize = get(file, "size", 0);
  const isNameCsv = getName.split(".").pop() === "csv";
  const isCsv =
    (getType === "text/csv" ||
      getType === "application/csv" ||
      getType === "application/vnd.ms-excel") &&
    isNameCsv;

  return {
    getType,
    getName,
    getSize,
    isCsv,
    isNotMultiGeneral,
    isMultiGeneral,
  };
};

const checkDuplicate = (prev, file, string, fileKey = "name") => {
  const currentNames = prev.map((item) => item[string]);
  if (currentNames.includes(file[fileKey])) {
    return prev;
  }
  return [...prev, file];
};

const notMultiGeneralFunc = async ({
  fileTypes,
  file,
  setError,
  errorToaster,
  setValue,
  name,
  maxSize,
}) => {
  if (fileTypes.length) {
    const { isValid, validButLimit } = isValidDecider({
      file,
      fileTypes,
      maxSize,
    });
    if (isValid) {
      const label = file.name;
      const value = String(await toBase64(file));
      setValue(name, { label, value, realFile: file });
      return;
    }
    if (validButLimit) {
      errorToaster(
        "Error",
        `File size exceeds maximum limit (${toMBString(maxSize)} MB)`
      );
      setError(false);
      return;
    }
    setError(true);
    return;
  }
  const label = file.name;
  const value = String(await toBase64(file));
  setValue(name, { label, value });
  return;
};
export const toKBString = (number) => {
  const divided = Math.ceil(Number(number) / 1000);
  return `${divided} KB`;
};
export const toMBString = (number) => {
  const divided = Number(number) / 1000000;
  return `${divided} MB`;
};
const multiGeneralFunc = async ({
  fileTypes,
  file,
  setError,
  errorToaster,
  setData,
  setFile,
  formData,
  maxSize,
}) => {
  if (fileTypes.length) {
    const { isValid, validButLimit } = isValidDecider({
      file,
      fileTypes,
      maxSize,
    });
    const label = file.name;
    if (isValid) {
      const value = String(await toBase64(file));
      setData((prev) => {
        if (formData) {
          return checkDuplicate(prev, file, "name");
        }
        const result = { label, value };
        return checkDuplicate(prev, result, "label", "label");
      });

      setFile((prev) => checkDuplicate(prev, file, "name"));
      setError(false);
      return;
    }
    if (validButLimit) {
      errorToaster(
        "Error",
        `${label} exceeds maximum limit of ${toMBString(maxSize)}`
      );
      setError(false);
      return;
    }
    setError(true);
    return;
  }

  const label = file.name;
  const value = String(await toBase64(file));
  const result = { label, value };
  setData((prev) => checkDuplicate(prev, result, "label", "label"));
  setFile((prev) => checkDuplicate(prev, result, "label", "label"));
  return;
};

const uploadCsvFunc = async ({ file, setError, setFile, setData }) => {
  const { isCsv, getName } = fileProps(file);
  const label = getName;
  const value = String(await toBase64(file));
  if (isCsv) {
    Papa.parse(file, {
      complete: ({ data }) => {
        const result = papaParseFormatter(data, file) || [];
        setData((prev) => {
          const resultFileNames = result.map(({ file_name }) => file_name);
          const isDuplicate = prev.filter(({ file_name }) =>
            resultFileNames.includes(file_name)
          ).length;
          if (isDuplicate) {
            return prev;
          }
          return [...prev, ...result];
        });
      },
    });

    setFile((prev) => {
      const result = { label, value };
      return checkDuplicate(prev, result, "label", "label");
    });
    setError(false);
    return;
  }
  setError(true);
  return;
};

const deleteFormatter = (currValue, deletedItem) => {
  const { data, file } = currValue;

  const newData = data.filter(({ file_name, name, label }) => {
    if (file_name == deletedItem) return false;
    if (label == deletedItem) return false;
    if (name == deletedItem) return false;
    return true;
  });

  const newFile = file.filter(({ label, name }) => {
    if (label == deletedItem) return false;
    if (name == deletedItem) return false;
    return true;
  });
  return { newFile, newData };
};

export const fileTypes = (typeRaw) => {
  const type = String(typeRaw || "");
  const isPDF = type.includes("pdf");
  const isJPEG = type.includes("jpeg") || type.includes("jpg");
  const isPNG = type.includes("png");
  const isJPEGOrPNG = isJPEG || isPNG;
  return { isPDF, isJPEG, isPNG, isJPEGOrPNG };
};

export const showPDFOrJPEGHooks = (filesRaw) => {
  const files = filesRaw || [];
  const [file, setFile] = useState({});
  const getFile = file?.file || file || {};
  const { type, name, url } = getFile;
  const { isJPEGOrPNG } = fileTypes(type || url);
  const { height } = windowDimension();
  // PDF PROPS
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  // PDF PROPS
  const { t } = useTranslation("common");

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setPageNumber(1);
    setIsOpen((p) => !p);
  };

  const handleShow = useCallback(
    async (label) => {
      try {
        const currentFile = files.filter(({ name }) => name == label).pop();
        const { type, url } = currentFile || {};
        const { isPDF, isJPEGOrPNG } = fileTypes(type || url);
        if (!(isPDF || isJPEGOrPNG)) return;

        if (isJPEGOrPNG) {
          const base64 = url ? "" : String(await toBase64(currentFile));
          setFile(
            url
              ? {
                  url:
                    process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL +
                    url,
                }
              : { file: currentFile, base64 }
          );
          toggle();
          return;
        }
        setFile(
          url
            ? {
                url:
                  process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL +
                  url,
              }
            : currentFile
        );
        toggle();
      } catch (error) {
        console.log("error:", error);
      }
    },
    [files]
  );

  const { Document, Page } = useReactPDF();

  const modalComponent = (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      centered
      contentClassName="modal-transfez"
    >
      <div>
        {isJPEGOrPNG ? (
          <img
            src={url ? url : file?.base64}
            style={{ height: height * 0.85, width: "auto" }}
          />
        ) : (
          <Document
            file={file}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        )}
        <div
          className="d-flex justify-content-between align-items-center px-2"
          style={{ marginTop: 10 }}
        >
          {isJPEGOrPNG ? (
            <GothamRegular>{name}</GothamRegular>
          ) : (
            <>
              <GothamRegular
                style={{
                  width: "33%",
                  textAlign: "center",
                }}
              >
                {name}
              </GothamRegular>
              <div
                className="d-flex justify-content-center"
                style={{ width: "33%" }}
              >
                <img
                  src={leftArrow}
                  className={"darkhover"}
                  onClick={() =>
                    setPageNumber((p) => {
                      if (p > 1) return p - 1;
                      return p;
                    })
                  }
                />
                <img
                  src={rightArrow}
                  className="darkhover"
                  onClick={() =>
                    setPageNumber((p) => {
                      if (p < numPages) return p + 1;
                      return p;
                    })
                  }
                />
              </div>
              <GothamRegular
                style={{
                  width: "33%",
                  textAlign: "center",
                }}
              >
                {t("Page")} {pageNumber} {t("of")} {numPages}
              </GothamRegular>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
  return { handleShow, modalComponent };
};

const formattedCurrValue = (data) => {
  const { url, name: label } = data || {};
  if (url)
    return {
      ...data,
      label,
    };
  return data;
};

export const FileInput = ({
  useFormObj,
  multiple = true,
  fileTypes = [],
  style,
  type = "",
  name,
  customText = "",
  height = 88,
  className = "",
  noButtonWhenSubmitted = false,
  formData,
  isError = useFormObj?.errors[name],
  trashAndShow,
  isDottedBorder = false,
  longText = false,
  isDottedThumbnail = false,
  defaultValue = [],
  hideField,
  maxSize = 10000000,
  maxSizeInfo = false,
  maxSizeInfoInside = false,
  woPadding = false,
  maxFileName,
  noFileErrorName = false,
  noDelete,
  fontStyle,
  uploadIcon,
  loading = false,
  canDelete,
  simpleTable,
  isPinkButton,
  isReimbursementDraft = false,
  invoiceUploadLoading = false,
  customFileSizeText = false,
  isCustomizeReceipt = false,
}) => {
  const { watch, setValue } = useFormObj;
  const currValue = formattedCurrValue(watch(name));

  const isThereData = Boolean(
    currValue?.data?.length || currValue?.size || currValue?.label || []?.length
  );

  useEffect(() => {
    if (!isThereData) setValue(name, false);
  }, [isThereData]);

  const { isNotMultiGeneral, isMultiGeneral } = fileProps(
    undefined,
    type,
    multiple
  );

  useRegisterField(useFormObj, name);

  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState(false);
  // data => only used in upload batch.
  // data = values of rows in csv files
  // file => real files
  const [data, setData] = useState(defaultValue);
  const [file, setFile] = useState(defaultValue);
  const { handleShow, modalComponent } = showPDFOrJPEGHooks(
    isNotMultiGeneral ? [currValue?.realFile || watch(name)] : file
  );
  const { errorToaster } = useContext(ToasterContext);
  const supportedFiles = fileTypes.map((type) => {
    const text = (type || "").split("/")[1];
    const isDocx =
      text == "vnd.openxmlformats-officedocument.wordprocessingml.document";
    const isXlsx =
      text == "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (isXlsx) return "XLSX";
    if (isDocx) return "DOCX";
    return text;
  });
  const hasSupportedFiles = Boolean(supportedFiles.length);

  useEffect(() => {
    if (data.length && file.length) {
      // needs set time out for updates defaultValue
      setTimeout(() => {
        setValue(name, { data, file });
      }, 100);
    }
  }, [data, file]);

  const handleDelete = (deletedItem) => {
    const { newData, newFile } = deleteFormatter(currValue, deletedItem);
    setData(newData);
    setFile(newFile);
    setValue(name, { data: newData, file: newFile });
  };

  const getFileLabels = get(currValue, "file", []).map(
    ({ label, name }) => label || name
  );
  const isSubmitted = getFileLabels.length;
  const defaultAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragEnter = (e) => {
    if (e) {
      defaultAction(e);
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setIsDragging(true);
      }
    }
  };

  const defaultSetValue = async (files) => {
    if (isNotMultiGeneral) {
      setData([]);
      setFile([]);
    }

    const invalidSize = fileTooLargeDecider(files);

    if (invalidSize) {
      errorToaster(
        "Error",
        `File size exceeds maximum limit (${toMBString(maxSize)} MB)`
      );
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const defaultProps = {
        fileTypes,
        file,
        setError,
        errorToaster,
        setData,
        setFile,
        maxSize,
      };

      if (isNotMultiGeneral) {
        await notMultiGeneralFunc({
          name,
          setValue,
          isSingle: !multiple,
          formData,
          ...defaultProps,
        });
        return;
      }
      if (isMultiGeneral) {
        await multiGeneralFunc({ formData, ...defaultProps });
      }
      if (name === "upload_csv") {
        await uploadCsvFunc(defaultProps);
      }
    }
  };

  const onDrop = async (e) => {
    if (e) {
      defaultAction(e);
      e.persist();
      const getFiles = get(e, "dataTransfer.files", []);
      defaultSetValue(getFiles);
      setIsDragging(false);
    }
  };

  const onDragLeave = () => setIsDragging(false);

  const onDragOver = (e) => {
    if (e) {
      defaultAction(e);
    }
  };

  const onChange = async (e) => {
    const getFiles = get(e, "target.files", []);
    defaultSetValue(getFiles);
  };
  const { label = "" } = currValue || {};

  const getCurrLabel = maxFileName
    ? label.substring(0, maxFileName) + "..."
    : label;

  const notMultipleAndHasValue = Boolean(!multiple && label);

  const textDecider = () => {
    if (customText) {
      if (error) return "Invalid File";
      return customText;
    }
    if (!isDragging && isDottedBorder) {
      if (error) return ["File type is invalid. Please upload again"];
      if (isDottedThumbnail) return ["Browse or drag files here to upload"];
      if (longText) return ["To upload files, drag them here or browse"];
      return ["To upload files,", "drag them here or browse"];
    }

    if (isDragging && isDottedBorder) return ["Drop here"];
    if (isDragging) return "Drop here";
    if (error) return "File type is invalid. Please upload again";
    return "To upload files, drag them here or browse";
  };

  const text = textDecider();

  useEffect(() => {
    if (!error) return;
    errorToaster("Error", "File type invalid");
    setError(false);
  }, [error]);

  const hideButton = notMultipleAndHasValue && noButtonWhenSubmitted;

  const tableProps = {
    data: getFileLabels,
    dataPinkButton: get(currValue, "file", []),
    handleDelete,
    handleShow,
    trashAndShow,
    canDelete,
    simpleTable,
    isPinkButton,
    isReimbursementDraft,
    invoiceUploadLoading,
  };

  if (hideField)
    return (
      <>
        <MultipleInputTable {...tableProps} hideField />
        {modalComponent}
      </>
    );

  const ErrorAndTable = () => (
    <>
      {Boolean(isError && !isThereData) && (
        <GothamRegular className="font12" style={{ color: "#E95757" }}>
          {noFileErrorName ? "" : titleCase(name)} cannot be empty
        </GothamRegular>
      )}
      {Boolean(isSubmitted) && <MultipleInputTable {...tableProps} />}
      {modalComponent}
    </>
  );

  if (isCustomizeReceipt)
    return (
      <FileInputCustomizeReceipt
        name={name}
        onDrop={onDrop}
        loading={loading}
        value={currValue}
        maxSize={maxSize}
        onChange={onChange}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        supportedFiles={supportedFiles}
        onRemoveFile={() => setValue(name, {})}
      />
    );

  return (
    <>
      {isDottedBorder ? (
        <>
          <label
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            className={`${
              hideButton ? "" : "btn-fileinput"
            } text-center hover ${className}`}
            style={{
              width: "100%",
              height: 124,
              backgroundColor: "white",
              border: "1px dashed #DA649F",
              padding: 12,
              ...style,
            }}
          >
            {loading && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaSpinner className="icon-spin mr-2" />
                <GothamRegular>Uploading..</GothamRegular>
              </div>
            )}
            {!loading && (
              <>
                <Row
                  style={{ height }}
                  className="mx-0 align-items-center justify-content-center"
                >
                  {notMultipleAndHasValue ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <GothamMedium
                        style={{ color: colors.blue19 }}
                        className="my-0"
                      >
                        {label}
                      </GothamMedium>
                      <img
                        src={xIcon}
                        className="ml-2 darkhover"
                        style={{ zIndex: 1 }}
                        onClick={(e) => {
                          if (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            setValue(name, {});
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <img src={uploadIcon ? uploadIcon : uploadBlueIcon} />
                      <GothamMedium
                        style={{ color: colors.pink, ...fontStyle }}
                        className="mt-1"
                      >
                        {text[0]}
                        <br />
                        {maxSizeInfoInside && (
                          <GothamRegular
                            style={{
                              color: colors.grey72,
                              fontSize: 12,
                              marginBottom: 0,
                              lineHeight: "18px",
                            }}
                          >
                            {`Maximum upload per file size : ${toMBString(
                              maxSize
                            )}.`}
                          </GothamRegular>
                        )}
                        {customFileSizeText ? (
                          <TextInlineRegular
                            style={{ fontSize: 12, color: "#6C6C71" }}
                          >
                            Max. size for each file:{" "}
                            <TextInlineMedium style={{ color: "#6C6C71" }}>
                              10 MB
                            </TextInlineMedium>
                          </TextInlineRegular>
                        ) : null}
                        {isReimbursementDraft ? <br /> : null}
                        {text[1]}
                      </GothamMedium>
                      {hasSupportedFiles && !noButtonWhenSubmitted && (
                        <>
                          {isDottedThumbnail ? (
                            <>
                              <GothamRegular
                                style={{
                                  color: colors.grey6c,
                                  fontSize: 10,
                                  lineHeight: "12px",
                                }}
                              >
                                Max. size for each file:{" "}
                                <span style={{ fontFamily: "GothamMedium" }}>
                                  {toMBString(maxSize)}
                                </span>
                              </GothamRegular>
                              <GothamRegular
                                style={{
                                  color: colors.grey6c,
                                  fontSize: 12,
                                  lineHeight: "12px",
                                  marginBottom: 0,
                                }}
                              >
                                Supported file types:{" "}
                                <span style={{ fontFamily: "GothamMedium" }}>
                                  {supportedFiles
                                    .map((item) => upperCase(item))
                                    .join(", ")}
                                </span>
                              </GothamRegular>
                            </>
                          ) : (
                            <GothamRegular
                              style={{ color: colors.grey72, fontSize: 10 }}
                            >
                              Supported File(s) :{" "}
                              {supportedFiles
                                .map((item) => upperCase(item))
                                .join(", ")}
                            </GothamRegular>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </Row>

                {/* hidden input */}
                <input
                  name="testing"
                  type="file"
                  multiple={multiple}
                  style={{ display: "none" }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={onChange}
                />
                {/* hidden input */}
              </>
            )}
          </label>
          {maxSizeInfo && (
            <GothamRegular
              style={{
                color: colors.grey72,
                fontSize: 12,
                marginBottom: 0,
                lineHeight: "18px",
              }}
            >
              {`Maximum upload per file size : ${toMBString(maxSize)}.`}
            </GothamRegular>
          )}
          <ErrorAndTable />
        </>
      ) : (
        <>
          <label
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            className={`${
              hideButton ? "" : "btn-fileinput"
            } text-center hover ${className}`}
            style={{
              width: "100%",
              height,
              border: notMultipleAndHasValue ? "" : "2px solid #DA649F",
              padding: woPadding ? 0 : "0px 65px",
              ...style,
            }}
          >
            <Row
              style={{ height }}
              className="mx-0 align-items-center justify-content-center"
            >
              {notMultipleAndHasValue ? (
                <div className="d-flex justify-content-between align-items-center">
                  <GothamMedium
                    style={{
                      color: colors.primaryBlue,
                      borderBottom: `${
                        woPadding ? "0px" : "1px"
                      } solid #2C4A77`,
                      textDecoration: woPadding ? "underline" : "",
                    }}
                    onClick={(e) => {
                      if (!e) return;
                      e.preventDefault();
                      e.stopPropagation();
                      handleShow(label);
                    }}
                    className="my-0 darkhover"
                  >
                    {getCurrLabel}
                  </GothamMedium>
                  {!noDelete && (
                    <img
                      src={xIcon}
                      className="ml-2 darkhover"
                      style={{ zIndex: 1 }}
                      onClick={(e) => {
                        if (e) {
                          e.preventDefault();
                          e.stopPropagation();
                          setValue(name, {});
                        }
                      }}
                    />
                  )}
                  {modalComponent}
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <img src={uploadIcon} className="mr-2 mb-2 " />
                  <GothamRegular
                    style={{
                      color: colors.pink,
                      fontWeight: "bold",
                      lineHeight: "22px",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    {text}
                  </GothamRegular>
                </div>
              )}
            </Row>
            {/* hidden input */}
            <input
              name="testing"
              type="file"
              multiple={multiple}
              style={{ display: "none" }}
              onClick={(e) => {
                e.target.value = null;
              }}
              onChange={onChange}
            />
            {/* hidden input */}
          </label>
          {hasSupportedFiles && !noButtonWhenSubmitted && (
            <>
              <GothamRegular
                style={{
                  color: colors.grey72,
                  fontSize: 12,
                  marginBottom: 0,
                  lineHeight: "18px",
                }}
              >
                Supported File(s) :{" "}
                {supportedFiles.map((item) => upperCase(item)).join(", ")}
              </GothamRegular>
              <GothamRegular
                style={{
                  color: colors.grey72,
                  fontSize: 12,
                  marginBottom: 0,
                  lineHeight: "18px",
                }}
              >
                {`Maximum upload file size : ${toMBString(maxSize)}.`}
              </GothamRegular>
            </>
          )}
          <ErrorAndTable />
        </>
      )}
    </>
  );
};

export const FileInputDotted = ({
  fileTypes = ["application/pdf", "image/jpeg"],
  useFormObj,
  name = "upload_file",
}) => (
  <FileInput
    isDottedBorder
    fileTypes={fileTypes}
    useFormObj={useFormObj}
    name={name}
    formData
    type="general"
    multiple={false}
    maxSize={25000000}
  />
);

export const FileInputDottedThumbnail = ({
  fileTypes = ["application/pdf", "image/jpeg"],
  useFormObj,
  name = "upload_file",
  maxSize = 25000000,
  multiple = false,
  ...props
}) => (
  <FileInput
    isDottedBorder
    fileTypes={fileTypes}
    useFormObj={useFormObj}
    name={name}
    formData
    type="general"
    multiple={multiple}
    maxSize={maxSize}
    isPinkButton
    isDottedThumbnail
    style={{ backgroundColor: "#FFF5FD", border: "2px dashed #DA649F" }}
    {...props}
  />
);

export const useRegisterField = (useFormObj, name) => {
  const { register, watch, setValue } = useFormObj;
  const currValue = watch(name);

  useEffect(() => register(name), []);

  return { currValue, setValue };
};

export const NakedSearchInput = ({
  onChange,
  placeholder = "Search Transactions",
  style,
  styleInput,
  woSearch,
  ...props
}) => (
  <div style={{ position: "relative", ...style }}>
    {!woSearch && (
      <Search
        style={{
          position: "absolute",
          left: 13,
          top: 12,
          color: "#BBBBC0",
        }}
      />
    )}
    <input
      type="text"
      onChange={(e) => onChange(e && e.target.value)}
      placeholder={placeholder}
      className="searchBatch"
      style={{
        height: 42,
        paddingTop: 10,
        width: "100%",
        marginBottom: 16,
        ...styleInput,
      }}
      {...props}
    />
  </div>
);
